import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProductCard from './ProductCard';
import cartfunctions from '../_helpers/cartfunctions';
export class GetSingleProduct extends Component {
    //static displayName = GetSingleProduct.name;
    //static productID = this.props.ProductID;
    constructor(props) {
        super(props);
      
        //console.log(props);
        //this.state = { products: [], loading: true };
        this.state = {
            products: [],
            productID: this.props.ProductID,
            checkOutPageLink: this.props.CheckOutPageLink,
            purchaseOnAccountLink: this.props.PurchaseOnAccountLink,
            loading: true,
            orientation: this.props.orientation === '' ? 'vertical' : this.props.orientation
        }
    }
   
   
    componentDidMount() {
        //cartfunctions.checkCartIsValid();
        this.getSingleProduct();
    }

    renderProduct(product, checkOutPageLink) {
        if (product != null) {
            return (
                <ProductCard dataParentToChild={product} CheckOutPageLink={checkOutPageLink} PurchaseOnAccountLink={this.state.purchaseOnAccountLink}  orientation={this.state.orientation} cartContents={this.props.cartContents} updateCartContents={this.props.updateCartContents}></ProductCard>
            );
        }
        else {
            return (<Container>
                <Row>
                    <Col md={12} >
                        Unable to identify Product to be loaded. Please remember  to include the ProductID via the URL. For example <code>GetSingleProduct?ProductID=102331</code>
                    </Col>
                </Row >
            </Container >);
        }
       
    }

    render() {
       
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderProduct(this.state.products, this.state.checkOutPageLink);
        if (this.state.productID === undefined) {
            return (
                <div>
                    <h1 id="tabelLabel" >Get Single Product</h1>
                    <p>This component demonstrates getting a single Product from Chargebee.</p>
                    {contents}
                </div>
            );
        }
        else {
            return (
                <div>
                     {contents}
                </div>
            );

        }
        
    }

    async getSingleProduct() {
        var product = null;
        if (this.state.productID === undefined) {
            var queryParameters = document.location.search;
            if (queryParameters !== '') {
                product = new URLSearchParams(queryParameters).get("ProductID");
            }
            
        }
        else {
            product = this.state.productID;
        }
        //const product2 = this.props.ProductID;
        if (product == null) {
            this.setState({ products: null, loading: false });
        }
        else {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetProduct/' + product;
            const response = await fetch(serviceUrl);
            const data = await response.json();
            this.setState({ products: data, loading: false });
        }
      
    }
}