import axios from 'axios';

export const shoppingcartService = {
    getShoppingCart: async function (cartID) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCart';
            return axios.post(serviceUrl, body);
          
        }
        catch (error) {
            return error;
        }
       
    },
    getNewShoppingCart: async function () {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
       
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/LoadNewCart';
            return axios.get(serviceUrl);
        }
        catch (error) {
            return error;
        }
    },
    getShoppingCartHeader: async function (cartID)
    {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        let results = [];
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCartHeader?userCartID='+cartID;
            await axios.get(serviceUrl)
                .then((response) => {
                    if (response.status === 200) {
                        results = response.data;
                    }
                })
                .catch((error) => {
                    return error;

                });
        }
        catch (error) {
            return error;
        }
        return results;
    },
    addItemToShoppingCart: async function (cartID, productID, unitPrice) {
        const endPointURL = process.env.REACT_APP_API_URL;
       if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID,
            productID: productID,
            unitPrice: unitPrice
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/AddItemToShoppingCart';
            return await axios.post(serviceUrl, body);
        }
        catch (error) {
            return error;
        }
      
    },
    removeItemFromShoppingCart: async function (cartID, productID) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID,
            productID: productID
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/RemoveItemFromShoppingCart';
            return axios.post(serviceUrl, body);
       
        }
        catch (error) {
            return error;
        }
    },
    reduceItemFromShoppingCart: async function (cartID, productID, unitPrice) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID,
            productID: productID,
            unitPrice: unitPrice
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/ReduceQtyFromShoppingCart';
            return axios.post(serviceUrl, body);
       
        }
        catch (error) {
            return error;
        }
    },
    checkUnitPrice: async function (id, unitPrice) {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = await fetch(endPointURL + 'api/ChargebeeWidgets/GetProduct/' + id, requestOptions);
        const data = await response.json();
        var isValidUnitPrice = true;
        if ((data.item_price.price / 100) !== unitPrice) {
            isValidUnitPrice = false;
        }
        //check whether the SKU was returned or not.
        //localStorage.setItem('currentUser', JSON.stringify(user));
        return isValidUnitPrice;
    },
    clearCart: async function (cartID) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/ClearShoppingCart';
            return axios.post(serviceUrl, body);
          
        }
        catch (error) {
            return error;
        }
        //return results;
    },
    
    saveCartEmail: async function (cartID, emailAddress) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            editOperation: "email",
            cartID: cartID,
            contactEmail: emailAddress
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/EditShoppingCart';
            return await axios.post(serviceUrl, body);
        }
        catch (error) {
            return error;
        }
    },
    saveCartAddressInfo: async function (cartID, companyName, firstName, lastName,
        address1, address2, city, region, postalCode,
        contactPhone, contactFirstName, contactLastName, contactEmail, country) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            editOperation: "addressInfo",
            cartID: cartID,
            companyName: companyName,
            contactFirstName: contactFirstName,
            contactLastName: contactLastName,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            billToFirstName: firstName,
            billToLastName: lastName,
            billToAddr1: address1,
            billToAddr2: address2,
            billToCity: city,
            billToState: region,
            billToPostalCode: postalCode,
            billtoCountry: country
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/EditShoppingCart';
            return await axios.post(serviceUrl, body);
        }
        catch (error) {
            return error;
        }
    },
    saveTCVersion: async function (cartID, tcVersion) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: cartID,
            editOperation: "tcInfo",
            tcVersion: tcVersion
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/EditShoppingCart';
            return await axios.post(serviceUrl, body);
        }
        catch (error) {
            return error;
        }
    },


}


