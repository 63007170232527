import { handleResponse } from '../_helpers/handle-response';
import axios from 'axios';
import CryptoJS from "crypto-js";

export const authenticationService = {
    login,
    logout,
    getUserRoles,
    get currentUserValue() { return localStorage.getItem("currentUser")}
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`api/Login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            return user;
        });
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    
}
  async function getUserRoles() {
      const endPointURL = process.env.REACT_APP_API_URL;
      const seedKey = process.env.REACT_APP_SEED_KEY;
    if (process.env.REACT_APP_API_URL === undefined) {
        endPointURL = "/";
    }
    
     try {
        const serviceUrl = endPointURL + 'api/User/GetUserRoles';
         await axios.get(serviceUrl)
            .then(res => {
                localStorage.setItem('UWA_CurrentUserRoles', CryptoJS.AES.encrypt(
                    JSON.stringify(res.data.userRoles),
                    seedKey
                ).toString() );
            });

    }
    catch (error) {
        return error;
    }
}