import React from 'react';
import { Chip, Tooltip } from '@mui/material';
export default function UnknownBadge(props) {
  
    return (
        <Tooltip title={props.name }>
            <Chip label={props.name} className="UWA_AddOnChip"
            />
        </Tooltip>
    );
}