import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import KPITile from '../../Charts/KPITile';
import { Grid } from '@mui/material';

export default function Report_NewSubscriptions(props) {
    const [isloading, setLoading] = useState(true);
    const [isKPITileLoading, setKPITileLoading] = useState(true);
    const [errMsg, setErrorMsg] = useState("");
    const [currentPeriod, setCurrentPeriod] = useState("2023");
    const [totalSubscriptions, updateTotalSubs] = useState(0);
    const [activeSubscriptions, updateactiveSubs] = useState(0);
    const [cancelledSubscriptions, updatecancelledSubs] = useState(0);
    const [newSubscriptionsYTD, updatenewSubsYTD] = useState(0);
    const [activeSubscriptionsYTD, updateactiveSubsYTD] = useState(0);
    const [cancelledSubscriptionsYTD, updatecancelledSubsYTD] = useState(0);
    const [subscriptiondata, setReportData] = useState([]);
    const [ytdProductdata, setytdProductData] = useState([]);
    const [newRevenueData, setNewRevenueData] = useState([]);
    const cards = [
        {
            loading: isKPITileLoading,
            title: 'TOTAL',
            fullValue: totalSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 0
        },
        {
            loading: isKPITileLoading,
            title: 'ACTIVE',
            fullValue: activeSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 1
        },
      
        {
            loading: isKPITileLoading,
            title: 'CANCELLED',
            fullValue: cancelledSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 2
        },
        {
            loading: isKPITileLoading,
            title: 'NEW (CYTD)',
            fullValue: newSubscriptionsYTD,
            duration: 3.25,
            format: '',
            activeTile: 3
        },
        {
            loading: isKPITileLoading,
            title: 'ACTIVE (CYTD)',
            fullValue: activeSubscriptionsYTD,
            duration: 3.25,
            format: '',
            activeTile: 4
        },
        {
            loading: isKPITileLoading,
            title: 'CANCELLED (CYTD)',
            fullValue: cancelledSubscriptionsYTD,
            duration: 3.25,
            format: '',
            activeTile: 5
        }

    ];
    const loadNewSubscriptions = async () => {
      
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        //console.log(currentPeriod);
        const serviceUrl = endPointURL + 'api/Subscription/GetNewSubscriptionsByYear?period='+ currentPeriod;

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res);
                    let headerArry = [...new Set(res.data.subscriptions.map(d => d.familyName))];  
                    //Now add "Month"
                    //console.log(headerArry);
                    if (headerArry.length > 0) {
                        let reportData = [];
                        let productFamilyCount = headerArry.length;
                        headerArry.splice(0, 0, "Month");
                        reportData.push(headerArry);
                        let monthsArry = [...new Set(res.data.subscriptions.map(d => d.startMonthAndYear))];
                        monthsArry.map(month => {
                            //console.log(month);
                            let x = [...Array(productFamilyCount+1)].map(x => 0);
                            x[0] = month;
                            //console.log(x);
                            let currentMonth = res.data.subscriptions.filter(s => s.startMonthAndYear === month);
                            //console.log(currentMonth);
                            //Now lets fill the arry. 
                            //let i = 1;
                            currentMonth.map(val => {
                                let familyNameIndex = headerArry.indexOf(val.familyName);
                                x[familyNameIndex] = val.count;
                              
                            });
                            reportData.push(x);
                        });
                        setReportData(reportData);
                        //console.log(subscriptiondata);
                    }
                    //console.log(reportData);
                    setLoading(false);
                };

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }
    const loadNewSubscriptionRevenue = async () => {

        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        console.log(currentPeriod);
        const serviceUrl = endPointURL + 'api/Subscription/GetNewSubscriptionRevenueByYear?period=' + currentPeriod;

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res);
                    let headerArry = [...new Set(res.data.subscriptions.map(d => d.familyName))];
                    //Now add "Month"
                    //console.log(headerArry);
                    if (headerArry.length > 0) {
                        let reportData = [];
                        let productFamilyCount = headerArry.length;
                        headerArry.splice(0, 0, "Month");
                        reportData.push(headerArry);
                        let monthsArry = [...new Set(res.data.subscriptions.map(d => d.startMonthAndYear))];
                        monthsArry.map(month => {
                            //console.log(month);
                            let x = [...Array(productFamilyCount + 1)].map(x => 0);
                            x[0] = month;
                            //console.log(x);
                            let currentMonth = res.data.subscriptions.filter(s => s.startMonthAndYear === month);
                            //console.log(currentMonth);
                            //Now lets fill the arry. 
                            //let i = 1;
                            currentMonth.map(val => {
                                let familyNameIndex = headerArry.indexOf(val.familyName);
                                x[familyNameIndex] = val.total;

                            });
                            reportData.push(x);
                        });
                        setNewRevenueData(reportData);
                        //console.log(subscriptiondata);
                    }
                    //console.log(reportData);
                    setLoading(false);
                };

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }

    const loadYTDSubscriptionsByProduct = async () => {
        
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Subscription/GetYTDSubscriptionsByProductFamily?period=' + currentPeriod;

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res);
                    console.log(res.data);
                    let reportData = [];
                    reportData.push(['Product', 'Count']);
                    res.data.ytdSubscriptionCountByProduct.map(x => {
                        reportData.push([x.familyName, x.count]);
                    });
                    setytdProductData(reportData);
                   
                };

            })
            .catch((error) => {
                console.error('Error:', error);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }
    const loadKPIMetrics = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Subscription/GetKPIMetrics';

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res);
                    console.log(res.data.dashboardMetrics);
                    
                    updateTotalSubs(res.data.dashboardMetrics.totalSubscriptions);
                    updateactiveSubs(res.data.dashboardMetrics.totalActiveSubscriptions);
                    updatecancelledSubs(res.data.dashboardMetrics.totalCancelledSubscriptions);
                    updatenewSubsYTD(res.data.dashboardMetrics.ytdTotalSubscriptions);
                    updateactiveSubsYTD(res.data.dashboardMetrics.ytdActiveSubscriptions);
                    updatecancelledSubsYTD(res.data.dashboardMetrics.ytdCancelledSubscriptions);

                    setKPITileLoading(false);
                };

            })
            .catch((error) => {
                console.error('Error:', error);
                setKPITileLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }
    const newSubChartOptions = {
        title: "New Subscription Counts (By Month) - " + currentPeriod,
        chartArea: { width: "50%" },
        hAxis: {
            title: "Months"
        },
        vAxis: {
            title: "Count"
        },
        seriesType: "bars",
        bar: { groupWidth: '45%' },
        ignoreWindowOnLoad : false
    };
    const newRevenueOptions = {
        title: "New Revenue (By Month) - " + currentPeriod,
        chartArea: { width: "75%" },
        hAxis: {
            title: "Months"
        },
        vAxis: {
            title: "Amount",
            format: 'currency'
        },
        seriesType: "bars",
        ignoreWindowOnLoad: false,
        colors: ['#76A7FA', 'green', 'red'],
        bar: { groupWidth: '45%' },
        isStacked: true
    };
    const ytdChartOptions = {
        title: "Subscription Counts By Product (YTD) - " + currentPeriod,
        pieHole: 0.4,
        is3D: true,
    };
    const handleCurrentPeriodChange = (e) => {
        let newPeriod = e.target.value;
        setCurrentPeriod(newPeriod);
    
      
    }

    useEffect(() => {
        loadKPIMetrics();
        loadNewSubscriptions();
        loadNewSubscriptionRevenue();
        loadYTDSubscriptionsByProduct();
    }, [currentPeriod]);
    
    if (isloading) {
        return (
            <Grid container spacing={2}>
                <Grid item lg={6} sm={6} xl={2} xs={12}  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item lg={6} sm={6} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item lg={6} sm={6} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item lg={6} sm={6} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item lg={6} sm={6} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item lg={6} sm={6} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={125} />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={400} />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" width="80%" height={400} />
                </Grid>
            </Grid>
        );
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12}>
                    {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
                </Grid>
                {cards.map((item, index) => {
                  
                    return (
                        <Grid item key={item.title + index} lg={6} sm={6} xl={2} xs={12} data-index={item.activeTile}>
                            <KPITile {...item} style={{ backgroundColor: "#fff", cursor: "pointer" }} />
                        </Grid>
                    );
                })}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                        <InputLabel id="lblCurrentPeriod">Current Period</InputLabel>
                        <Select
                                labelId="lblCurrentPeriod"
                                id="ddlCurrentPeriod"
                                value={currentPeriod}
                                label="Current Period"
                                style={{ height: '25px' }}
                                onChange={handleCurrentPeriodChange}
                          
                        >
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                        </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                isStacked="true"
                data={subscriptiondata}
                options={newSubChartOptions}
                />
                </Grid>
                <Grid item xs={12} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        height="400px"
                        data={ytdProductdata}
                        options={ytdChartOptions}
                    />
                </Grid>
                <Grid item xs={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Chart
                        chartType="ComboChart"
                        width="100%"
                        height="400px"
                        data={newRevenueData}
                        options={newRevenueOptions}
                    />
                </Grid>
            </Grid>
        </>
    );
   
}