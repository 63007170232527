import React, { Component} from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import cartfunctions from '../../_helpers/cartfunctions';


export default class StartStep extends Component {
    constructor(props) {
        super(props);
       this.state = {
            email: props.getCartCheckOut().email,
            isloading: false
        }
    }
    componentDidMount() {
        this.props.toggleWaitModal(true);
        cartfunctions.getCartHeader().then(result => {
            if (!!result.soldToEmail) {
                this.setState({ email: result.soldToEmail });
            };
             
        });
        this.props.toggleWaitModal(false);
    }

    componentWillUnmount() { }
    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;
        this.props.toggleWaitModal(true);
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getCartCheckOut().email !== userInput.email ) {
                this.props.updateCartCheckOut({ ...userInput });
                //Lets save this also to the db.
                cartfunctions.saveCartEmail(userInput.email);
            }
           
            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }
        this.props.toggleWaitModal(false);

        return isDataValid;
    }
    //validationCheck(elementName) {
    //    //console.log(this._validateOnDemand);
    //    //if (!this._validateOnDemand)
    //    //    return;

    //    const userInput = this._grabUserInput(); // grab user entered vals
    //    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    //    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
    //        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    //    }

    //    //this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    //}
    handleChange = (event) => {
        var helperVarName = event.target.name + 'ValMsg';
        this.setState({ [helperVarName] : ''});
        this.setState({ [event.target.name]: event.target.value });
        
    }
    _validateData(data) {
        return {
            //emailVal: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)
            emailVal: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            emailValMsg: val.emailVal ? '' : 'Please enter a valid email address to start the checkout process'
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            email: this.state.email
        };
    }


    render() {
      
        return (
            <div className="step StartStep" >
                <Grid container  spacing={2} paddingBottom={4} paddingRight={4}>
                            <Grid item col={12}>
                                <label className="col-md-12 control-label">
                                    <h2>Start Checkout Process</h2>
                                </label>
                            </Grid>
                            <Grid item col={12}>
                                    Please provide your email address and select the NEXT button. This address will be used to send you details about your purchase today.
                            </Grid>
                            <Grid item xs={12}>
                               <TextField 
                            name="email"
                            id="outlined-required"
                            label="Email Address"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.email}
                            required
                            style={{ width: '50%' }}
                            helperText={this.state.emailValMsg}
                                 />
                        
                        
                            </Grid>
                </Grid>
            </div>
        )
    }
}



