import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button, FormControlLabel, Grid, TextField, Switch } from '@mui/material';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';

export default function ManageEventLogs(props) {

    const [openManageIntegrationDialog, setOpenManageIntegrationDialog] = useState(false);
    const [currenteventLogID, setCurrentEventID] = useState(-1);
    const [currentEventData, setCurrentEventData] = useState({
        cbCustomerId: 0,
        createdDate: null,
        eventData: null,
        id: 0,
        integrationName: null,
        lastModifiedDate: null,
        logData: null,
        sourceSystem: null,
        status: null
    });
    const [dialogerrMsg, setDialogErrorMsg] = useState("");
    const [errMsg, setErrorMsg] = useState("");

    const renderViewButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        handleManageEventDialogOpen(params.row.id);
                    }}
                >
                    View
                </Button>
            </strong>
        )
    };
    const [isloading, setLoading] = useState(null);
    const [tableData, setTableData] = React.useState([]);

    const handleManageEventDialogOpen = async (currenteventLogID) => {
        setCurrentEventID(currenteventLogID);

        setOpenManageIntegrationDialog(true);
    }
    const handleManageEventDialogClose = () => {
        setDialogErrorMsg("");
        setErrorMsg("");
        setCurrentEventID(-1);
        setCurrentEventData([]);
        setOpenManageIntegrationDialog(false);
    }
 

    const eventLogsColumns = [
        {
            field: 'id', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderViewButton
        },
        {
            field: 'logTypekey',
            headerName: 'LogType',
            width: 200,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 200,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },    

        {
            field: 'createdDate',
            headerName: 'Create Date',
            type: 'date',
            align: 'right',
            headerAlign: 'right',
            width: 200
        },
        {
            field: 'extraInfo',
            headerName: 'Details',
            width: 300,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        }

    ];

    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(4)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );

    const loadEventLogs = async () => {



        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Subscription/GetAllEventLogData';


        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setTableData(res.data);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log('Error:', error);


            });


    };
    const loadEventLogsDetails = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        const serviceUrl = endPointURL + 'api/Subscription/GetEventLogRecordDetails?eventLogId=' + currenteventLogID;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setCurrentEventData(res.data);
                    console.log("currentEventData-Object -");
                    console.log(currentEventData);
                    console.log(currentEventData[0]);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setDialogErrorMsg(error.response.data.message);
                }
                else {
                    setDialogErrorMsg(error.response.data);
                }

            });
    }

    useEffect(() => {
        document.title = "Manage Event Logs";
        loadEventLogs();

    }, []);

    useEffect(() => {
        if (currenteventLogID > 0) {

            loadEventLogsDetails();
        }
    }, [currenteventLogID])
    return (

        <Container>
            <Dialog
                open={openManageIntegrationDialog}
                onClose={handleManageEventDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="Manage Event Dialog"> Event Details
                </DialogTitle>
                <DialogContent>
                    {isloading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Skeleton variant="rectangular" width="100%" height={360} />
                        </div>
                    ) : (
                        <Grid container sx={{ p: 1 }} spacing={2} >
                                <React.Fragment key={"integration_" + currentEventData.Id}>
                                <Grid item xs={12}>
                                    <TextField style={{ width: '100%' }}
                                            name="logTypekey"
                                        label="LogType"
                                            size="small"
                                            disabled="true" 
                                        required
                                            value={currentEventData.logTypekey}
                                       
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField multiline style={{ width: '100%' }}
                                            name="userName"
                                        label="User Name"
                                            size="large"
                                            disabled="true" 
                                        required
                                            value={currentEventData.userName}
                                        
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField multiline style={{ width: '100%' }}
                                            name="description"
                                           label="Description"
                                            size="large"
                                            disabled="true" 
                                        required
                                            value={currentEventData.description}
                                    />
                                    </Grid>

                                  

                                    <Grid item xs={12}>
                                        <TextField multiline style={{ width: '100%' }}
                                            name="createdDate"
                                            label="Created Date"
                                            size="large"
                                            disabled="true" 
                                            required
                                            value={currentEventData.createdDate}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField multiline style={{ width: '100%' }}
                                            name="extraInfo"
                                            label="Details"
                                            size="large"
                                            editable="false"
                                            disabled="true"
                                            required
                                            value={currentEventData.extraInfo}

                                        />
                                    </Grid>

                            </React.Fragment>


                            <Grid item xs={12}>
                                {dialogerrMsg ? <Alert severity="error" >{dialogerrMsg}</Alert> : ""}
                            </Grid>

                        </Grid>
                    )}




                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={handleManageEventDialogClose} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Row>
                <Col>
                    <h1>View Event Logs</h1>
                </Col>
            </Row>
            <Row>
                <Col>

                    <hr />
                    <div style={{ height: 350, width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                {/*<PermissionsGate scopes={[SCOPES.canManageEventLogs} showMessage="false">*/}
                                <DataGrid
                                    rows={tableData}
                                    rowHeight={39}
                                    columns={eventLogsColumns}
                                    pageSize={10}
                                    getRowId={row => Math.random()}
                                    components={{
                                        LoadingOverlay: LoadingSkeleton
                                    }}
                                    loading={isloading}
                                    rowsPerPageOptions={[10, 15, 20]}
                                        disableColumnSelector={true} />
                                    {/*</PermissionsGate>*/}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
                </Col>
            </Row>
        </Container >


    );
}


