/// <reference path="wizardsteps/startstep.js" />
import React from "react";
import styled from '@emotion/styled';
import Modal from "@mui/material/Modal";
import RingLoader from "react-spinners/RingLoader"
export class ModalWait extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: true };
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose(msg) {
        this.setState({ open: false });
        this.props.handleResponse(msg);
    }
    render() {
        return <WaitModal open={this.state.open} />;
    }
    async componentDidMount() {
        let msg;
        if (this.props.onExeFun) {
            msg = await this.props.onExeFun(...this.props.args);
        }
        //this.handleClose(msg);
    }
}

const useStyles = styled((theme) => ({
    paper: {
        position: "absolute",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgb(100,100,100, 0.5)",
        border: "none",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modal: {
        top: 0,
        left: 0
    },
    content: {
        textAlign: "center",
        color: "white",
        position: "absolute",
        left: "50%",
        top: "50%"
    }
}));
export default function WaitModal(props) {
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={props.open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div sx={classes.paper}>
                    <p className="UWA_WaitIcon" id="simple-modal-description">
                        <RingLoader size={100} color="#005295" sx={classes.content} />
                    </p>
                 </div>
            </Modal>
        </div>
    );
}