import React, { useState, useEffect, useCallback } from 'react';

import UWA_ShoppingCart from '../components/UWA_ShoppingCart';
import RenderQueryParamShoppingCart from '../components/RenderQueryParamShoppingCart';
//import { useLocation } from "react-router-dom";
import cartfunctions from '../_helpers/cartfunctions';

export default function GetShoppingCart(props) {
    //const [cartDetails, setCartDetails] = useState([]);
   
    const [readOnlyMode, setReadOnlyMode] = useState(props.ReadOnlyMode === undefined ? "" : props.ReadOnlyMode);
    //const [disablePlaceOrderButton] = useState(true);
    const [shoppingCartPageLink] = useState(props.shoppingCartPageLink);
    const [checkOutPageLink] = useState(props.checkOutPageLink);
    //const [queryCartID] = new URLSearchParams(window.location.search).get('CartID');
    const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
        if (s) {
            if (!s.includes("CartID=")) {
                s = "CartID=";
            }
        }
        else {
            s ="CartID=";
        }
        const [k, v] = s.split('=');
        return Object.assign(acc, { [k]: v })
    }, {})
    let queryCartID = params.CartID;
    
    //console.log(params.CartID);
    //console.log(queryCartID);
   
    useEffect(() => {
        document.title = "Shopping Cart";
       
    }, []);
    
    //const handleCheckOutClick = useCallback(() => {
    //    window.location.href = (checkOutPageLink === '' ? '/CheckoutWizard' : checkOutPageLink);
    //});

  
    //const handleEditCartClick = useCallback(() => {
    //    window.location.href = (shoppingCartPageLink === '' ? '/GetShoppingCart' : shoppingCartPageLink);
    //});
   

   
    
    //console.log(props);
    let contents = !queryCartID ? (<UWA_ShoppingCart cartID={cartfunctions.getCartID()} cartContents={props.cartContents} updateCartContents={props.updateCartContents}  checkOutPageLink={checkOutPageLink} shoppingCartPageLink={shoppingCartPageLink} ReadOnlyMode={readOnlyMode}/>) : (<RenderQueryParamShoppingCart queryCart={queryCartID} checkOutPageLink={checkOutPageLink} shoppingCartPageLink={shoppingCartPageLink}/>);
    return (
        <div>
            {contents}
        </div>
    );



};
