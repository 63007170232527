import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid} from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import  Alert  from '@mui/material/Alert';
import axios from 'axios';
import KPITile from '../Charts/KPITile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateNewSubscription from './CreateNewSubscription.js';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   
 
export default function ManageSubscriptions(props) {
    const [isloading, setLoading] = useState(null);
    const [CurrentKPITile, setActiveKPITile] = useState("0");
     const [isKPITileLoading, loadingKPITile] = useState(true);
    const [totalSubscriptions, updateTotalSubs] = useState(0);
    const [activeSubscriptions, updateactiveSubs] = useState(0);
    const [futureSubscriptions, updatefutureSubs] = useState(0);
    const [cancelledSubscriptions, updatecancelledSubs] = useState(0);
    const [dashboardErrMsg, setDashboardErrorMsg] = useState("");
    const [tableData, setTableData] = React.useState([]);
    const [openAddNewSubscriptionDialog, setOpenAddNewSubscriptionDialog] = useState(false);

    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        window.location.href = '/Admin/ManageSubscription?SubscriptionID=' + params.row.id + "&CustomerID=" + params.row.customer_id;
                    }}
                >
                    View
                </Button>{ }
            </strong>
        )
    };

   const subscriptionColumns = [

        {
            field: 'cartid', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },
        {
            field: 'status',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Status',
            width: 110,
            valueGetter: (params) => {
                return params.row.status;
            } },
        {
            field: 'customer.company',
            headerName: 'Company Name',
            width: 300,
            headerAlign: 'left',
            editable: false,
            align: 'left',
            valueGetter: (params) => {
                return params.row.companyName;
            }
        },
        {
            field: 'oracleID',
            headerName: 'Customer ID',
            width: 100,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            valueGetter: (params) => {
                return params.row.customer_id;
            }
        },
        {
            field: 'start_date',
            headerName: 'Start Date',
            type: 'date',
            width: 100,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return new Date(params.row.start_date);
            }
        },
        {
            field: 'next_billing_at',
            headerName: 'Next Bill Date',
            type: 'date',
            width: 120,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                if (params.row.next_billing_at) {
                    return new Date(params.row.next_billing_at);
                }
                else {
                    null;
                }
               
            }
        },
        {
            field: 'billing_period_unit',
            headerName: 'Bill Frequency',
            type: 'date',
            width: 110,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            valueGetter: (params) => {
                if (params.row.billing_period && params.row.billing_period > 1)
                    return params.row.billing_period + ' ' + params.row.billing_period_unit;
                else
                    return params.row.billing_period_unit;
            }
        },
        {
            field: 'subscription_plan_id',
            headerName: 'Plan Info',
            width: 145,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            valueGetter: (params) => {
                return params.row.subscription_plan_id;
            }
        },
        
        
    ];
    const trialColumns = [

        {
            field: 'cartid', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },
        {
            field: 'status',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Status',
            width: 110,
            valueGetter: (params) => {
                return params.row.status;
            }
        },
        {
            field: 'customer.company',
            headerName: 'Company Name',
            width: 350,
            headerAlign: 'left',
            editable: false,
            align: 'left',
            valueGetter: (params) => {
                return params.row.companyName;
            }
        },
        {
            field: 'oracleID',
            headerName: 'Customer ID',
            width: 100,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            valueGetter: (params) => {
                return params.row.customer_id;
            }
        },
        {
            field: 'trialstartDate',
            headerName: 'Trial Start Date',
            type: 'date',
            width: 150,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return new Date(params.row.trialStartDate);
            }
        },
        {
            field: 'trialenddate',
            headerName: 'Trial End Date',
            type: 'date',
            width: 150,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return new Date(params.row.trialEndDate);
            }
        },
       
        {
            field: 'subscription_plan_id',
            headerName: 'Plan Info',
            width: 145,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            valueGetter: (params) => {
                return params.row.subscription_plan_id;
            }
        },


    ];
   const loadSubscriptions = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetAllSubscriptions?subscriptionStatus='+CurrentKPITile;

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setTableData(res.data.subscriptionInfo);
                    if (CurrentKPITile == "" || CurrentKPITile == "0")
                    {
                        updateTotalSubs(res.data.reportingMetrics.total_subscriptions);
                        updateactiveSubs(res.data.reportingMetrics.total_subscriptions_active);
                        updatefutureSubs(res.data.reportingMetrics.total_subscriptions_future);
                        updatecancelledSubs(res.data.reportingMetrics.total_subscriptions_cancelled);
                        loadingKPITile(false);
                    }
                   
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setDashboardErrorMsg(error.response.data.message);
                }
                else {
                    setDashboardErrorMsg(error.response.data);
                }

            });
    }
   const cards = [
        {
           loading: isKPITileLoading,
            title: 'TOTAL SUBSCRIPTIONS ',
            fullValue: totalSubscriptions,
            duration: 3.25,
           format: '',
           activeTile: 0
        },
        {
            loading: isKPITileLoading,
            title: 'ACTIVE SUBSCRIPTIONS ',
            fullValue: activeSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 1
        },
        {
            loading: isKPITileLoading,
            title: 'FUTURE SUBSCRIPTIONS ',
            fullValue: futureSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 2
        },
        {
            loading: isKPITileLoading,
            title: 'CANCELLED SUBSCRIPTIONS ',
            fullValue: cancelledSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 3
        }
       
    ];
   const trialcards = [
        {
            loading: isKPITileLoading,
            title: 'TOTAL TRIALS ',
            fullValue: totalSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 0
        },
       
        {
            loading: isKPITileLoading,
            title: 'CANCELLED/COMPLETED TRIALS ',
            fullValue: cancelledSubscriptions,
            duration: 3.25,
            format: '',
            activeTile: 3
        }
    ];
   const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(10)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );
   const filterSubscriptions = (e) => {
       let index = e.currentTarget.dataset.index;
       if (index != CurrentKPITile) {
           setActiveKPITile(index);
           setTableData([]);
          

       }
    }
    useEffect(() => {
        document.title = "Manage Subscriptions";
        setLoading(true);
        loadSubscriptions();

    }, [CurrentKPITile]);
    const handleAddNewSubsciptionDialogClose = () => {
        setOpenAddNewSubscriptionDialog(false);

    }
    const handleCreateNewSubscription = () => {
        setOpenAddNewSubscriptionDialog(true);
    }
    const Load_NewSubscriptionDialog = () => {
        return (
            <>
                <Dialog
                    open={openAddNewSubscriptionDialog}
                    onClose={handleAddNewSubsciptionDialogClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="Create New Subscription Wizard">Create New Subscription</DialogTitle>
                    <DialogContent><CreateNewSubscription currentCustomerID="" /></DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>
            </>
        )
    }
   
    return (
        <Container>
            <PermissionsGate
                scopes={[SCOPES.canViewSubscriptions]} 
                showMessage="false">
            <Row>
                <Col>
                    <h1>Manage Subscriptions</h1>
                </Col>
                </Row>
            </PermissionsGate>
            <PermissionsGate
                scopes={[SCOPES.canViewTrialSubscriptions]}
                showMessage="false" missingscopes={[SCOPES.canViewSubscriptions]}>
                <Row>
                    <Col>
                        <h1>Manage Trials</h1>
                    </Col>
                </Row>
            </PermissionsGate>
            <Row>
                <PermissionsGate
                    scopes={[SCOPES.canViewSubscriptions]}
                    showMessage="false">
                    {cards.map((item, index) => {
                        if (CurrentKPITile == item.activeTile) {
                            return (
                                <Col key={item.title + index} lg={3} sm={6} xl={3} xs={12} onClick={filterSubscriptions} data-index={item.activeTile}>
                                    <KPITile {...item} style={{ backgroundColor: "#e6e6e6" }} />
                                </Col>
                            );
                        }
                        return (
                            <Col key={item.title + index} lg={3} sm={6} xl={3} xs={12} onClick={filterSubscriptions} data-index={item.activeTile}>
                                <KPITile {...item} style={{ backgroundColor: "#fff", cursor: "pointer" }} />
                            </Col>
                        );
                    })}
                </PermissionsGate>
                <PermissionsGate
                    scopes={[SCOPES.canViewTrialSubscriptions]}
                    showMessage="false" missingscopes={[SCOPES.canViewSubscriptions]}>
                    {trialcards.map((item, index) => {
                        if (CurrentKPITile == item.activeTile) {
                            return (
                                <Col key={item.title + index} lg={3} sm={6} xl={3} xs={12} onClick={filterSubscriptions} data-index={item.activeTile}>
                                    <KPITile {...item} style={{ backgroundColor: "#e6e6e6" }} />
                                </Col>
                            );
                        }
                        return (
                            <Col key={item.title + index} lg={3} sm={6} xl={3} xs={12} onClick={filterSubscriptions} data-index={item.activeTile}>
                                <KPITile {...item} style={{ backgroundColor: "#fff", cursor: "pointer" }} />
                            </Col>
                        );
                    })}
                </PermissionsGate>
            </Row>
            <Row>
                <Col>
                    {dashboardErrMsg ? <Alert severity="error" >{dashboardErrMsg}</Alert> : ""}
                </Col>
            </Row>
            <Row>
                <Col><hr/></Col>
            </Row>
            <PermissionsGate scopes={[SCOPES.canViewSubscriptions, SCOPES.canViewTrialSubscriptions]} showMessage="false" >
                    <Row>
                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', flex: 1 }}>
                        <PermissionsGate scopes={[SCOPES.isAdmin]} showMessage="false">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="UWA_btn_Submit"
                                style={{ marginLeft: 16, marginBottom: 3 }}
                                onClick={handleCreateNewSubscription}
                            >  Create New Subscription
                            </Button>
                        </PermissionsGate>
                        <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} missingscopes={[SCOPES.canManageTrialSubscriptions]}  showMessage="false">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="UWA_btn_Submit"
                                style={{ marginLeft: 16, marginBottom: 3 }}
                                onClick={handleCreateNewSubscription }
                            >  Create New Subscription
                            </Button>
                        </PermissionsGate>
                        <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} missingscopes={[SCOPES.canManageSubscriptions]} showMessage="false">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="UWA_btn_Submit"
                                style={{ marginLeft: 16, marginBottom: 3 }}
                                onClick={handleCreateNewSubscription}
                            >  Create Trial Subscription
                            </Button>
                        </PermissionsGate>
                            <Load_NewSubscriptionDialog />
                            
                         </Col>
                </Row>
                    <Row>
                    <Col>
                        <div style={{ height: 550, width: '100%' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <PermissionsGate scopes={[SCOPES.canViewSubscriptions]}  showMessage="false" >
                                    <DataGrid
                                        rows={tableData}
                                        rowHeight={39}
                                        columns={subscriptionColumns}
                                        pageSize={10}
                                        getRowId={row => Math.random()}
                                        components={{
                                            LoadingOverlay: LoadingSkeleton
                                        }}
                                        loading={isloading}
                                        rowsPerPageOptions={[10]}
                                            disableColumnSelector={true} />
                                    </PermissionsGate>
                                    <PermissionsGate scopes={[SCOPES.canViewTrialSubscriptions]} missingscopes={[SCOPES.canViewSubscriptions]} showMessage="false" >
                                        <DataGrid
                                            rows={tableData}
                                            rowHeight={39}
                                            columns={trialColumns}
                                            pageSize={10}
                                            getRowId={row => Math.random()}
                                            components={{
                                                LoadingOverlay: LoadingSkeleton
                                            }}
                                            loading={isloading}
                                            rowsPerPageOptions={[10]}
                                            disableColumnSelector={true} />
                                    </PermissionsGate>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PermissionsGate>
      
            
        </Container>
        );
}