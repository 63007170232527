import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { GetSingleProduct } from './components/GetSingleProduct';
import GetShoppingCart  from './components/GetShoppingCart';
import { CheckoutWizard } from './components/CheckoutWizard';
import { GetAllProduct } from './components/GetAllProduct';
import ManageFIQCarts  from './components/FIQCartManagement/ManageFIQCarts';
import CreateFeasibilityIQCart  from './components/FIQCartManagement/CreateFeasibilityIQCart';
import  ManageFeasibilityIQCart from './components/ManageFeasibilityIQCart';
import cartfunctions from './_helpers/cartfunctions';
import { GetShoppingCartIcon } from './components/GetShoppingCartIcon';
import  ProtectedRoute  from './components/ProtectedRoute';

import ManageSubscriptions from './components/Subscriptions/ManageSubscriptions';
import ManageSubscription from './components/Subscriptions/ManageSubscription';
import SubscriptionDashboard from './components/Subscriptions/SubscriptionDashboard';


import UserManagementDashboard from './components/UserManagement/UserManagementDashboard';
import ManageIntegrationMaster from './components/IntegrationMaster/ManageIntegrationMaster';
import ManageEventLogs from './components/IntegrationMaster/ManageEventLogs';
import PermissionsGate from './security/PermissionGate';
import { SCOPES } from './security/permission-maps';   




// okta
import { Security, LoginCallback  } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import './custom.css'
import OktaSignIn from './components/OktaSignIn';
import Config from './_helpers/okta-config';
import OktaSignOut from './components/OktaSignOut';
import { commonfunctions } from './_helpers/commonfunctions';
import { authenticationService } from "./_services/authentication.service.js";

function App(props) {
    const [displayName, setDisplayName] = useState(null);
    const [currentCartID, updateCurrentCartID] = useState(cartfunctions.getCartID());
    const [widgetName, setWidgetName] = useState(null);
    const [chargebeeProductID, setChargebeeProductID] = useState(null);
    const [checkOutPageLink, setCheckOutPageLink] = useState(null);
    const [orientation, setOrientation] = useState();
    const [purchaseOnAccountLink, setPurchaseOnAccountLink] = useState();
    const [cartDetails, setCartDetails] = useState([]);
    const [shoppingcartPageLink, setShoppingCartPageLink] = useState([]);
    const oktaAuth = new OktaAuth(Config.oidc);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const componentIsMounted = useRef(true);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        await commonfunctions.logEvent("LOGIN", "User Login", "");
        await authenticationService.getUserRoles();
        window.location.href = (toRelativeUrl(originalUri, window.location.origin));
    };
   
    useEffect(() => {
         setWidgetName(props.domElement.getAttribute("widget-name"))
        setChargebeeProductID(props.domElement.getAttribute("cbproductid"));
        setCheckOutPageLink(props.domElement.getAttribute("checkoutpage"));
        setOrientation(props.domElement.getAttribute("orientation"));
        setPurchaseOnAccountLink(props.domElement.getAttribute("purchaseonaccountlink"));
        setShoppingCartPageLink(props.domElement.getAttribute("shoppingcartpage"));
        setDisplayName(App.name);
       
    }, []);
    useEffect(() => {
        const fetchCart = () => {
            const endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCart';
            const body = {
                cartID: currentCartID
            };
            if (!!currentCartID) {
               axios.post(serviceUrl, body)
                    .then((result) => {
                        if (result.status === 200) {
                            //console.log("inside effect");
                            //console.log(result.status);
                            setCartDetails(result.data);
                            updateCurrentCartID(result.data.cartID);
                           // console.log(cartDetails);
                        }
                    })
                   .catch(exception => {
                       cartfunctions.getNewShoppingCart()
                           .then(() => {
                               updateCurrentCartID(cartfunctions.getCartID());
                           });
                   });
            }
            else {
                //console.log("no cart");
                cartfunctions.getNewShoppingCart()
                    .then(() =>{
                        updateCurrentCartID(cartfunctions.getCartID());
                    });
            }
           

          
        };
        fetchCart();
       
    }, [currentCartID]);

    
    useEffect(async () => {
        if (componentIsMounted.current) {
            const currentLoginState = await oktaAuth.isAuthenticated();
            if (currentLoginState) {
                setIsLoggedIn(currentLoginState);
            }
            else {
                setIsLoggedIn(false);
            }
           
     
        }
        return () => {
            componentIsMounted.current = false;
        }
      
    }, [setIsLoggedIn]);
   
    const updateCartDetails = async () => {
        //console.log("calling parent prop update");
        //const prevValue = cartDetails;
        let currentCartID = cartfunctions.getCartID();
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCart';
        const body = {
            cartID: currentCartID
        };
        axios.post(serviceUrl, body)
            .then((result) => {
                if (result.status === 200) {
                    //console.log("inside update");
                     setCartDetails(result.data);
                 }
            });
      
       
    };
  
    



    

    const renderElement = () => {
        if (widgetName === 'Product') {
            return (
                <GetSingleProduct ProductID={chargebeeProductID} PurchaseOnAccountLink={purchaseOnAccountLink} CheckOutPageLink={checkOutPageLink} cartContents={cartDetails} updateCartContents={updateCartDetails} orientation={(orientation === null) ? "" : orientation}></GetSingleProduct>
            )
        }
        else if (widgetName === 'ShoppingCart') {
            return (
                <GetShoppingCart updateCartContents={updateCartDetails} cartContents={cartDetails} checkOutPageLink={checkOutPageLink} shoppingCartPageLink={shoppingcartPageLink}></GetShoppingCart>)
        }
        else if (widgetName === 'CheckoutWizard') {
            return (<CheckoutWizard cartContents={cartDetails} shoppingcartpage={shoppingcartPageLink} updateCartContents={updateCartDetails}></CheckoutWizard>)
        }
        else if (widgetName === 'ShoppingCartIcon') {
            return (<GetShoppingCartIcon cartContents={cartDetails} shoppingCartPageLink={shoppingcartPageLink}></GetShoppingCartIcon>)
        }
        else {
            return (
                <BrowserRouter>
                    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                        <Layout cartContents={cartDetails}>
                            <Routes>
                                <Route  path='/' element={<Home />} />
                                <Route  path="/login-okta" element={<OktaSignIn />} />
                                <Route path="/login/callback" element={<LoginCallback errorComponent="dummyComponent"/>} />
                                <Route  path="/logoff" element={<OktaSignOut />} />
                                <Route path='/GetSingleProduct' element={<GetSingleProduct cartContents={cartDetails} updateCartContents={updateCartDetails} PurchaseOnAccountLink="" />} />
                                <Route path='/GetAllProduct' element={<GetAllProduct cartContents={cartDetails} updateCartContents={updateCartDetails} />} PurchaseOnAccountLink="" />
                                <Route path='/GetShoppingCart' element={<GetShoppingCart cartContents={cartDetails} updateCartContents={updateCartDetails} checkOutPageLink="" shoppingCartPageLink=""/>} />
                                <Route path='/CheckoutWizard' element={<CheckoutWizard cartContents={cartDetails} updateCartContents={updateCartDetails} shoppingcartpage="" />} />
                                <Route path='/MyDashboard' element={<ProtectedRoute isLoggedIn={isLoggedIn}><SubscriptionDashboard /></ProtectedRoute>} />

                                <Route path='/Admin/ManageFIQCarts' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canManageFIQCarts]}> <ManageFIQCarts /></PermissionsGate></ProtectedRoute>} />
                                <Route path='/Admin/CreateFIQCart' element={<ProtectedRoute isLoggedIn={isLoggedIn}><CreateFeasibilityIQCart /></ProtectedRoute>} />
                                <Route path='/Admin/ManageFIQCart' element={<ProtectedRoute isLoggedIn={isLoggedIn}><ManageFeasibilityIQCart /></ProtectedRoute>} />
                                <Route path='/Admin/ManageSubscriptions' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canViewSubscriptions, SCOPES.canViewTrialSubscriptions]}><ManageSubscriptions /></PermissionsGate>
                                </ProtectedRoute>} />
                                <Route path='/Admin/ManageSubscription' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canViewSubscriptions, SCOPES.canManageSubscriptions, SCOPES.canViewTrialSubscriptions, SCOPES.canManageTrialSubscriptions]}><ManageSubscription /></PermissionsGate>
                                </ProtectedRoute>} />
                               
                                <Route path='/Admin/UserManagement' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canViewSubscriptions]}><UserManagementDashboard /></PermissionsGate>
                                </ProtectedRoute>} />

                                <Route path='/Admin/IntegrationMaster' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canManageIntegrationMaster]}><ManageIntegrationMaster /></PermissionsGate>
                                </ProtectedRoute>} />

                                <Route path='/Admin/EventLogs' element={<ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <PermissionsGate scopes={[SCOPES.canManageEventLogs]}><ManageEventLogs /></PermissionsGate>
                                </ProtectedRoute>} />
                                

                  
                                
                            </Routes>
                        </Layout>
                    </Security>
              
                 </BrowserRouter>
            );
        }
    }
    
    return (
        <>
            {renderElement()}
        </>
    )
}

export default App;