import React, { useEffect, useState, useRef } from 'react';
import { Card, CardContent, Stack, Button, Alert, Grid, Skeleton, FormLabel, FormControlLabel, Switch, Typography,  Autocomplete} from '@mui/material';
import TextField from '@mui/material/TextField';

import Avatar from '@mui/material/Avatar';
import { commonfunctions } from '../../_helpers/commonfunctions';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import MergeIcon from '@mui/icons-material/Merge';
import ConstructionIcon from '@mui/icons-material/Construction';
import Tooltip from '@mui/material/Tooltip';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
//import customParseFormat from "dayjs/plugin/customParseFormat";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';
export default function SubscriptionDetailsTile(props) {
    console.log(props);
    //dayjs.extend(customParseFormat);
    const [openExtendTrialDialog, setExtendTrialDialogStatus] = useState(false);
    const [openEndTrialDialog, setEndTrialDialogStatus] = useState(false);
    const [openCampaignDialog, setCampaignDialogStatus] = useState(false);
    const [openTrialDialog, setTrialDialogStatus] = useState(false);
    const [openConsolidationDialog, setConsolidationDialogStatus] = useState(false);
    const [ExtendTrialErrMsg, setExtendTrialErrMsg] = useState("");
    const [CancelTrialErrMsg, setCancelTrialErrMsg] = useState("");
    const relatedTrialSubscriptions = useRef([]);
    const relatedSubscriptions = useRef([]);
     
    const [trialEndDate, setTrialEndDate] = useState(dayjs());
   // console.log(commonfunctions.formatToDate(props.subscriptiondetails.trialEndDate));
  //  console.log(dayjs(commonfunctions.formatToDate(props.subscriptiondetails.trialEndDate), 'MM/DD/YYYY'));
   
   
   //console.log(props);
    const { loading, ...subscriptiondetails } = props;

    const handleCancelExtendTrialDialogClose = () => {
        setExtendTrialErrMsg("");
        setExtendTrialDialogStatus(false);
    }
    const handleExtendTrial = () => {
        setExtendTrialErrMsg("");
        setExtendTrialDialogStatus(true);
    }
    const handleOpenCampaignDialog = () => {
        setCampaignDialogStatus(true);
    }

    const handleOpenTrialDialog = () => {
        setTrialDialogStatus(true);
    }

    const handleOpenConsolidationDialog = () => {
        setConsolidationDialogStatus(true);
    }
    const handleExtendTrialSubmit = async() => {
        //Lets push this to the backend for processing
       
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/ExtendTrialEndDate';
        
        const trialInfo = {
            subscriptionID: props.subscriptiondetails.id,
            trialEndDate: trialEndDate
        };
        await axios.post(serviceUrl, trialInfo)
            .then(res => {
                if (res.status === 200) {
                    setExtendTrialErrMsg("");
                   // props.reloadsubscription();
                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + props.subscriptiondetails.id + "&CustomerID=" + props.subscriptiondetails.customer_id;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    setExtendTrialErrMsg(error.response.data.message);
                }
                else {
                    setExtendTrialErrMsg(error.response.data);
                }

            });
    }
    const handleCancelTrialSubmit = async () => {
        //Lets push this to the backend for processing

        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CancelTrialSubscription';

        const trialInfo = {
            subscriptionID: props.subscriptiondetails.id
        };
        await axios.post(serviceUrl, trialInfo)
            .then(res => {
                if (res.status === 200) {
                    setCancelTrialErrMsg("");
                    // props.reloadsubscription();
                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + props.subscriptiondetails.id + "&CustomerID=" + props.subscriptiondetails.customer_id;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    setCancelTrialErrMsg(error.response.data.message);
                }
                else {
                    setCancelTrialErrMsg(error.response.data);
                }

            });
    }
    const handleCancelEndTrialDialogClose = () => {
        setCancelTrialErrMsg("");
        setEndTrialDialogStatus(false);
    }
    const handleCancelCampaignDialogClose = () => {
        setCampaignDialogStatus(false);
    }
    const handleCancelConsolidationDialogClose = () => {
        setConsolidationDialogStatus(false);
    }
    const handleCancelTrialDialogClose = () => {
        setTrialDialogStatus(false);
    }
    const Load_RelatedTrialSubscriptions = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        try {
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetRelatedTrialSubscriptions/' + subscriptiondetails.subscriptiondetails.id;
            await axios.get(serviceUrl)
                .then((response) => {
                    //console.log(response.data);
                    relatedTrialSubscriptions.current = response.data.result;

                })
                .catch((error) => {
                    console.log('Error:', error);
                });

        }
        catch (error) {
            console.log('Error:', error);
        }
    }
    const Load_RelatedSubscriptions = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        try {
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetRelatedSubscriptions/' + subscriptiondetails.subscriptiondetails.id;
            await axios.get(serviceUrl)
                .then((response) => {
                   //console.log(response.data);
                    relatedSubscriptions.current = response.data.result;

                })
                .catch((error) => {
                    console.log('Error:', error);
                });

        }
        catch (error) {
            console.log('Error:', error);
        }
    }

    
    const handleEndTrial = () => {
        setCancelTrialErrMsg("");
        setEndTrialDialogStatus(true);
    }
    const LoadExtendTrialDialog = () => {
      
        return (
            <>
                <Dialog open={openExtendTrialDialog}
                    onClose={handleCancelExtendTrialDialogClose}>
                    <DialogTitle> {"Extend Trial"}</DialogTitle>
                    <DialogContent>
                        <FormLabel id="cancelSubscriptionLabel">Please enter the date you would like to extend the trial to. </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>

                            <DatePicker sx={{ width: 190 }}
                                getPopupContainer={() => document.getElementById("date-popup")}
                                popupStyle={{
                                    position: "relative"
                                }}
                                label="Revised End Date"
                                value={trialEndDate}
                                defaultValue={props.subscriptiondetails.trialEndDate}
                                defaultPickerValue={props.subscriptiondetails.trialEndDate}
                                onChange={(newValue) => { setExtendTrialErrMsg(""); setTrialEndDate(newValue); }}

                            />
                        </LocalizationProvider>
                        <div id="date-popup" />
                        {ExtendTrialErrMsg ? <Alert severity="error" >{ExtendTrialErrMsg}</Alert> : ""}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus={true} onClick={handleCancelExtendTrialDialogClose} variant="contained">
                            Cancel
                        </Button>
                        <Button autoFocus variant="contained" onClick={handleExtendTrialSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
    const LoadEndTrialDialog = () => {
        return (
            <>
                <Dialog open={openEndTrialDialog}
                    onClose={handleCancelEndTrialDialogClose}>
                    <DialogTitle> {"End Trial"}</DialogTitle>
                    <DialogContent>
                        <FormLabel id="cancelSubscriptionLabel">Are you sure you want to end this trial? Please click Submit to confirm this action. </FormLabel>

                        {CancelTrialErrMsg ? <Alert severity="error" >{CancelTrialErrMsg}</Alert> : ""}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus={true} onClick={handleCancelEndTrialDialogClose} variant="contained">
                            Cancel
                        </Button>
                        <Button autoFocus variant="contained" onClick={handleCancelTrialSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
    useEffect(() => {
        if (!subscriptiondetails.subscriptiondetails.isTrial) {
            //console.log("Opening Dialog");
            Load_RelatedTrialSubscriptions();
            Load_RelatedSubscriptions();
        }

    }, [openTrialDialog, openConsolidationDialog, subscriptiondetails.subscriptiondetails.id]);
   
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width="100%" height={200} />
            </div>
        );
    }
   // console.log(props);
    return (
        <Card  {...subscriptiondetails} >
            <CardContent >

                <LoadExtendTrialDialog />
                <LoadEndTrialDialog />
                <PermissionsGate
                    scopes={[SCOPES.canManageSubscriptions]}
                    showMessage="false">
                 <LoadCampaignModal campaignDialogStatus={openCampaignDialog} handleCloseCampaignDialog={handleCancelCampaignDialogClose} subscriptiondetails={props.subscriptiondetails} />
                <LoadTrialsModal trialDialogStatus={openTrialDialog} handleCloseTrialDialog={handleCancelTrialDialogClose} subscriptiondetails={props.subscriptiondetails} relatedtrialsubscriptions={relatedTrialSubscriptions.current} />
                <LoadConsolidationModal consolidationDialogStatus={openConsolidationDialog} handleCloseConsolidationDialog={handleCancelConsolidationDialogClose} subscriptiondetails={props.subscriptiondetails} relatedsubscriptions={relatedSubscriptions.current} />
                </PermissionsGate>
                    <Grid container>
                  
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item md={7} xs={12} >
                                <Stack direction="row" gap={1}>
                                <Avatar sx={{ bgcolor: '#2268e0' }}>
                                    <SubscriptionsIcon sx={{ width: 20, height: 20 }} />
                                </Avatar>
                                <Typography variant="h5">
                                    {subscriptiondetails.subscriptiondetails.id}
                                    </Typography>
                                    {subscriptiondetails.subscriptiondetails.campaignID ? <><Button variant="outlined" sx={{ m: '5px' }} disabled><CampaignIcon sx={{ width: 20, height: 20 }} ></CampaignIcon>{subscriptiondetails.subscriptiondetails.campaignID}</Button></> : null}
                                </Stack>
                            </Grid>
                            <PermissionsGate
                                scopes={[SCOPES.canManageSubscriptions]}
                                showMessage="false">
                                 <Grid item md={3} xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" gap={0.5}>
                                    <Tooltip title="Associate with Campaign"><Button variant="contained" className="UWA_btn_Submit" sx={{ m: '3px' }} onClick={handleOpenCampaignDialog}><CampaignIcon sx={{ width: 20, height: 20 }} /></Button></Tooltip>
                                    {(subscriptiondetails.subscriptiondetails.isTrial === false) ?
                                        <>     <Tooltip title="Include Original Trial Subscription"><Button variant="contained" className="UWA_btn_Submit" sx={{ m: '3px' }} onClick={handleOpenTrialDialog}><ConstructionIcon sx={{ width: 20, height: 20 }} /></Button></Tooltip>
                                            <Tooltip title="Consolidate from other Subscriptions"><Button variant="contained" className="UWA_btn_Submit" sx={{ m: '3px' }} onClick={handleOpenConsolidationDialog}><MergeIcon sx={{ width: 20, height: 20 }} /></Button></Tooltip>
                                        </> : null}
                                </Stack>
                                </Grid>
                                <Grid item md={2} xs={12} sx={{ textAlign: "end" }}>
                                    <Typography variant="h6" color={subscriptiondetails.subscriptiondetails.status == 'Cancelled' ? 'red' : (subscriptiondetails.subscriptiondetails.status == 'Active' ? '#2268e0' : 'green')}>
                                        {subscriptiondetails.subscriptiondetails.status}
                                    </Typography>
                                </Grid>
                            </PermissionsGate>
                            {subscriptiondetails.subscriptiondetails.isTrial ? <>
                                <PermissionsGate
                                    scopes={[SCOPES.canManageTrialSubscriptions]} missingscopes={[SCOPES.canManageSubscriptions]}
                                    showMessage="false">
                                    <Grid item md={5} xs={12} sx={{ textAlign: "end" }}>
                                        <Typography variant="h6" color={subscriptiondetails.subscriptiondetails.status == 'Cancelled' ? 'red' : (subscriptiondetails.subscriptiondetails.status == 'Active' ? '#2268e0' : 'green')}>
                                            {subscriptiondetails.subscriptiondetails.status}
                                        </Typography>
                                    </Grid>
                                </PermissionsGate>
                            </> : null}
                            
                            <Grid item md={4} xs={12} zeroMinWidth>
                                <Typography color="textSecondary">
                                    Create Date:
                                </Typography>
                                <Typography style={{ overflowWrap: 'break-word' }}>
                                    {commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.created_at)}
                                </Typography>
                            </Grid>
                           <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    Start Date:
                                </Typography>
                                <Typography>
                                    {commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.start_date)}
                                </Typography>
                                </Grid>
                           <Grid item md={4} xs={12}>
                                    <Typography color="textSecondary">
                                        Next Billing on:
                                    </Typography>
                                    <Typography>
                                        {commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.next_billing_at)}
                                    </Typography>
                                </Grid>
                            
                          
                            <Grid item md={4} xs={12} zeroMinWidth>
                                <Typography color="textSecondary">
                                    Billing Frequency:
                                </Typography>
                                <Typography style={{ overflowWrap: 'break-word' }}>
                                    {subscriptiondetails.subscriptiondetails.billing_period_unit}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    Subscription Amount:
                                </Typography>
                                <Typography>
                                    {commonfunctions.formatToCurrency(subscriptiondetails.subscriptiondetails.subscription_total)}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    MRR:
                                </Typography>
                                <Typography>
                                    {commonfunctions.formatToCurrency(subscriptiondetails.subscriptiondetails.mrr)}
                                </Typography>
                            </Grid>
                            {(subscriptiondetails.subscriptiondetails.cF_TC_Version ? 
                                <>
                                    <Grid item md={4} xs={12} zeroMinWidth>
                                        <Typography color="textSecondary">
                                            T&C Accepted By:
                                        </Typography>
                                        <Typography style={{ overflowWrap: 'break-word' }}>
                                            {subscriptiondetails.subscriptiondetails.cF_TC_AcceptedBy}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography color="textSecondary">
                                            T&C Acceptance Date:
                                        </Typography>
                                        <Typography>
                                            {(subscriptiondetails.subscriptiondetails.cF_TC_AcceptanceDate ? commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.cF_TC_AcceptanceDate) : 'N/A')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography color="textSecondary">
                                            T&C Version:
                                        </Typography>
                                        <Typography>
                                            {subscriptiondetails.subscriptiondetails.cF_TC_Version}
                                        </Typography>
                                    </Grid>
                                </>
                            : <></>
                            )}
                           


                            <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions, SCOPES.canManageSubscriptions]} showMessage="false">
                            {subscriptiondetails.subscriptiondetails.status == 'InTrial' ?
                                <>
                                    <Grid item md={12} xs={12}>
                                        <Alert severity="error">Note that this is a Trial Subscription
                                            <Button variant="contained" className="UWA_btn_Submit" sx={{ m: '5px' }} onClick={handleExtendTrial}>Extend Trial</Button>
                                            <Button variant="contained" className="UWA_btn_Submit" sx={{ m: '5px' }} onClick={handleEndTrial}>End Trial</Button></Alert>
                                    </Grid>
                                    </> : null}
                            </PermissionsGate>
                            {(subscriptiondetails.subscriptiondetails.status != 'InTrial' && subscriptiondetails.subscriptiondetails.isTrial === true) ?
                            <>
                                <Grid item md={12} xs={12}>
                                    <Alert severity="error">This subscription started as a Trial Subscription</Alert>
                                </Grid>
                            </> : null}
                            {subscriptiondetails.subscriptiondetails.isTrial === true ? <>
                                <Grid item md={4} xs={12}>
                                    <Typography color="textSecondary">
                                        Trial Start Date:
                                    </Typography>
                                    <Typography>
                                        {commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.trialStartDate)}
                                    </Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Typography color="textSecondary">
                                        Trial End Date:
                                    </Typography>
                                    <Typography>
                                        {commonfunctions.formatToDate(subscriptiondetails.subscriptiondetails.trialEndDate)}
                                    </Typography>
                                </Grid>
                            </> :null}
                        </Grid>
                        
                    </Grid>
                   
                </Grid>
            </CardContent>
        </Card>
    );
}

export function LoadCampaignModal(props) {
    //console.log(props);
    const campaignDialogStatus = props.campaignDialogStatus;
    const [CampaignErrMsg, setCampaignErrMsg] = useState("");
    const [CampaignIDAtItemLevel, setCampaignIDAtItemLevel] = useState(props.subscriptiondetails.manageCampaignAtLineLevel);
    const [CampaignID, setCampaignID] = useState(props.subscriptiondetails.campaignID);
    const [LineCampaignID, setLineCampaignID] = useState(props.subscriptiondetails.meta_data);
    const handleHeaderCampaignIDChange = (e) => {
        setCampaignID(e.target.value);
    }

    const handleLineCampaignIDChange = (e) => {
        let key = e.currentTarget.dataset.key;
        var tempLineCampaignID = LineCampaignID;
       
        tempLineCampaignID && tempLineCampaignID.subscriptionInfo.map((item) => {
            if (item.key === key)
            {
                item.cbCampaignID = e.target.value;
            }
        });
        setLineCampaignID(tempLineCampaignID);

    }
    const validateCampaignID = () => {
        var success = true;
        if (!CampaignIDAtItemLevel) {
            if (typeof (CampaignID) != "undefined") {
                success = false;
            }
        }
        else {
            var tempLineCampaignID = LineCampaignID;
            success = false;
            tempLineCampaignID && tempLineCampaignID.subscriptionInfo.map((item) => {
                if (item.cbcampaignID.length > 0) {
                    success = true;
                }
            });
        }
        return success;
    }
    const handleCampaignSubmit = async () => {
        if (!validateCampaignID) {
            setCampaignErrMsg("You must enter a Campaign ID either at the Header or Item Level before attempting to save.");
            return;
        }
        setCampaignErrMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/UpdateCampaignIDOnSubscription';
        const requestOptions = {
            isitemlevel: CampaignIDAtItemLevel,
            subscriptionid: props.subscriptiondetails.id,
            campaignid: CampaignID,
            subscriptiondetails: LineCampaignID
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + props.subscriptiondetails.id + "&CustomerID=" + props.subscriptiondetails.customer_id;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    setCampaignErrMsg(error.response.data.message);
                }
                else {
                    setCampaignErrMsg(error.response.data);
                }
            });
    }
    const handleCampaignIDLevelChange = async (e) => {
        setCampaignIDAtItemLevel(!CampaignIDAtItemLevel);
    }
    
    return (
        <>
            <Dialog open={campaignDialogStatus}
                onClose={props.handleCloseCampaignDialog} >
                <DialogTitle> {"Campaign ID Information"}</DialogTitle>
                <CloseIcon
                    aria-label="close"
                    onClick={props.handleCloseCampaignDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
              
                </CloseIcon>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {props.subscriptiondetails.meta_data ?
                            <>
                                <Grid item xs={12} md={12} >
                                    <Stack direction="row" justifyContent="end">
                                        <FormControlLabel control={<Switch onChange={handleCampaignIDLevelChange} checked={CampaignIDAtItemLevel} key={"cbManageAtItemLevel"} />} label="Manage at the Item Level" labelPlacement="start" />
                                    </Stack>
                                </Grid>
                        </> : null}
                        
                        {(CampaignIDAtItemLevel === true) ?
                            <>

                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterIndividualCampaignIDMsg'>Please enter the Campaign ID for each unit on this Subscription </FormLabel>
                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterCampaignIDMsg'>Please enter the Campaign ID for this Subscription </FormLabel>
                                </Grid>
                            </>}
                        {(CampaignIDAtItemLevel === true) ?
                            <>
                                <Grid item xs={12} md={12}>
                                    <center>
                                         <Grid container spacing={2} sx={{ width: '80%' }} justifyContent="middle">
                                    {props.subscriptiondetails.meta_data && props.subscriptiondetails.meta_data.subscriptionInfo.map((item) => {
                                        //console.log(item);
                                        return (
                                                <React.Fragment key={item.key}>
                                                <Grid item xs={6} md={6}>
                                                    {item.key}
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <TextField style={{ width: '100%' }}
                                                        name="campaignID"
                                                        label="Campaign ID"
                                                        size="small"
                                                        required
                                                        defaultValue={item.cbCampaignID}
                                                        inputProps={{ 'data-key': item.key }}
                                                        onChange={handleLineCampaignIDChange }
                                                    />
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                    })}
                                    </Grid>
                                    </center>
                                </Grid>
                            </> :

                            <Grid item xs={12} md={12}>
                                <TextField style={{ width: '100%' }}
                                    name="campaignID"
                                    id="campaignID"
                                    label="Campaign ID"
                                    size="small"
                                    required
                                    defaultValue={CampaignID}
                                    onChange={handleHeaderCampaignIDChange}
                                />
                                
                            
                            </Grid>
                        }
                    </Grid>
                    {CampaignErrMsg ? <Alert severity="error" >{CampaignErrMsg}</Alert> : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={props.handleCloseCampaignDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleCampaignSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
export function LoadTrialsModal(props) {
    //console.log(props);
    const trialDialogStatus = props.trialDialogStatus;
    const [TrialErrMsg, setTrialErrMsg] = useState("");
    const [TrialIDAtItemLevel, setTrialIDAtItemLevel] = useState(props.subscriptiondetails.manageRelatedAtLineLevel);
    const [TrialID, setTrialID] = useState(props.subscriptiondetails.relatedSubscriptionID);
    const [LineTrialID, setLineTrialID] = useState(props.subscriptiondetails.meta_data);
    const handleHeaderTrialIDChange = (event, values) => {
        setTrialID(values.id);
    }

    const handleLineTrialIDChange = (event, values, eventType) => {
        let key = event.currentTarget.dataset.key;
        var tempLineTrialID = LineTrialID;
        if (eventType === 'clear') {

        }
        else {
            tempLineTrialID && tempLineTrialID.subscriptionInfo.map((item) => {
                if (item.key === key) {
                    item.relatedCBSubscriptionID = values.id;
                }
            });

        }
        setLineTrialID(tempLineTrialID);

    }
    const handleClearButton = (key) => {
        var tempLineTrialID = LineTrialID;
        tempLineTrialID && tempLineTrialID.subscriptionInfo.map((item) => {
            if (item.key === key) {
                item.relatedCBSubscriptionID = null;
            }
        });
        setLineTrialID(tempLineTrialID);
    }
    const validateTrialID = () => {
        var success = true;
        if (!TrialIDAtItemLevel) {
            if (typeof (TrialID) != "undefined") {
                success = false;
            }
        }
        else {
            var tempLineTrialID = LineTrialID;
            success = false;
            tempLineTrialID && tempLineTrialID.subscriptionInfo.map((item) => {
                try {
                    if (item.relatedCBSubscriptionID.length > 0) {
                        success = true;
                    }
                }
                catch (exception) {
                    //Item doesnt exist for one of the lines. that's fine.'
                }
               
            });
        }
        return success;
    }
    const handleTrialSubmit = async () => {
        if (!validateTrialID()) {
            setTrialErrMsg("You must enter a Trial ID either at the Header or Item Level before attempting to save.");
            return;
        }
        setTrialErrMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/UpdateTrialIDOnSubscription';
        const requestOptions = {
            isitemlevel: TrialIDAtItemLevel,
            subscriptionid: props.subscriptiondetails.id,
            relatedsubscriptionid: TrialID,
            subscriptiondetails: LineTrialID
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + props.subscriptiondetails.id + "&CustomerID=" + props.subscriptiondetails.customer_id;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    setTrialErrMsg(error.response.data.message);
                }
                else {
                    setTrialErrMsg(error.response.data);
                }
            });
    }
    const handleTrialIDLevelChange = async (e) => {
        setTrialIDAtItemLevel(!TrialIDAtItemLevel);
    }

    return (
        <>
            <Dialog open={trialDialogStatus}
                onClose={props.handleCloseTrialDialog} >
                <DialogTitle> {"Trial ID Information"}</DialogTitle>
                <CloseIcon
                    aria-label="close"
                    onClick={props.handleCloseTrialDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                </CloseIcon>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {props.subscriptiondetails.meta_data ?
                            <>
                                <Grid item xs={12} md={12} >
                                    <Stack direction="row" justifyContent="end">
                                        <FormControlLabel control={<Switch onChange={handleTrialIDLevelChange} checked={TrialIDAtItemLevel} key={"cbManageAtItemLevel"} />} label="Manage at the Item Level" labelPlacement="start" />
                                    </Stack>
                                </Grid>
                            </> : null}

                        {(TrialIDAtItemLevel === true) ?
                            <>

                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterIndividualTrialIDMsg'>Please pick the Trial ID for each unit on this Subscription </FormLabel>
                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterTrialIDMsg'>Please pick the Trial ID for this Subscription </FormLabel>
                                </Grid>
                            </>}
                        {(TrialIDAtItemLevel === true) ?
                            <>
                                <Grid item xs={12} md={12}>
                                    <center>
                                        <Grid container spacing={2} sx={{ width: '80%' }} justifyContent="left">
                                            {props.subscriptiondetails.meta_data && props.subscriptiondetails.meta_data.subscriptionInfo.map((item) => {
                                                //console.log(item);
                                                return (
                                                    <React.Fragment key={item.key}>
                                                        <Grid item xs={4} md={4}>
                                                            {item.key}
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            {item.relatedCBSubscriptionID && item.relatedSubscriptionIDIsTrial === false ? 
                                                                <>
                                                                    <TextField style={{ width: '100%' }}
                                                                        name="relatedTrialID"
                                                                        label="Original Consolidated ID"
                                                                        size="small"
                                                                        disabled
                                                                        defaultValue={item.relatedCBSubscriptionID}
                                                                        inputProps={{ 'data-key': item.key }}
                                                                    />
                                                                </>
                                                                : <>
                                                                    <Autocomplete
                                                                        clearIcon={
                                                                            <CloseIcon
                                                                                onClick={() => handleClearButton(`${item.key}`)}
                                                                                sx={{
                                                                                    width: '1.5rem',
                                                                                    height: '1.5rem',
                                                                                }}
                                                                                aria-label="close"
                                                                            >
                                                                         
                                                                            </CloseIcon>}
                                                                        disablePortal
                                                                        defaultValue={props.relatedtrialsubscriptions.find(r => r.id === item.relatedCBSubscriptionID)}
                                                                        options={props.relatedtrialsubscriptions}
                                                                        getOptionLabel={(option) => (option ? option.id : "")}
                                                                        onChange={handleLineTrialIDChange}
                                                                        renderOption={(props, option) => {
                                                                            //console.log("props");
                                                                            //console.log(option);
                                                                            return <li {...props} data-key={`${item.key}`} >{`${props.key}`}</li>;
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} label="Trial ID" />}
                                                                    />
                                                                </>
                                                            }
                                                           
                                                           
                                                        </Grid>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Grid>
                                    </center>
                                </Grid>
                            </> :

                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    disablePortal
                                    options={props.relatedtrialsubscriptions}
                                    getOptionLabel={(option) => (option ? option.id + " / [" + commonfunctions.formatToDate( option.startDate) + "]" : "")}
                                    sx={{ width: 400 }}
                                    onChange={handleHeaderTrialIDChange}
                                    renderInput={(params) => <TextField {...params} label="Trial ID / [Start Date]"  />}
                                />
                                


                            </Grid>
                        }
                    </Grid>
                    {TrialErrMsg ? <Alert severity="error" >{TrialErrMsg}</Alert> : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={props.handleCloseTrialDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleTrialSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
export function LoadConsolidationModal(props) {
   // console.log(props);
    const ConsolidationDialogStatus = props.consolidationDialogStatus;
    const [ConsolidationErrMsg, setConsolidationErrMsg] = useState("");
    //const [ConsolidationIDAtItemLevel, setConsolidationIDAtItemLevel] = useState(props.subscriptiondetails.manageRelatedAtLineLevel);
    const [ConsolidationIDAtItemLevel, setConsolidationIDAtItemLevel] = useState(true);
    const [ConsolidationID, setConsolidationID] = useState(props.subscriptiondetails.relatedSubscriptionID);
    const [LineConsolidationID, setLineConsolidationID] = useState(props.subscriptiondetails.meta_data);
    const handleHeaderConsolidationIDChange = (event, values) => {
        setConsolidationID(values.id);
    }

    const handleLineConsolidationIDChange = (event, values, eventType) => {
        let key = event.currentTarget.dataset.key;
        var tempLineConsolidationID = LineConsolidationID;
        if (eventType === 'clear') {

        }
        else {
            tempLineConsolidationID && tempLineConsolidationID.subscriptionInfo.map((item) => {
                if (item.key === key) {
                    item.relatedCBSubscriptionID = values.id;
                }
            });

        }
        setLineConsolidationID(tempLineConsolidationID);

    }
    const handleClearButton = (key) => {
        var tempLineConsolidationID = LineConsolidationID;
        tempLineConsolidationID && tempLineConsolidationID.subscriptionInfo.map((item) => {
            if (item.key === key) {
                item.relatedCBSubscriptionID = null;
            }
        });
        setLineConsolidationID(tempLineConsolidationID);
    }
    const validateConsolidationID = () => {
        var success = true;
        if (!ConsolidationIDAtItemLevel) {
            if (typeof (ConsolidationID) != "undefined") {
                success = false;
            }
        }
        else {
            var tempLineConsolidationID = LineConsolidationID;
            success = false;
            tempLineConsolidationID && tempLineConsolidationID.subscriptionInfo.map((item) => {
                try {
                    if (item.relatedCBSubscriptionID.length > 0) {
                        success = true;
                    }
                }
                catch (exception) {
                    //Item doesnt exist for one of the lines. that's fine.'
                }

            });
        }
        return success;
    }
    const handleConsolidationSubmit = async () => {
        if (!validateConsolidationID()) {
            setConsolidationErrMsg("You must enter a Consolidatio ID either at the Header or Item Level before attempting to save.");
            return;
        }
        setConsolidationErrMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/UpdateConsolidatedIDOnSubscription';
        const requestOptions = {
            isitemlevel: ConsolidationIDAtItemLevel,
            subscriptionid: props.subscriptiondetails.id,
            relatedsubscriptionid: ConsolidationID,
            subscriptiondetails: LineConsolidationID
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + props.subscriptiondetails.id + "&CustomerID=" + props.subscriptiondetails.customer_id;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    setTrialErrMsg(error.response.data.message);
                }
                else {
                    setTrialErrMsg(error.response.data);
                }
            });
    }
    const handleConsolidationIDLevelChange = async (e) => {
        setConsolidationIDAtItemLevel(!ConsolidationIDAtItemLevel);
    }

    return (
        <>
            <Dialog open={ConsolidationDialogStatus}
                onClose={props.handleCloseConsolidationDialog} >
                <DialogTitle> {"Consolidation ID Information"}</DialogTitle>
                <CloseIcon
                    aria-label="close"
                    onClick={props.handleCloseConsolidationDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                </CloseIcon>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {props.subscriptiondetails.meta_data ?
                            <>
                                <Grid item xs={12} md={12} >
                                    <Stack direction="row" justifyContent="end">
                                        <FormControlLabel control={<Switch disabled onChange={handleConsolidationIDLevelChange} checked={ConsolidationIDAtItemLevel} key={"cbManageAtItemLevel"} />} label="Manage at the Item Level" labelPlacement="start" />
                                    </Stack>
                                </Grid>
                            </> : <Grid item xs={12} md={12} >
                                <Stack direction="row" justifyContent="end">
                                    <FormControlLabel control={<Switch disabled onChange={handleConsolidationIDLevelChange} checked={ConsolidationIDAtItemLevel} key={"cbManageAtHeaderLevel"} />} label="Manage at the Header Level" labelPlacement="start" />
                                </Stack>
                            </Grid>}

                        {(ConsolidationIDAtItemLevel === true) ?
                            <>

                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterIndividualConsolidationIDMsg'>Please pick the Subscription from which you are consolidating each unit on this Subscription </FormLabel>
                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12} md={12}>
                                    <FormLabel id='LblEnterHeaderConsolidationIDMsg'>Please pick the Subscription from which you are consolidating on this Subscription </FormLabel>
                                </Grid>
                            </>}
                        {(ConsolidationIDAtItemLevel === true) ?
                            <>
                                <Grid item xs={12} md={12}>
                                    <center>
                                        <Grid container spacing={2} sx={{ width: '80%' }} justifyContent="left">
                                            {(props.subscriptiondetails.meta_data) && props.relatedsubscriptions.length > 0 && props.subscriptiondetails.meta_data.subscriptionInfo.map((item) => {
                                                //console.log(item);
                                                return (
                                                    <React.Fragment key={item.key}>
                                                        <Grid item xs={4} md={4}>
                                                            {item.key}
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            {item.relatedSubscriptionIDIsTrial === true ?
                                                                <>
                                                                    <TextField style={{ width: '100%' }}
                                                                        name="relatedTrialID"
                                                                        label="Original Trial ID"
                                                                        size="small"
                                                                        disabled
                                                                        defaultValue={item.relatedCBSubscriptionID}
                                                                        inputProps={{ 'data-key': item.key }}
                                                                    />
                                                                </> :

                                                                <>
                                                                    <Autocomplete
                                                                        clearIcon={
                                                                            <CloseIcon
                                                                                onClick={() => handleClearButton(`${item.key}`)}
                                                                                sx={{
                                                                                    width: '1.5rem',
                                                                                    height: '1.5rem',
                                                                                }}>
                                                                            </CloseIcon>}
                                                                        disablePortal
                                                                        name={item.key}
                                                                        defaultValue={props.relatedsubscriptions.find(r => r.id === item.relatedCBSubscriptionID)}
                                                                        key={item.key}
                                                                        options={props.relatedsubscriptions}
                                                                        getOptionLabel={(option) => (option ? option.id : "")}
                                                                        onChange={handleLineConsolidationIDChange}
                                                                        renderOption={(props, option) => {
                                                                            //console.log("props");
                                                                            //console.log(option);
                                                                            return <li {...props} data-key={`${item.key}`} >{`${props.key}`}</li>;
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} label="Subscription ID" />}
                                                                    />
                                                                </>}
                                                            

                                                        </Grid>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Grid>
                                    </center>
                                </Grid>
                            </> :

                            <Grid item xs={12} md={12}>
                                {props.relatedsubscriptions && props.relatedsubscriptions.length > 0 ?
                                <>
                                        <Autocomplete
                                            disablePortal
                                            options={props.relatedsubscriptions}
                                            getOptionLabel={(option) => (option ? option.id + " / [" + commonfunctions.formatToDate(option.startDate) + "]" : "")}
                                            sx={{ width: 400 }}
                                            onChange={handleHeaderConsolidationIDChange}
                                            renderInput={(params) => <TextField {...params} label="Subscription ID" />}
                                        />
                                </> : null}
                                



                            </Grid>
                        }
                    </Grid>
                    {ConsolidationErrMsg ? <Alert severity="error" >{ConsolidationErrMsg}</Alert> : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={props.handleCloseConsolidationDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleConsolidationSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}