import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, LinearProgress } from '@mui/material';
import CountUp from 'react-countup';
import CircularProgress from '@mui/material/CircularProgress';

export default function KPITile(props) {
    const [prefix, setPrefix] = useState(null);
     const [postfix, setPostfix] = useState(null);
    const { loading, title, progress, fullValue, difference, duration, format, activeTile, ...rest } = props;
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="secondary" />
            </div>
        );
    };
   
    return (
        <Card {...rest} >
            <CardContent >
                <Grid container justify="space-between" >
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom >
                            {title}
                        </Typography>
                        <Typography variant="h3">
                            {prefix}
                            <CountUp end={fullValue} duration={duration} separator="," decimals={0} />
                            {postfix}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}


