import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { commonfunctions } from '../_helpers/commonfunctions';

const OktaSignIn = () => {
    const { authState, oktaAuth } = useOktaAuth();

    // handle login 
    const handleLogin = async () => {
        await oktaAuth.signInWithRedirect({ originalUri: '/MyDashboard' });
    
    }

    //const handleLogout = async () => {
    //   await oktaAuth.signOut({ originalUri: '/login-okta' });
  
    //}

    
    useEffect(() => {
        handleLogin();
    }, []);

    if (!authState || !authState.isAuthenticated) {
        return <></>;
    } else {
        return `You're authenticated`;
    }
}

export default OktaSignIn;