import React, { Component } from 'react';
import AddShoppingCart from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import cartfunctions from '../_helpers/cartfunctions';
export class GetShoppingCartIcon extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            loading: false,
            shoppingCartPageLink: props.shoppingCartPageLink,
            cartDetails: props.cartContents,
            cartItemCount: cartfunctions.getCartItemCount()
        };
        this.handleShoppingCartClick = this.handleShoppingCartClick.bind(this);
        //this.cartItemCount = this.getCartItemsCount();

    }
    
  
    //componentDidMount() {
    //}
    //componentDidUpdate() {
    //}
    notificationsLabel(count) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 9) {
            return 'more than 9 items in your cart';
        }
        return `${count} notifications`;
    }
    getCartItemsCount(cartDetails) {
        //var cartDetails = this.props.cartContents;
       // console.log(cartDetails);
        var qty = 0;
        if (!!cartDetails) {
            //cartDetails = [];
            qty = cartDetails.quantity;
        }
        else {
            qty = 0;
        }
        //var qty = 0;
        //for (var i in cartDetails) {
        //    qty += cartDetails[i].qty;
        //}
        //console.log(qty);
        return qty;
       
    }
    handleShoppingCartClick() {
        window.location.href = (this.state.shoppingCartPageLink === '' ? '/GetShoppingCart' : this.state.shoppingCartPageLink);
    }
    renderShoppingCartIcon(cartContents) {
       // console.log("LETS SEE IF THIS CHANGES");
        const cartItemCount = this.getCartItemsCount(cartContents);
        return (
            <IconButton aria-label={this.notificationsLabel(cartItemCount)} onClick={this.handleShoppingCartClick}>
                <Badge badgeContent={cartItemCount} max={9} sx={{
                    "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "red"
                    }
                }}>
                    <AddShoppingCart />
                </Badge>
            </IconButton>
        );
    }
    render() {
        let contents = this.state.loading ? <span>..</span> : this.renderShoppingCartIcon(this.props.cartContents);
        return (
            <span>{contents}</span>
        );
    }

   
}