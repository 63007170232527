import React, { useEffect, useState} from 'react';
import { Card, CardContent, CardMedia, CardHeader, Skeleton, Select, MenuItem, FormControl, InputLabel, CardActions, IconButton, Button, Tooltip, Alert, TextField } from '@mui/material';
import { Container, Row, Col } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MergeIcon from '@mui/icons-material/Merge';
import ConstructionIcon from '@mui/icons-material/Construction';
import CampaignIcon from '@mui/icons-material/Campaign';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RegistryForm from './MetaDataComponents/RegistryForm.js';
import UserForm from './MetaDataComponents/UserForm.js';
import AGPTextronBadge from './MetaDataComponents/AGPTextronBadge.js';
import RWABadge from './MetaDataComponents/RWABadge.js';
import RWAWBBadge from './MetaDataComponents/RWAWBBadge.js';
import UnknownBadge from './MetaDataComponents/UnknownBadge.js';
import axios from 'axios';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   

export default function SubscriptionItemDetails(props) {
    const { loading, subscriptionCancellationReasons, subscriptionID, customerID, pricingModel, subscriptionStatus, subscriptionDetails, isTrial, ...itemDetails } = props;
    const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
    const [openUserEditDialog, setOpenUserEditDialog] = useState(false);
    const [openRegistryDeleteDialog, setOpenRegistryDeleteDialog] = useState(false);
    const [openRegistryEditDialog, setOpenRegistryEditDialog] = useState(false);
    const [openSeatLinkDialog, setOpenSeatLinkDialog] = useState(false);
    const [registryDialogTitle, setRegistryDialogTitle] = useState("Confirm Registry Deletion?");
    const [registryDialogMsg, setRegistryDialogMsg] = useState("");
    const [registryEditDialogMsg] = useState("Please use the form below to edit the details about this registry. Please keep in mind that you cannot have multiple registries on the same subscription with the same Registry/Tail #.")
    const [editregistryDialogTitle] = useState("Edit Registry Details");
    const [userEditDialogMsg] = useState("Please use the form below to edit the details about this user. Please keep in mind that you cannot have multiple users on the same subscription with the same username.")
    const [edituserDialogTitle] = useState("Edit User Details");
    const [linkSeatDialogTitle] = useState("Link Seat Details");
    const [linkSeatDialogMsg] = useState("Please select the end-dated subscription seat you wish to link to this seat. Upon successfully linking the seat, both the existing and the end-dated seat will have a link to each other.")
    const [validationErrMsg, setValidationErrMsg] = useState("");
    const [validationLinkSeatErrMsg, setLinkSeatValidationErrMsg] = useState("");
    const [yesoptionDisabled, disableYesOption] = useState(false);
    const subscriptionItemKey = React.useRef(itemDetails.itemDetails.key);
    const subscriptionItemOriginalKey = React.useRef(itemDetails.itemDetails.originalKey);
    const subscriptionItemDetails = React.useRef(itemDetails.itemDetails);
    const subscriptionItemFirstName = React.useRef(itemDetails.itemDetails.firstName);
    const subscriptionItemLastName = React.useRef(itemDetails.itemDetails.lastName);
    const subscriptionItemEmail = React.useRef(itemDetails.itemDetails.email);
    const [lstCancelReasons] = React.useState(props.subscriptionCancellationReasons);
    const [subscriptionCancelReason, updateSubscriptionCancelReason] = useState("");
    const [tableData, setTableData] = React.useState([]);
    const selectedSeatRow = React.useRef([]);

  
   // console.log(subscriptionDetails);
   //console.log("TEST USER DETAILS");
   //console.log(props);
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width={100} height={100} />
            </div>
        );
    }
    useEffect(() => {
        lstCancelReasons.current = (props.subscriptionCancellationReasons.current);
       
    }, []);
   
    const unlinkedSeatsColumns = [
        {
            field: 'SubscriptionID',
            headerAlign: 'left',
            align: 'left',
            headerName: 'Subscription ID',
            width: 160,
            disableColumnMenu: true,
            sortable: false,
            editable: false,
            valueGetter: (params) => {
                return params.row.subscriptionItem.subscription.cbSubscriptionId;
            }
        },
        {
            field: 'Key',
            headerAlign: 'left',
            align: 'left',
            headerName: 'Key',
            width: 110,
            valueGetter: (params) => {
                return params.row.key;
            }
        },
        {
            field: 'Start Date',
            headerAlign: 'right',
            align: 'right',
            type: 'date',
            headerName: 'Start Date',
            width: 110,
            valueGetter: (params) => {
                return new Date(params.row.startDate);
            }
        },
        {
            field: 'End Date',
            headerAlign: 'right',
            align: 'right',
            type: 'date',
            headerName: 'End Date',
            width: 110,
            valueGetter: (params) => {
                return new Date(params.row.endDate);
            }
        },
        {
            field: 'Cancel Reason',
            headerAlign: 'left',
            align: 'left',
            headerName: 'Cancel Reason',
            width: 230,
            valueGetter: (params) => {
                return params.row.cancelReason;
            }
        },
    ];

    const handleCancellationReasonChange = (e) => {
        updateSubscriptionCancelReason(e.target.value);
        setValidationErrMsg("");
        subscriptionItemDetails.current.cancelReason = e.target.value;
    }
    const handleSaveSeatLink = async(e) => {
        console.log(selectedSeatRow.current.length);
        if (selectedSeatRow.current.length == 0) {
            setLinkSeatValidationErrMsg("You must select a row before attempting to link");
            return false;
        }
        else {
            var _key = e.currentTarget.dataset.key;
            var _subscriptionID = props.subscriptionid;
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/UpdateSeatLink';
            const subscriptionInfo = {
                currentSubscriptionID: _subscriptionID,
                currentSubscriptionKey: _key,
                linkedSubscriptionID: selectedSeatRow.current[0].subscriptionItem.subscription.cbSubscriptionId,
                linkedSubscriptionKey: selectedSeatRow.current[0].key
             
            };
            await axios.post(serviceUrl, subscriptionInfo)
                .then(res => {
                    if (res.status === 200) {
                        //console.log(res.data);
                        setLinkSeatValidationErrMsg("");
                       
                        setOpenSeatLinkDialog(false);
                        var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + _subscriptionID + "&CustomerID=" + props.customerid;
                        setTimeout(() => {
                            window.location.href = redirectUrl;
                        }, 1000);

                    }

                })
                .catch((error) => {
                    //console.error('Error:', error.response.data);
                   // disableYesOption(true);
                    if (error.response.data.message) {
                        setLinkSeatValidationErrMsg(error.response.data.message);
                    }
                    else {
                        setLinkSeatValidationErrMsg(error.response.data);
                    }

                });

        }
       
    }
    const handleUserDeleteDialogClose = () => {
        setOpenUserDeleteDialog(false);

    };
    const handleRegistryDeleteDialogClose = () => {
        setOpenRegistryDeleteDialog(false);

    };
    const handleRegistryEditDialogClose = () => {
        setOpenRegistryEditDialog(false);
    }
    const handleUserEditDialogClose = () => {
        setOpenUserEditDialog(false);
    }
    const handleSeatLinkDialogClose = () => {
        setOpenSeatLinkDialog(false);
    }
    const validatedeleteUser = async (e) => {
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        //Lets check whether this user can be deleted from the subscription. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'validate_deleteUser',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: _key,
            pricingModel: _pricingModel
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);

                }

            })
            .catch((error) => {
                //console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }
            });
        setOpenUserDeleteDialog(true);
    }
    const showEditUserDialog = async (e) => {
        setValidationErrMsg("");
     
        disableYesOption(false);
        setOpenUserEditDialog(true);
    }
    const validatedeleteRegistry = async (e) => {
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        //Lets check whether this user can be deleted from the subscription. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'validate_deleteRegistry',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: _key,
            pricingModel: _pricingModel
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);
                   
                }

            })
            .catch((error) => {
                console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }
                

            });
        setRegistryDialogTitle("Confirm Registry Deletion?");
        setRegistryDialogMsg("Are you sure you want to remove this registry from this subscription? Based on the type of subscription, it might affect the billing amount on the subscription. Please note that the changes are not finalized till you click 'Finalize Changes' from the other screen.");
        setOpenRegistryDeleteDialog(true);

    }
    const validateeditRegistry = async (e) => {
        //user wants to push the registry edit details to the estimate. 
        var _subscriptionID = props.subscriptionid;
        //var _key = itemDetails.itemDetails.key;
        var _pricingModel = props.pricingModel;
        //Lets check whether this user can be deleted from the subscription. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'validate_editRegistry',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: itemDetails.itemDetails.key,
            pricingModel: _pricingModel,
            editedMetaInfo: subscriptionItemDetails.current
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    itemDetails.itemDetails.key = subscriptionItemKey.current;
                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenRegistryDeleteDialog(false);
                    props.reloadsubscription(res.data.revisionInfo);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }
                
                subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                subscriptionItemKey.current = "";
                subscriptionItemOriginalKey.current = "";
                subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                subscriptionItemKey.current = subscriptionItemDetails.current.originalKey;
                subscriptionItemOriginalKey.current = subscriptionItemDetails.current.originalKey;
                setTimeout(() => {
                    subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                    subscriptionItemKey.current = subscriptionItemDetails.current.originalKey;
                    subscriptionItemOriginalKey.current = subscriptionItemDetails.current.originalKey;
                }, 1000);

            });
    }
    const validateeditUser = async (e) => {
        //validate_editUser
        //user wants to push the user edit changes to the estimate. 
        var _subscriptionID = props.subscriptionid;
        //var _key = itemDetails.itemDetails.key;
        var _pricingModel = props.pricingModel;
        //Lets check whether this user can be deleted from the subscription. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'validate_editUser',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: itemDetails.itemDetails.key,
            pricingModel: _pricingModel,
            firstName: subscriptionItemFirstName.current,
            lastName: subscriptionItemLastName.current,
            email: subscriptionItemEmail.current,
            editedMetaInfo: subscriptionItemDetails.current
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    itemDetails.itemDetails.key = subscriptionItemKey.current;
                    itemDetails.itemDetails.firstName = subscriptionItemFirstName.current;
                    itemDetails.itemDetails.lastName = subscriptionItemLastName.current;
                    itemDetails.itemDetails.email = subscriptionItemEmail.current;

                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenUserEditDialog(false);
                    props.reloadsubscription(res.data.revisionInfo);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

                subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                subscriptionItemKey.current = "";
                subscriptionItemOriginalKey.current = "";
                subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                subscriptionItemKey.current = subscriptionItemDetails.current.originalKey;
                subscriptionItemOriginalKey.current = subscriptionItemDetails.current.originalKey;
                setTimeout(() => {
                    subscriptionItemDetails.current.key = subscriptionItemDetails.current.originalKey;
                    subscriptionItemKey.current = subscriptionItemDetails.current.originalKey;
                    subscriptionItemOriginalKey.current = subscriptionItemDetails.current.originalKey;
                }, 1000);

            });

    }
    const deleteUser = async (e) => {

        //Check if user has selected a deletion reason.
        if (subscriptionCancelReason === "") {
            setValidationErrMsg("You must select a reason to remove this user!");
            return false;
        }
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'deleteUser',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: _key,
            pricingModel: _pricingModel,
            editedMetaInfo: subscriptionItemDetails.current
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenUserDeleteDialog(false);
                    props.reloadsubscription(res.data.revisionInfo);
                   
                }

            })
            .catch((error) => {
                //console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });
    }
    const deleteRegistry = async (e) => {
        //Check if user has selected a deletion reason.
        if (subscriptionCancelReason === "") {
            setValidationErrMsg("You must select a reason to remove this registry!");
            return false;
        }
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'deleteRegistry',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: _key,
            pricingModel: _pricingModel,
            editedMetaInfo: subscriptionItemDetails.current
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenRegistryDeleteDialog(false);
                    props.reloadsubscription(res.data.revisionInfo);
                }

            })
            .catch((error) => {
                //console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });
    }
    const reactivateUser = async (e) => {
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'reactivateUser',
            subscriptionID: _subscriptionID,
            key: _key,
            pricingModel: _pricingModel
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenUserDeleteDialog(false);
                    props.reloadsubscription();
                }

            })
            .catch((error) => {
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });
    }
    const validateReactivateRegistry = async (e) => {
        var _subscriptionID = props.subscriptionid;
        var _key = e.currentTarget.dataset.key;
        var _pricingModel = props.pricingModel;
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        const subscriptionInfo = {
            action: 'validateReactivatingRegistry',
            subscriptionID: _subscriptionID,
            subscriptionDetails: subscriptionDetails,
            key: _key,
            pricingModel: _pricingModel,
            editedMetaInfo: subscriptionItemDetails.current
        };
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setValidationErrMsg("");
                    disableYesOption(false);
                    setOpenUserDeleteDialog(false);
                    props.reloadsubscription(res.data.revisionInfo);
                }

            })
            .catch((error) => {
                setRegistryDialogTitle("Registry Reactivation Error");
                setRegistryDialogMsg("An error occurred while trying to re-activate the registry")
                setOpenRegistryDeleteDialog(true);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });
    }
    const showeditRegistryDialog = async (e) => {
       
        setValidationErrMsg("");
        disableYesOption(false);
        subscriptionItemKey.current = "";
        subscriptionItemOriginalKey.current = "";
        setOpenRegistryEditDialog(true);
    }
    const showSeatLinkDialog = async (e) => {
        load_UnlinkedSeats();
        setOpenSeatLinkDialog(true);
        setLinkSeatValidationErrMsg("");
    }
    const load_UnlinkedSeats = async (e) => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        try {
            const serviceUrl = endPointURL + 'api/Subscription/GetUnlinkedSeatsByCustomer';
            let unlinkedSeatsDTO = {
                customerID: props.customerid,
                seatType: itemDetails.itemDetails.dataType
            }
            await axios.post(serviceUrl, unlinkedSeatsDTO)
                .then((response) => {
                    if (response.status === 200) {
                       console.log(response.data.unLinkedSeats);
                        setTableData(response.data.unLinkedSeats); 
                    }


                })
                .catch((error) => {
                    console.log('Error:', error);
                });

        }
        catch (error) {
            console.log('Error:', error);
        }
    }
    const validateRegistryInfo = React.useCallback((e) => {
        setValidationErrMsg("");
        disableYesOption(false);
        subscriptionItemOriginalKey.current = (e.target).dataset.originalkey;
        subscriptionItemKey.current = (e.target).value;
        //console.log(subscriptionItemKey.current);
        subscriptionItemDetails.current.key = (e.target).value;


        //console.log(subscriptionItemDetails.current);
    }, []);
    const validateUserInfo = React.useCallback((e) => {
        var fieldName = (e.target).dataset.fieldname;
        var fieldValue = (e.target).value;
        setValidationErrMsg("");
        disableYesOption(false);
        if (fieldName === 'firstName') {
            subscriptionItemFirstName.current = fieldValue;
            subscriptionItemDetails.current.firstName = fieldValue;
        }
        if (fieldName === 'lastName') {
            subscriptionItemLastName.current = fieldValue;
            subscriptionItemDetails.current.lastName = fieldValue;
        }
        if (fieldName === 'email') {
            subscriptionItemEmail.current = fieldValue;
            subscriptionItemDetails.current.email = fieldValue;
        }
        if (fieldName === 'userName') {
            subscriptionItemOriginalKey.current = (e.target).dataset.originalkey;
            subscriptionItemKey.current = fieldValue;
            //console.log(subscriptionItemKey.current);
            subscriptionItemDetails.current.key = (e.target).value;
        }
    
    }, [])
    const validateAddonChange = React.useCallback((e) => {
        let isChecked = e.target.checked;
        let addonID = e.target.dataset.addonid;
        let originalKey = e.target.dataset.originalkey;
        //console.log(subscriptionItemDetails.current);
        let addOnExists = false;
        if (isChecked) {
            //see if this exists in the addOns list. Otherwise add it.
            for (var i = 0; i < subscriptionItemDetails.current.addOns.length; i++) {
                if (subscriptionItemDetails.current.addOns[i].id === addonID) {
                    addOnExists = true;
                }
            }
            if (!addOnExists) {
                subscriptionItemDetails.current.addOns.push({ id: addonID, name: addonID });
            }
        }
        else {
            //Lets remove it from the list. 
            for (var i = 0; i < subscriptionItemDetails.current.addOns.length; i++) {
                if (subscriptionItemDetails.current.addOns[i].id === addonID) {
                    subscriptionItemDetails.current.addOns.splice(i, 1);
                    break;
                }
            }
        }


    }, []);
    const LoadEditRegistryDialog = () => {
        const registrymode = {
            mode: "edit",
            itemDetails: itemDetails.itemDetails,
            subscriptionDetails: props.subscriptionDetails,
            onvalidate: validateRegistryInfo,
            onvalidateaddonchange: validateAddonChange
        }
        return (
            <>
                <Dialog
                    open={openRegistryEditDialog}
                    onClose={handleRegistryEditDialogClose}
                >
                    <DialogTitle id="Edit Registry Details">
                        {editregistryDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        {registryEditDialogMsg}
                        <RegistryForm {...registrymode} />
                        {validationErrMsg ? <Alert severity="error">{validationErrMsg}</Alert> : ""}
                    </DialogContent>
                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                    <DialogActions>
                        <Button autoFocus={true} onClick={handleRegistryEditDialogClose} variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={validateeditRegistry} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                            Submit
                        </Button>
                        </DialogActions>
                    </PermissionsGate>
                    {props.isTrial ? <>
                        <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} showMessage="false" missingscopes={[SCOPES.canManageSubscriptions]}>
                            <DialogActions>
                                <Button autoFocus={true} onClick={handleRegistryEditDialogClose} variant="contained">
                                    Cancel
                                </Button>
                                <Button onClick={validateeditRegistry} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                                    Submit
                                </Button>
                            </DialogActions>
                        </PermissionsGate>
                    </>
                        : null}
                    
                </Dialog>
            </>
        );
    }
    const LoadEditUserDialog = () => {
        const editmode = {
            mode: "edit",
            itemDetails: itemDetails.itemDetails,
            subscriptionDetails: props.subscriptionDetails,
            onvalidate: validateUserInfo,
            onvalidateaddonchange: validateAddonChange
        }
        return (
            <>
                <Dialog
                    open={openUserEditDialog}
                    onClose={handleUserEditDialogClose}
                >
                    <DialogTitle id="Edit User Details">
                        {edituserDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        {userEditDialogMsg}
                        <UserForm {...editmode} />
                        {validationErrMsg ? <Alert severity="error">{validationErrMsg}</Alert> : ""}
                    </DialogContent>
                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                        <DialogActions>
                            <Button autoFocus={true} onClick={handleUserEditDialogClose} variant="contained">
                                Cancel
                            </Button>
                            <Button onClick={validateeditUser} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                                Submit
                            </Button>
                            </DialogActions>
                    </PermissionsGate>
                    {props.isTrial ? <>
                        <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} showMessage="false" missingscopes={[SCOPES.canManageSubscriptions]}>
                            <DialogActions>
                                <Button autoFocus={true} onClick={handleUserEditDialogClose} variant="contained">
                                    Cancel
                                </Button>
                                <Button onClick={validateeditUser} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                                    Submit
                                </Button>
                            </DialogActions>
                        </PermissionsGate>
                    </>
                        :
                        null}
                        
                       
                   
                </Dialog>
            </>
        );
    }
 
    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(10)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );
    const LoadLinkSeatDialog = () => {
        const [selectionModel, setSelectionModel] = React.useState([]);
        return (
            <>
                <Dialog
                    open={openSeatLinkDialog}
                    onClose={handleSeatLinkDialogClose}
                >
                    <DialogTitle id="Link Seat Details">
                        {linkSeatDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        {linkSeatDialogMsg}
                        <div style={{ display: 'flex', height: '250px' }}>
                            <DataGrid
                                rows={tableData}
                                rowHeight={30}
                                columns={unlinkedSeatsColumns}
                                pageSize={10}
                                components={{
                                    LoadingOverlay: LoadingSkeleton
                                }}
                                selectionModel={selectionModel}
                                loading={props.loading}
                                rowsPerPageOptions={[10]}
                                checkboxSelection={true}
                                onSelectionModelChange={(selection) => {
                                    const selectionSet = new Set(selectionModel);
                                    selectedSeatRow.current = [];
                                    if (selection.length > 1) {
                                        const result = selection.filter((s) => !selectionSet.has(s));
                                        setSelectionModel(result);
                                        const selectedRowData = tableData.filter((row) =>
                                            row.id === selection[1]
                                        );
                                        if (selectedRowData) {
                                            selectedSeatRow.current = selectedRowData;
                                        };

                                    } else {
                                        setSelectionModel(selection);
                                        const selectedRowData = tableData.filter((row) =>
                                            row.id === selection[0]
                                        );
                                        if (selectedRowData) {
                                            selectedSeatRow.current  = selectedRowData;
                                        };
                                    }
                                    //console.log(selectionSet);
                                    //console.log(selection);
                                   
                                   // console.log(selectedSeatRow.current);
                                }}
                            />
                        </div>
                        {validationLinkSeatErrMsg ? <Alert severity="error">{validationLinkSeatErrMsg}</Alert> : ""}
                    </DialogContent>
                  
                        <DialogActions>
                        <Button autoFocus={true} variant="contained" onClick={handleSeatLinkDialogClose }>
                                Cancel
                            </Button>
                        <Button autoFocus variant="contained" onClick={handleSaveSeatLink} data-key={subscriptionItemKey.current}>
                                Submit
                            </Button>
                        </DialogActions>
         
                </Dialog>
            </>
            )
    }
    if (itemDetails.itemDetails.dataType === "REGISTRY") {
        return (
            <>
                <Dialog
                    open={openRegistryDeleteDialog}
                    onClose={handleRegistryDeleteDialogClose}
                >
                    <DialogTitle id="Confirm Registry Deletion">
                        {registryDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {registryDialogMsg}

                            <br />Please select from the list of reasons for this removal.
                        </DialogContentText>
                        <FormControl required sx={{ m: 1, width: 430 }}>

                            <InputLabel>Removal Reason</InputLabel>
                            <Select
                                value={subscriptionCancelReason}
                                onChange={handleCancellationReasonChange}
                            >
                                {lstCancelReasons.current && lstCancelReasons.current.map((item, index) => {

                                    return (
                                        <MenuItem value={item} key={index}>{item}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {validationErrMsg ? <Alert severity="error" >{validationErrMsg}</Alert> : ""}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus={true} onClick={handleRegistryDeleteDialogClose} variant="contained">
                            No
                        </Button>
                        <Button onClick={deleteRegistry} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoadEditRegistryDialog />
                <Card style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', backgroundColor: (itemDetails.itemDetails.isNewItem ? "#EBF5FB" : "#fff") }}>

                    <CardMedia>

                        <Avatar sx={{ width: 100, height: 100, margin: "auto", bgcolor: (itemDetails.itemDetails.endDate ? "#D7D7DA" : "#3333cc") }} >
                            <ConnectingAirportsIcon sx={{ width: 80, height: 80 }} />

                        </Avatar>
                    </CardMedia>
                    <CardContent>
                        <Container >
                            <Row>
                                <Col xs={12} md={12}>
                                    <center>
                                        <b>{itemDetails.itemDetails.key}</b>



                                    </center>
                                </Col>
                            </Row>
                            {itemDetails.itemDetails.cbCampaignID != null ?
                                <>
                                    <Row>
                                        <Col xs={12} md={12}>

                                            <Button variant="outlined" sx={{ m: '5px' }} disabled><CampaignIcon sx={{ width: 20, height: 20 }} ></CampaignIcon>{itemDetails.itemDetails.cbCampaignID}</Button>
                                        </Col>
                                    </Row>
                                </> : null}
                            {itemDetails.itemDetails.relatedCBSubscriptionID != null ?

                                <>
                                    <Row>
                                        <Col>
                                            {itemDetails.itemDetails.relatedSubscriptionIDIsTrial === true ?
                                                <>
                                                    <Button variant="outlined" sx={{ m: '5px' }} disabled><ConstructionIcon sx={{ width: 20, height: 20 }} ></ConstructionIcon>{itemDetails.itemDetails.relatedCBSubscriptionID}</Button>
                                                </> :
                                                <>
                                                    <Button variant="outlined" sx={{ m: '5px' }} disabled><MergeIcon sx={{ width: 20, height: 20 }} ></MergeIcon>{itemDetails.itemDetails.relatedCBSubscriptionID}</Button>
                                                </>}
                                        </Col>
                                    </Row>
                                </> : null}
                           
                            <Row>
                                <Col xs={12} md={12}>
                                    <center>
                                        {itemDetails.itemDetails.addOns && itemDetails.itemDetails.addOns.sort((p1, p2) => p1.id > p2.id ? 1 : -1).map((item, index) => {

                                            if (item.id === "RWA-WB-102173")
                                                return (<React.Fragment key={item.id}><RWAWBBadge /></React.Fragment>)
                                            else if (item.id === "RW-ANALYSIS-102172")
                                                return (<React.Fragment key={item.id}><RWABadge /></React.Fragment>)
                                            else if (item.id === "APG-TEXTRON-102394")
                                                return (<React.Fragment key={item.id}><AGPTextronBadge /></React.Fragment>)
                                            else return (<React.Fragment key={item.id}><UnknownBadge name={item.id} /></React.Fragment>)
                                        })}
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="registryName"
                                        label="Start Date"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard"
                                        defaultValue={commonfunctions.formatToDate(itemDetails.itemDetails.startDate)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="endDate"
                                        label="End Date"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard"
                                        defaultValue={commonfunctions.formatToDate(itemDetails.itemDetails.endDate)}
                                    />
                                </Col>
                            </Row>
                            {itemDetails.itemDetails.cancelReason != null ?
                                <>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <TextField style={{ width: '100%' }}
                                                name="cancelReason"
                                                label="Cancel Reason"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }} variant="standard"
                                                defaultValue={itemDetails.itemDetails.cancelReason}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "normal",
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '2',
                                                        WebkitBoxOrient: 'vertical',
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <></>}
                           
                        </Container>
                    </CardContent>
                    <CardActions disableSpacing>

                        {itemDetails.itemDetails.endDate ?
                            (
                                (subscriptionStatus) && (subscriptionStatus == 'Future') ? (<Tooltip title="Restart Registry">
                                    <IconButton aria-label="Restart Registry" color="success" component="label" onClick={validateReactivateRegistry} data-key={itemDetails.itemDetails.key} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Tooltip>) : (null)

                            ) :
                            (
                                <>
                                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                                        <Tooltip title="Edit Registry">
                                    <IconButton aria-label="Edit Registry" color="primary" component="label" onClick={showeditRegistryDialog} data-key={itemDetails.itemDetails.key} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                    <Tooltip title="Delete Registry">
                                        <IconButton aria-label="Delete Registry" color="error" component="label" onClick={validatedeleteRegistry} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                        </Tooltip>
                                    </PermissionsGate>
                                    {props.isTrial ? <>
                                        <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} showMessage="false" missingscopes={[SCOPES.canManageSubscriptions]}>
                                            <Tooltip title="Edit Registry">
                                                <IconButton aria-label="Edit Registry" color="primary" component="label" onClick={showeditRegistryDialog} data-key={itemDetails.itemDetails.key} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Registry">
                                                <IconButton aria-label="Delete Registry" color="error" component="label" onClick={validatedeleteRegistry} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </PermissionsGate>

                                    </>
                                        : null}
                                    
                                </>)}
                    </CardActions>
                </Card>
            </>
        );
    }
    if (itemDetails.itemDetails.dataType === "USER") {
        
        return (
            <>  <Dialog
                open={openUserDeleteDialog}
                onClose={handleUserDeleteDialogClose}
            >
                <DialogTitle id="Confirm User Deletion">
                    {"Confirm User Removal?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this user from this subscription? Based on the type of subscription, it might affect the billing amount on the subscription.
                        <br />Please select from the list of reasons for this removal.
                    </DialogContentText>
                    <FormControl required sx={{ m: 1, width: 430 }}>
                        
                        <InputLabel>Removal Reason</InputLabel>
                    <Select
                            value={subscriptionCancelReason}
                            onChange={handleCancellationReasonChange}
                        >
                            {lstCancelReasons.current && lstCancelReasons.current.map((item, index) => {
                            
                            return (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                            );
                        })}
                        </Select>
                    </FormControl>
                    {validationErrMsg ? <Alert severity="error" >{validationErrMsg}</Alert> : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={handleUserDeleteDialogClose} variant="contained">
                        No
                    </Button>
                    <Button onClick={deleteUser} autoFocus variant="contained" disabled={yesoptionDisabled} data-key={itemDetails.itemDetails.key} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
                <LoadEditUserDialog />
                <LoadLinkSeatDialog />
                <Card style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', backgroundColor: (itemDetails.itemDetails.isNewItem ? "#EBF5FB" : "#fff") }} >
                    <CardMedia>

                        <Avatar sx={{ width: 100, height: 100, margin: "auto", bgcolor: (itemDetails.itemDetails.endDate ? "#D7D7DA" : "#3333cc") }}>
                            <PersonIcon sx={{ width: 80, height: 80 }} label={itemDetails.itemDetails.key} />
                        </Avatar>
                    </CardMedia>
                    <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                        <Container  >
                            <Row>
                                <Col xs={12} md={12}><center><b>{itemDetails.itemDetails.key}</b>
                                    {commonfunctions.checkSubscriptionStatus(subscriptionStatus) == false && !itemDetails.itemDetails.swappedSeatKey ?
                                    <>
                                            <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                                                <Tooltip title="Link to Existing Seat"><IconButton aria-label="Link to Existing Seat" color="success" component="label" onClick={showSeatLinkDialog}>
                                                    <MergeTypeIcon sx={{ color: "#3BE17A" }}></MergeTypeIcon>
                                                </IconButton>
                                                </Tooltip>
                                            </PermissionsGate>
                                    </> : <></>}
                                    
                                </center>
                                </Col>
                            </Row>
                            {itemDetails.itemDetails.cbCampaignID != null ?
                                <>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <center><Button variant="outlined" sx={{ m: '5px' }} disabled><CampaignIcon sx={{ width: 20, height: 20 }} ></CampaignIcon>{itemDetails.itemDetails.cbCampaignID}</Button></center>


                                        </Col>
                                    </Row>
                                </> : null}
                            {itemDetails.itemDetails.relatedCBSubscriptionID != null ?

                                <>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            {itemDetails.itemDetails.relatedSubscriptionIDIsTrial === true ?
                                                
                                                <>
                                                    
                                                    <Button variant="outlined" sx={{ m: '5px' }} disabled><ConstructionIcon sx={{ width: 20, height: 20 }} ></ConstructionIcon>{itemDetails.itemDetails.relatedCBSubscriptionID}</Button>

                                                </> :
                                                <>
                                                    
                                                    <Button variant="outlined" sx={{ m: '5px' }} disabled><MergeIcon sx={{ width: 20, height: 20 }} ></MergeIcon>{itemDetails.itemDetails.relatedCBSubscriptionID}</Button>

                                                </>}
                                        </Col>
                                    </Row>
                                </> : null}
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="fullName"
                                        label="Name"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard" 
                                        defaultValue={commonfunctions.formatFullName(itemDetails.itemDetails.firstName, itemDetails.itemDetails.lastName)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="email"
                                        label="Email"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard" 
                                        defaultValue={itemDetails.itemDetails.email}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="registryName"
                                        label="Start Date"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard" 
                                        defaultValue={commonfunctions.formatToDate(itemDetails.itemDetails.startDate)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <TextField style={{ width: '100%' }}
                                        name="registryName"
                                        label="End Date"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }} variant="standard" 
                                        defaultValue={commonfunctions.formatToDate(itemDetails.itemDetails.endDate)}
                                    />
                                </Col>
                            </Row>
                            {itemDetails.itemDetails.cancelReason != null ? 
                                <>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <TextField style={{ width: '100%' }}
                                                name="cancelReason"
                                                label="Cancel Reason"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }} variant="standard"
                                                defaultValue={itemDetails.itemDetails.cancelReason}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <></>}
                            {itemDetails.itemDetails.swappedSeatKey != null ?
                                <>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <TextField style={{ width: '100%' }}
                                                name="cancelReason"
                                                label="Swapped Seat"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }} variant="standard"
                                                defaultValue={itemDetails.itemDetails.swappedSeatKey}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <></>}
                            
                        </Container>
                        <CardActions disableSpacing>
                           
                            {itemDetails.itemDetails.endDate ? (
                                (subscriptionStatus) && (subscriptionStatus == 'Future') ? (<Tooltip title="Reactivate User">
                                    <IconButton aria-label="Reactivate User" color="success" component="label" onClick={reactivateUser} data-key={itemDetails.itemDetails.key} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Tooltip>) : (null)

                            ) :
                                (
                                    <>

                                        <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                                            <Tooltip title="Edit User Info">
                                            <IconButton aria-label="Edit User" color="primary" component="label" onClick={showEditUserDialog} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                                <EditIcon />
                                            </IconButton>
                                            </Tooltip>
                                        <Tooltip title="Delete User">
                                            <IconButton aria-label="Delete User" color="error" component="label" onClick={validatedeleteUser} data-key={itemDetails.itemDetails.key} disabled={commonfunctions.checkSubscriptionStatus(subscriptionStatus)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                            </Tooltip></PermissionsGate>

                                        {props.isTrial ? <>

                                            <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} showMessage="false" missingscopes={[SCOPES.canManageSubscriptions]}>
                                                <Tooltip title="Edit User Info">
                                                    <IconButton aria-label="Edit User" color="primary" component="label" onClick={showEditUserDialog} disabled={(commonfunctions.checkSubscriptionStatus(subscriptionStatus))}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete User">
                                                    <IconButton aria-label="Delete User" color="error" component="label" onClick={validatedeleteUser} data-key={itemDetails.itemDetails.key} disabled={commonfunctions.checkSubscriptionStatus(subscriptionStatus)}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Tooltip></PermissionsGate>
                                        </> : null}
                                    </> )}
                        </CardActions>
                    </CardContent>
                </Card>
            </>
        );
    }

  
    return (
        <Card style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', backgroundColor: (itemDetails.itemDetails.isNewItem ? "#EBF5FB" : "#fff") }}>
            <CardHeader>

            </CardHeader>
            <CardContent >
                <Avatar sx={{ width: 70, height: 70, margin: "auto" }} label={itemDetails.itemDetails.key}>
                    <HelpOutlineIcon sx={{ width: 60, height: 60 }} />
                </Avatar>
                <center><b>{itemDetails.itemDetails.key}</b></center>
                <Container>
                    <Row>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>Name:
                        </Col>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>{commonfunctions.formatFullName(itemDetails.itemDetails.firstName, itemDetails.itemDetails.lastName)}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>Start Date:</Col>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>{commonfunctions.formatToDate(itemDetails.itemDetails.startDate)}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>End Date:</Col>
                        <Col style={{ whiteSpace: "nowrap" }} xs={12} md={6}>{commonfunctions.formatToDate(itemDetails.itemDetails.endDate)}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    );

}
