import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import PermissionsGate from '../security/PermissionGate';
import { SCOPES } from '../security/permission-maps';   
import { GetShoppingCartIcon } from './GetShoppingCartIcon';
import './NavMenu.css';

export default function NavMenu(props) {
  //static displayName = NavMenu.name;
   const [collapsed, setCollapsed] = useState(true);
  const [cartDetails, setCartDetails] = useState(props.cartContents);
  const [userInfo, setUserInfo] = useState();

  const { authState, oktaAuth } = useOktaAuth();
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
          setUserInfo(info);
          //Lets make sure the user exists in db since we've successfully authenticated
          //var endPointURL = process.env.REACT_APP_API_URL;
          //if (process.env.REACT_APP_API_URL === undefined) {
          //    endPointURL = "/";
          //}

          //try {
          //    const serviceUrl = endPointURL + 'api/User/GetCurrentUser';
          //    axios.get(serviceUrl)
          //        .then(response => {

          //        })
          //        .catch((error) => {
          //            console.log('Error:', error);
          //        });
          //}
          //catch (error) {
          //    console.log('Error:', error);
          //}
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

    const logout = () => {
        //console.log(userInfo);
       
      
        window.location.href = '/logoff';
  };
  


  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
              <Container>
                  <Row>
                      <Col className="col-md-2">
                          <NavbarBrand tag={Link} to="/"><img src="https://www.universalweather.com/wp-content/uploads/2021/11/universal-weather-and-aviation.svg" height="75em" width="175em" /></NavbarBrand>

                      </Col>
                   
                      <Col className="col-md-10 text-end">
                          <GetShoppingCartIcon cartContents={props.cartContents} shoppingCartPageLink='' />
                           <ul className="navbar-nav UWA_navbar-name">
                              {
                                  !authState?.isAuthenticated &&
                                  <NavItem>
                                      <NavLink tag={Link} className="text-dark" to="/login-okta">UWA Login</NavLink>
                                  </NavItem>
                              }
                              {authState?.isAuthenticated &&
                                  <>
                                      <UncontrolledDropdown nav inNavbar>
                                          <DropdownToggle nav caret>
                                              {userInfo?.name}
                                          </DropdownToggle>
                                          <DropdownMenu end>
                                              <DropdownItem onClick={() => logout()}>
                                                  Logout
                                              </DropdownItem>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                                  </>
                              }
                          </ul>
                          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                      </Col>
                  </Row>

                  <Row>
                      <Col className="text-start">
                          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                              <ul className="navbar-nav flex-grow">
                                  <NavItem>
                                      <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                  </NavItem>
                                 
                                  {
                                      authState?.isAuthenticated &&
                                      <>
                                          <NavItem>
                                              <NavLink tag={Link} className="text-dark" to="/MyDashboard">My Dashboard</NavLink>
                                          </NavItem>
                                          <PermissionsGate
                                              scopes={[SCOPES.canNavigateSubscriptions, SCOPES.canNavigateTrialSubscriptions, SCOPES.canManageUsers]}
                                              showMessage="false">
                                          <UncontrolledDropdown nav inNavbar>
                                              <DropdownToggle nav caret>
                                                  Admin
                                              </DropdownToggle>
                                                  <DropdownMenu end>
                                                      <PermissionsGate showMessage="false" scopes={[SCOPES.canManageFIQCarts]}>
                                                          <DropdownItem href="/Admin/ManageFIQCarts">
                                                              Manage FIQ Carts
                                                          </DropdownItem>
                                                      </PermissionsGate>
                                                      <PermissionsGate showMessage="false" scopes={[SCOPES.canNavigateTrialSubscriptions]} missingscopes={[SCOPES.canNavigateSubscriptions] }>
                                                                  <DropdownItem href="/Admin/ManageSubscriptions">
                                                                    Manage Trial Subscriptions
                                                                  </DropdownItem>
                                                              </PermissionsGate>
                                                              <PermissionsGate showMessage="false" scopes={[SCOPES.canNavigateSubscriptions]}>
                                                                  <DropdownItem href="/Admin/ManageSubscriptions">
                                                                      Manage Subscriptions
                                                                      </DropdownItem>
                                                                  </PermissionsGate>
                                                      
                                                      <DropdownItem divider />
                                                      <PermissionsGate showMessage="false" scopes={[SCOPES.canManageEventLogs]}>
                                                          <DropdownItem href="/Admin/EventLogs">
                                                              View Event Logs
                                                          </DropdownItem>
                                                      </PermissionsGate>
                                                      <PermissionsGate showMessage="false" scopes={[SCOPES.canManageUsers]}>
                                                  <DropdownItem href="/Admin/UserManagement">
                                                      Manage Users
                                                      </DropdownItem>
                                                      </PermissionsGate>

                                                      <PermissionsGate showMessage="false" scopes={[SCOPES.canManageIntegrationMaster]}>
                                                          <DropdownItem href="/Admin/IntegrationMaster">
                                                              Manage Integration Records
                                                          </DropdownItem>
                                                      </PermissionsGate>

                                                    
                                                 
                                              </DropdownMenu>
                                          </UncontrolledDropdown>
                                          </PermissionsGate>
                                         
                                      </>
                                  }
                                  <UncontrolledDropdown nav inNavbar>
                                      <DropdownToggle nav caret>
                                          Widgets
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                          <DropdownItem href="/GetSingleProduct">
                                              Single Product
                                          </DropdownItem>
                                          <DropdownItem href="/GetAllProduct">
                                              All Products
                                          </DropdownItem>
                                          <DropdownItem divider />
                                          <DropdownItem href="/GetShoppingCart">
                                              Shopping Cart
                                          </DropdownItem>
                                          <DropdownItem href="/CheckoutWizard">
                                              Checkout Wizard
                                          </DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>

                              </ul>
                          </Collapse>
                      </Col>
                  </Row>
          
        
         

        </Container>
      </Navbar>
    </header>
  );
}
