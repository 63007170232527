import React from 'react';
import { Chip, Tooltip } from '@mui/material';
export default function RWABadge(props) {
  
    return (
        <Tooltip title="Runway Analysis Addon">
            <Chip label={'RWA'} className="UWA_AddOnChip"
            />
        </Tooltip>
    );
}