import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button} from '@mui/material';
import axios from 'axios';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   

export default function ManageFIQCarts(props) {
    //console.log("hello");
    
    const renderEditButton = (params) => {
        return (
            <PermissionsGate scopes={[SCOPES.canManageFIQCarts]} showMessage="false" >
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        window.location.href = '/Admin/ManageFIQCart?CartID=' + params.row.cartID;
                    }}
                >
                    Manage
                </Button>
                </strong>
            </PermissionsGate>
        )
    };
    const RedirectToCreateFIQPage = () => {
        window.location.href = '/Admin/CreateFIQCart';
    };
    function getFormattedCurrencyValue(params) {
        return commonfunctions.formatToCurrency(params.row.cartTotal);
    }
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = React.useState([]);
    //const cartColumns: GridColDef[] = [
    const cartColumns = [


        {
            field: 'cartid', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },
        { field: 'status', headerAlign: 'center', align: 'center', headerName: 'Status', width: 110 },
        {
            field: 'companyName',
            headerName: 'Company Name',
            width: 300,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },
        {
            field: 'oracleID',
            headerName: 'Customer ID',
            width: 100,
            headerAlign: 'center',
            editable: false,
            align: 'center'
        },
        {
            field: 'createDate',
            headerName: 'Creation Date',
            type: 'date',
            width: 120,
            headerAlign: 'right',
            editable: false,
            align: 'right'
        },

        {
            field: 'expirationDate',
            headerName: 'Expiration Date',
            type: 'date',
            align: 'right',
            headerAlign: 'right',
            width: 120,
        },
        {
            field: 'checkoutCompletionDate',
            headerName: 'Completion Date',
            type: 'date',
            width: 130,
            headerAlign: 'right',
            editable: false,
            align: 'right'
        },
        {
            field: 'cartTotal',
            headerName: 'Cart Total',
            type: 'number',
            width: 120,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: getFormattedCurrencyValue,

        },
        {
            field: 'accountManager',
            headerName: 'Account Manager',
            width: 160,
            headerAlign: 'left',
            editable: false,
            align: 'left'

        },
    ];
  
    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(4)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index}/>
            ))}
        </Box>
    );
   
    const loadShoppingCarts = async () => {
      
       

        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ShoppingCart/GetFIQDashboardCarts';

        //console.log(serviceUrl);
        await axios.get(serviceUrl)
        .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setTableData(res.data);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log('Error:', error);


            });
      

    };
    useEffect(() => {
        document.title = "Manage FIQ Carts";
        loadShoppingCarts();
       
    }, []);
    return (
     
                <Container>
                    <Row>
                        <Col>
                            <h1>Manage FIQ Carts</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           
                        </Col>
                <Col align="right">
                            <PermissionsGate scopes={[SCOPES.canManageFIQCarts]} showMessage="false" >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="UWA_btn_Submit"
                                    style={{ marginLeft: 16 }}
                                    onClick={RedirectToCreateFIQPage}
                                >  Create New FIQ Cart
                                </Button>
                            </PermissionsGate>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                   
                            <hr />
                            <div style={{ height: 350, width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                      
                                        <DataGrid
                                    rows={tableData}
                                    rowHeight={39}
                                    columns={cartColumns}
                                    pageSize={10}
                                    getRowId={row => Math.random()}
                                    components={{
                                        LoadingOverlay: LoadingSkeleton
                                    }}
                                    loading={loading}
                                    rowsPerPageOptions={[10, 15, 20]}
                                    disableColumnSelector={true} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container >
       
      
    );
}


