import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { Card, Grid, CardHeader, Alert, IconButton, Skeleton, Tooltip } from '@mui/material';
import SubscriptionInfoTile from './SubscriptionInfoTile.js';
import SubscriptionDetails from './SubscriptionDetails.js';
import CreateNewSubscription from './CreateNewSubscription.js';
import { ModalWait } from '../WaitModal'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   

export default function ManageSubscription(props) {

    const [isloading, setLoading] = useState(null);
    const [currentSubscriptionID, setCurrentSubscriptionID] = useState(new URLSearchParams(useLocation().search).get('SubscriptionID'));
    const [currentCustomerID, setCurrentCustomerID] = useState(new URLSearchParams(useLocation().search).get('CustomerID'));
    const [subscriptionInfo, setSubscriptionInfo] = useState([]);
    const [custInfo, setCustInfo] = useState([]);
    const [currentsub, setCurrentSub] = useState([]);
    const [openAddNewSubscriptionDialog, setOpenAddNewSubscriptionDialog] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const [showWaitModal, toggleState] = useState(false);
    const shouldLoadCustomerDetails = useRef(true);
    const reload_CurrentSubscription = React.useCallback(() => {
        load_CustomerSubscriptions();
    }, []);
    const toggleWaitModal = (val) => {
        toggleState(val);
    }
    const load_CustomerSubscriptions = async () => {
        toggleWaitModal(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        //console.log("INSIDE load process" + currentSubscriptionID);
        //console.log(currentSubscriptionID);
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetCustomerSubscriptions?subscriptionID=' + currentSubscriptionID + "&customerID=" + currentCustomerID;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setSubscriptionInfo(res.data.subscriptionInfo);
                    setCustInfo([res.data.customer]);
                    setLoading(false);
                    res.data.subscriptionInfo.map((item) => {
                        if (item.subscription.id === currentSubscriptionID) {
                            setCurrentSub([item.subscription]);
                        }
                    })
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrMsg(error.response.data.message);
                }
                else {
                    setErrMsg(error.response.data);
                }

            });
        toggleWaitModal(false);
    };

    useEffect(() => {
        if (shouldLoadCustomerDetails.current) {
            setLoading(true);
            shouldLoadCustomerDetails.current = false;
        }
        document.title = "Manage Subscriptions";

        load_CustomerSubscriptions();


    }, [currentSubscriptionID]);
    const switchSubscription = async (e) => {
        //let subID = e.currentTarget.dataset.item;
        //let custID = e.currentTarget.dataset.customer;
        //console.log(e.currentTarget.dataset.item);
        //shouldLoadCustomerDetails.current = true;
        setCurrentSubscriptionID(e.currentTarget.dataset.item);
        setCurrentCustomerID(e.currentTarget.dataset.customer);
        //setSubscriptionInfo([]);
        setCurrentSub([]);
        //load_CustomerSubscriptions();
        // window.location.href = 'Admin/ManageSubscription?SubscriptionID=' + subID + "&CustomerID=" + custID;

    }
    const LoadMissingInformationPane = () => {
        return (
            <>
                <Row>
                    <Col xs={12} md={12}>
                        <Alert severity="error" >Unable to locate any Subscription Information</Alert>


                    </Col>
                </Row>
            </>
        )
    }
    const handleCreateNewSubscription = () => {
        setOpenAddNewSubscriptionDialog(true);
    }
    const handleAddNewSubsciptionDialogClose = () => {
        setOpenAddNewSubscriptionDialog(false);

    }
    const Load_NewSubscriptionDialog = () => {
        return (
            <>
                <Dialog
                    open={openAddNewSubscriptionDialog}
                    onClose={handleAddNewSubsciptionDialogClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="Create New Subscription Wizard">Create New Subscription
                    </DialogTitle>
                    <DialogContent><CreateNewSubscription currentCustomerID={currentCustomerID} /></DialogContent>
                </Dialog>
            </>
        )
    }
    const LoadSubscriptionDetails = (subscriptionProps) => {
        //console.log(subscriptionProps);
        return (
            <>
                <Grid item xs={12} md={12}>

                        {subscriptionProps.subscriptionDetails && subscriptionProps.subscriptionDetails.map((item, index) => {
                            let subdetails = {
                                custInfo: custInfo,
                                loading: isloading,
                                subscripdetails: item
                            };
                            let reload = {
                                reloadsubscription: reload_CurrentSubscription
                            }
                            //console.log(index);
                            return (<React.Fragment key={item.id}><SubscriptionDetails {...subdetails} {...reload} /></React.Fragment>);
                        })}

                    </Grid>
            </>
        );
    }
    if (isloading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width="100%" height={360} />
            </div>
        );
    };
    return (
        <Grid container>
            <Load_NewSubscriptionDialog />
            <Grid item xs={12} md={12}>
                    <h2>Manage Customer Subscriptions</h2>
            </Grid>
            {subscriptionInfo.length > 0 ? ("") :
                (<LoadMissingInformationPane/>
                )
            }
            <Grid item lg={4} sm={12} xl={4} md={4} xs={12} style={{ paddingRight: "5px" }} className="gx-0">
                {subscriptionInfo.length > 0 ? (
                    <>
                        <Card square={true} >
                            <CardHeader title="All Subscriptions" className="UWA_SubscriptionListTitle"
                                action={
                                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                                    <Tooltip title="Add New Subscription">
                                        <IconButton aria-label="settings" sx={{ bgcolor: '#fff', width: 34, height: 34, padding: '10px', marginRight: '4px', marginTop: '2px' }} onClick={(event) => handleCreateNewSubscription()} >
                                            <ControlPointIcon />
                                    </IconButton>
                                    </Tooltip>
                                    </PermissionsGate>
                            }
                            />
                                
                           
                        </Card>
                    </>
                ): ("") }
                   


                {subscriptionInfo && subscriptionInfo.map((item, index) => {
                    let subInfo = {
                        loading: isloading,
                        subscripinfo: item.subscription
                    };

                    if (item.subscription.id === currentSubscriptionID) {
                        return (<Row key={item.subscription.id} >
                            <Col>
                                <SubscriptionInfoTile {...subInfo} className="UWA_SubscriptionActiveTile" onClick={switchSubscription} data-item={item.subscription.id} data-customer={item.subscription.customer_id} />
                            </Col>
                        </Row>
                        )
                    }
                    return (
                        <Row key={item.subscription.id}>
                            <Col>
                                <SubscriptionInfoTile {...subInfo} className="UWA_SubscriptionTile" onClick={switchSubscription} data-item={item.subscription.id} data-customer={item.subscription.customer_id} />
                            </Col>
                        </Row>

                    );
                })}

            </Grid>
            <Grid item lg={8} sm={12} xl={8} xs={12} md={8}>
                {showWaitModal ? (
                    <ModalWait
                        handleResponse={showWaitModal}
                    />
                ) : null}
                <Grid>
                       
                    <LoadSubscriptionDetails  subscriptionDetails={currentsub}/>
                </Grid>


            </Grid>
            <Grid item lg={12} sm={12}>
                {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
            </Grid>
            </Grid>

     
    );
}