import axios from 'axios';

export const commonService = {
    logEvent: async function (logTypeKey, description, extraInfo) {
        const endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            logTypekey: logTypeKey,
            description: description, 
            extraInfo: extraInfo
        };
        try {
            const serviceUrl = endPointURL + 'api/EventLog/AddLogEvent';
            return axios.post(serviceUrl, body);

        }
        catch (error) {
            return error;
        }
    }
}
