import React, { Component } from 'react';

export class Home extends Component {
  //static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Chargebee React Widgets</h1>
     
        <p>The widgets included with this application can be used as standalone widgets on any website page. The routes/links provided via this page are to display data from the widgets. Adding widgets to specific pages will be done via the uwa_widgets div. Additional ones will be added to the list as we progress through MVP stages:</p>
        <ul>
                <li><strong>GetSingleProduct</strong>. This takes a parameter of a specific Charge ID from Chargebee Product Catalog - as an example you can test using <code>'GetProduct?ProductID=XXXXXX'</code> </li>
                 <li><strong>GetAllProducts</strong>. Used to return a list of all available Charge ID's from Chargebee Product Catalog</li>
                <li><strong>GetSubscription</strong>. Will be used to get a specific subscription based product from Chargebee</li>
                <li><strong>GetSubscriptionList</strong>. Will be used to get all active Subscription products from Chargebee</li>
                <li><strong>GetShoppingCart</strong>. Will be used to display a shopping cart with your selected items</li>
        </ul>
       
      </div>
    );
  }
}
