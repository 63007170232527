const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '0oa4oca2csmwT34Bi1d7';
const ISSUER = process.env.REACT_APP_ISSUER || 'https://id.universalweather.net/oauth2/aus4vujdvdDUErBvH1d7';
const OKTA_TESTING_DISABLEHTTPCHECK = process.env.OKTA_TESTING_DISABLEHTTPCHECK || false;
const REDIRECT_URI = `${window.location.origin}` + '/login/callback';

const oidc = {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPCHECK,
    pkce: true
}

export default {
    // Add common config values here
    oidc
};