export const ROLES = {
    admin: "Admin",
    jwtToken: "JWT_TOKEN",
    viewSubscriptions: "VIEW_SUBSCRIPTIONS",
    manageSubscriptions: "MANAGE_SUBSCRIPTIONS",
    viewFIQCarts: "VIEW_FIQ_CARTS",
    manageFIQCarts: "MANAGE_FIQ_CARTS",
    manageTrialSubscriptions: "MANAGE_TRIAL_SUBSCRIPTIONS"
};

export const SCOPES = {
    canNavigateFIQCarts: "can-NavigateFIQ",
    canNavigateSubscriptions: "can-NavigateSubscriptions",
    canNavigateTrialSubscriptions: "can-NavigateTrialSubscriptions",
    canViewFIQCarts: "can-ViewFIQCarts",
    canManageFIQCarts: "can-ManageFIQCarts",
    canViewSubscriptions: "can-ViewSubscriptions",
    canViewTrialSubscriptions: "can-ViewTrialSubscriptions",
    canManageSubscriptions: "can-ManageSubscriptions",
    canManageUsers: "can-ManageUsers",
    canManageTrialSubscriptions: "can-ManageTrialSubscriptions",
    canManageIntegrationMaster: "can-ManageIntegrationMaster",
    canManageEventLogs: "can-ManageEventLogs"
};

export const PERMISSIONS = {
    [ROLES.viewSubscriptions]: [SCOPES.canViewSubscriptions, SCOPES.canNavigateSubscriptions],
    [ROLES.manageSubscriptions]: [SCOPES.canViewSubscriptions, SCOPES.canNavigateSubscriptions, SCOPES.canManageSubscriptions],
    [ROLES.viewFIQCarts]: [SCOPES.canViewFIQCarts, SCOPES.canNavigateFIQCarts],
    [ROLES.manageFIQCarts]: [SCOPES.canViewFIQCarts, SCOPES.canNavigateFIQCarts, SCOPES.canManageFIQCarts],
    [ROLES.admin]: [
        SCOPES.canNavigateFIQCarts,
        SCOPES.canNavigateSubscriptions,
        SCOPES.canManageFIQCarts,
        SCOPES.canManageSubscriptions,
        SCOPES.canViewFIQCarts,
        SCOPES.canViewSubscriptions,
        SCOPES.canManageUsers,
        SCOPES.canManageTrialSubscriptions,
        SCOPES.canViewTrialSubscriptions,
        SCOPES.canNavigateTrialSubscriptions,
        SCOPES.canManageIntegrationMaster,
        SCOPES.canManageEventLogs,
        SCOPES.isAdmin
    ],
    [ROLES.manageTrialSubscriptions]: [SCOPES.canViewTrialSubscriptions, SCOPES.canNavigateTrialSubscriptions, SCOPES.canManageTrialSubscriptions]
};
