import React, { useState, useEffect } from 'react';
import { red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import { ModalWait } from './WaitModal'
import Grid from '@mui/material/Grid';

function NetSuiteCustomerBrowse(props) {

    const open = props.open;
    const onClose = props.onClose;
    const [customerSearchQuery, updateCustomerQuery] = useState("");
    const [customerSearchResults, forceUpdate] = useState([]);
    const [resultCount, forceResultCountUpdate] = useState(0);
    const [showWaitModal, toggleWaitModal] = useState(false);
    const [hasSearchResults, toggleHasSearchResults] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [ItemsPerPage] = useState(3);
    const currentPosition = ((pageNumber * ItemsPerPage) - ItemsPerPage);
     const handlePrev = () => {

        if (pageNumber === 1) return;
        toggleWaitModal(true);
        setPageNumber(pageNumber - 1);
        toggleWaitModal(false);

    }
    const handleNext = () => {
        if (Math.round(resultCount / (pageNumber + 1)) >= ItemsPerPage) {
            toggleWaitModal(true);
            setPageNumber(pageNumber + 1);
            toggleWaitModal(false);
           // console.log(currentPosition);

            //console.log(paginatedCustomerSearchResults);
        }
        else return;

    }
    let paginatedCustomerSearchResults = customerSearchResults.slice(currentPosition, currentPosition + ItemsPerPage);

    function BuildAddress(name, addr1, addr2, city, state, zip, country) {
        return (name + "<br/>" +
            addr1 + "<br/>" +
            city + ", " + state + " " + zip + "<br/>" +
            country);

    }
    async function handleChange(e) {
        updateCustomerQuery(e.target.value);
        if (e.target.value.length >= 3) {
            await getNSSearchResults(e.target.value);
        }
        //console.log(e.target.value);
    }
    async function btnSearch_Click(e) {
        //var searchBy = e.parent.closest("#search");
       
        await getNSSearchResults(customerSearchQuery);

    }
    function handleDoubleClick(e) {
        //console.log(e.target);
        var searchResultCard = e.target.closest(".UWA_SearchResultCard");
        var oracleID = searchResultCard.getAttribute("id");
        console.log(oracleID);
        props.selectedOracleCustomerID(oracleID);
        //console.log(searchResultCard);
        //var searchResultCard = this.closest(".UWA_SearchResultCard");
        var elements = document.querySelectorAll(".UWA_SearchResultCard");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.backgroundColor = "";
        }
        searchResultCard.style.backgroundColor = "#ccc";
        onClose(e);
    }

    async function getNSSearchResults(searchBy) {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        updateCustomerQuery(searchBy);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                SearchQuery: searchBy
            })
        };
        try {
            toggleWaitModal(true);
            toggleHasSearchResults(false);
            const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomers';
            fetch(serviceUrl, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //console.log(data);
                    forceResultCountUpdate(data.count);
                    setPageNumber(1);
                    forceUpdate(data.items);
                    toggleWaitModal(false);
                    if (data.count > 0) { toggleHasSearchResults(true);}

                })
                .catch((error) => {
                    console.log('Error:', error);
                    toggleWaitModal(false);
                });
           
           
      
            
        }
        catch (error) {
            await toggleWaitModal(false);
            return error;
        }

    }
    function onClearResults(e) {
        forceUpdate([]);
        toggleHasSearchResults(false);
        forceResultCountUpdate(0);
        setPageNumber(1);

        updateCustomerQuery("");
    }
    return (

        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="UWA_DialogTitle" >
                Select NetSuite Customer
            </DialogTitle>
            <DialogContent className="UWA_CustomerBrowseDialogContent">
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <TextField style={{ width: '111%' }}
                            name="search"
                            label="Search by Customer Name or Oracle #"
                            onBlur={handleChange}
                            size="small"
                            defaultValue={customerSearchQuery}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton tooltip="Search for Customer" aria-label="customer search" onClick={(e) => { btnSearch_Click(e) }}>
                            <ManageSearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
               
               
                {paginatedCustomerSearchResults.map(customerRecord => (
                    <div key={customerRecord.id}>
                        <Card sx={{ display: 'flex', margin: 1 }} onDoubleClick={handleDoubleClick} className="UWA_SearchResultCard" id={customerRecord.id}>
                           
                            <span className="UWA_SearchResult_CB"></span>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6" className="UWA_SearchresultName">
                                        <BusinessRoundedIcon /> {customerRecord.name} ({customerRecord.id})
                                    </Typography>
                                    <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                        <b>Sales Rep</b> {customerRecord.salesrepfirstname}  {customerRecord.salesreplastname}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                        <Typography className="UWA_SearchResultAddress"  color="text.secondary" component="div" sx={{paddingRight: 3}}>
                                            <b>Billing Address</b>
                                            <p dangerouslySetInnerHTML={{ __html: BuildAddress(customerRecord.billtoaddressee, customerRecord.billtoaddress1, customerRecord.billtoaddress2, customerRecord.billtocity, customerRecord.billtostate, customerRecord.billtozip, customerRecord.billtocountry) }} />
                                         </Typography>
                                        <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                            <b>Shipping Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: BuildAddress(customerRecord.shiptoaddressee, customerRecord.shiptoaddress1, customerRecord.shiptoaddress2, customerRecord.shiptocity, customerRecord.shiptostate, customerRecord.shiptozip, customerRecord.shiptocountry) }} />
                                        </Typography>
                                    </Box>
                                    <Button autoFocus onClick={handleDoubleClick} variant="contained" className="UWA_btn_Submit">
                                        SELECT
                                    </Button>
                                </CardContent>
                               
                            </Box>
                           
                        </Card>
                       
                    </div>
                ))}
                {showWaitModal ? (
                    <ModalWait
                       
                    />
                ) : null}
                <div>
                    <span style={{ float: "left" }}>
                        <font>Page #: {pageNumber}</font>
                    </span>
                    <span style={{ float: "right" }}>
                        <font>Total Records Found: {resultCount}</font>
                        <IconButton color="secondary" onClick={handlePrev} aria-label="Previous" sx={{ color: red[600] }}>
                            <ArrowLeftIcon/>
                        </IconButton>
                        <IconButton color="secondary" onClick={handleNext} aria-label="Next" sx={{ color: red[600] }}>
                            <ArrowRightIcon/>
                        </IconButton>
                    </span>
                </div>
            </DialogContent>
            <DialogActions >
                <Button onClick={onClearResults} disabled={!hasSearchResults} autoFocus variant="contained" className="UWA_btn_clearresults">
                    Clear
                </Button>
                <Button onClick={onClose} autoFocus variant="contained" className="UWA_btn_cancel">
                    Close
                </Button>
       
            </DialogActions>
        </Dialog>
    );
};
export default NetSuiteCustomerBrowse;