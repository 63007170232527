import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import cartfunctions from '../../_helpers/cartfunctions';



export default class TermsAndConditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptedTC: props.getCartCheckOut().acceptedTC,
            acknowledgeTC: true,
            accepTCValMsg: '',
            currentTCVersion: process.env.REACT_APP_TC_VERSION,
            TCVersionMsg: "You are accepting version " + process.env.REACT_APP_TC_VERSION + " of our terms and conditions."
        };
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);

    }


    componentDidMount() {
        window.addEventListener('scroll', this.onScroll.bind(this));
        cartfunctions.getCartHeader().then(result => {
            if (!!result.tcVersion) {
                this.setState({ acknowledgeTC: false });
                this.setState({ acceptedTC: true });
            }
            else {
                this.setState({ acknowledgeTC: true });
                this.setState({ acceptedTC: false });
            }
        });
        if (this.state.acceptedTC) {
            this.setState({ acknowledgeTC: false });
        }

    }

    componentWillUnmount() { }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.acceptedTC !== userInput.acceptedTC ) { // only update store of something changed
                this.props.updateCartCheckOut({ ...userInput });
                cartfunctions.saveCartTCVersion(process.env.REACT_APP_TC_VERSION);
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return {
            accepTCVal: (data.acceptedTC === true) // required: anything besides N/A
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            accepTCValMsg: val.accepTCVal ? '' : 'You must accept the terms and conditions in order to proceed'
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            acceptedTC: this.state.acceptedTC
        };
    }
    handleChange = (event) => {
        this.setState({ acceptedTC: event.target.checked });
        this.setState({ accepTCValMsg: '' });
    }
    onScroll = (e) => {
        // console.log(e.target.scrollHeight);
        // console.log(e.target.scrollTop);
        //console.log(e.target.clientHeight);


        const bottom = Math.round((e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight) <= 6;
        //console.log(bottom);
        //console.log(Math.round((e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight));
        if (bottom) {
            this.setState({
                acknowledgeTC: false
            });

        }
        else {
            this.setState({
                acknowledgeTC: true
            });
        }
    }



    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if (typeof this.state.acceptedTC == 'undefined' || this.state.acceptedTC) {
            notValidClasses.accepTCValGrpCls = 'no-error';
        }
        else {
            notValidClasses.accepTCValGrpCls = 'has-error';

        }



        return (
            <div className="step step3">

                <Grid container spacing={2} paddingBottom={4} paddingRight={4} maxHeight={500}>
                    <Grid item xs={12} sm={12}>
                        <label className="col-md-12 control-label">
                            <h2>Accept Terms & Conditions</h2>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        Please review the terms and conditions as outlined below and check the box indicating you are accepting the terms and conditions.<br/><b>IMPORTANT: </b> <i>The checkbox button will become enabled once you scroll down and read to the bottom of the terms and conditions.</i>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {this.state.TCVersionMsg && <Alert severity="info"><>{this.state.TCVersionMsg}</></Alert>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormGroup >
                            <FormControlLabel control={<Switch disabled={this.state.acknowledgeTC} onChange={this.handleChange} checked={this.state.acceptedTC}/>} label="Accept Terms & Conditions" />

                        </FormGroup>
                        <span className={notValidClasses.accepTCValGrpCls}>{this.state.accepTCValMsg}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} maxHeight={475} >
                        <Box className="UWA_TermsAndConditionsBox"
                            sx={{
                                mb: 2,
                                flexDirection: "column",
                                height: '33%',
                                overflow: "hidden",
                                overflowY: "auto",

                                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                            }}
                            onScroll={(e) => { this.onScroll(e) }}
                        >
                            <p><b>TERMS AND CONDITIONS FOR UNIVERSAL&reg; PRODUCTS AND SERVICES</b></p>

                            <p>1. These terms and conditions (these "<strong>Terms</strong>") apply to the purchase of, or free trial period for, certain products and services, including Feasibility-IQ, from Universal Weather and Aviation, LLC (referred to as "<strong>Universal</strong>", "<strong>us</strong>", "<strong>we</strong>", or "<strong>our</strong>" as the context may require).</p>

                            <p>2. <span className="UWA_underline">Order Acceptance and Cancellation</span>. You agree that your order is an offer to buy, under these Terms, all products and services listed in your order. All orders must be accepted by us or we will not be obligated to sell the products or services to you or provide you with a free trial. We reserve the right to decline your purchase or request for free trial for any reason. If a purchase if declined, you will receive a refund of any money paid by you.</p>

                            <p>3. <span className="UWA_underline">Products and Services</span>. You acknowledge that the products and services provided are advisory in nature, including, but not limited to Feasibility-IQ, and with respect to such advisory services, you agree that you have a duty to and shall review and verify the accuracy and completeness of  all  information provided in connection therewith under this Agreement. Furthermore, any links with information that we send you are dynamic and will be updated as needed prior to your trip. You have a duty to continually review the link to ensure that you have the most up to date information. We shall not be responsible for any liability arising out of your failure to review the most up to date information provided to you. In addition, we shall not be responsible for information that you provide to us which is inaccurate.</p>

                            <p>4. <span className="UWA_underline">Prices and Payment Terms</span>. </p>

                            <p>(a) The price charged for a product or service will be the price in effect at the time the order is placed and will be set out in your order confirmation email. Price increases will only apply to orders placed after such changes. Posted prices do not include taxes or charges for shipping and handling. All such taxes and charges will be added to your merchandise total, and will be itemized in your order confirmation email. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. We reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any orders arising from such occurrences.</p>

                            <p>(b) Terms of payment are within our sole discretion and payment must be received by us before our acceptance of an order. We accept American Express, Visa and MasterCard for all purchases. You represent and warrant that (i) the credit card information you supply to us is true, correct and complete, (ii) you are duly authorized to use such credit card for the purchase, (iii) charges incurred by you will be honored by your credit card company, and (iv) you will pay charges incurred by you at the posted prices, including shipping and handling charges and all applicable taxes, if any.</p>

                            <p>5. <span className="UWA_underline">Warranty and Disclaimers</span>. </p>

                            <p><strong>ALL PRODUCTS AND SERVICES ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</strong></p>

                            <p><strong>SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU.</strong></p>

                            <p><strong>YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY DAMAGES ARISING OUT OF THE MANUFACTURER'S FAILURE TO HONOR ITS WARRANTY OBLIGATIONS TO YOU.</strong></p>

                            <p>6. <span className="UWA_underline">Limitation of Liability</span>. <strong>IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF, OR RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND (D) HOW YOU INTEND TO USE THE PRODUCT OR SERVICES. OUR SOLE AND ENTIRE MAXIMUM LIABILITY, FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE PRODUCTS AND SERVICES. </strong>The limitation of liability set forth above shall not apply to liability resulting from our gross negligence or willful misconduct.</p>

                            <p>7. <span className="UWA_underline">Privacy</span>. We respect your privacy and are committed to protecting it. Our <em>Privacy Policy</em>, www.universalweather.com/privacy, governs the processing of all personal data collected from you in connection with your purchase of products or services.</p>

                            <p>8. <span className="UWA_underline">Use of Information</span>. The use of any information you obtain through your purchase of products or services (the "<strong>Information</strong>") may only be used for your individual use or your organization's internal use only. You may not share any information with any other third party, including but not limited to other flight departments or any company which could be considered a competitor of Universal. Universal has the right to seek injunctive relief in any court of competent jurisdiction if you violate this provision.</p>

                            <p>9. <span className="UWA_underline">Use of Password</span>. Any password that you receive from Universal to access products and services must only be used by you. You may not share your password with anyone, including other people within your organization. If someone else in your organization requires a password, they should email customercarecenter@univ-wea.com. Universal reserves its right to monitor your IP address, log in, and other online information for compliance with this Section 9. Universal may terminate this Agreement in accordance with Section 17 if you violate this Section.</p>

                            <p>10. <span className="UWA_underline">Governing Law and Jurisdiction</span>. All matters arising out of or relating to these Terms are governed by and construed in accordance with the internal laws of the State of Texas without giving effect to any choice or conflict of law provision or rule. Any disputes arising out of or relating to these Terms shall be resolved in the federal or state courts located in Houston, Texas.</p>

                            <p>11. <span className="UWA_underline">Assignment</span>. You will not assign any of your rights or delegate any of your obligations under these Terms without our prior written consent. Any purported assignment or delegation in violation of this Section 11 is null and void. No assignment or delegation relieves you of any of your obligations under these Terms.</p>

                            <p>12. <span className="UWA_underline">No Waivers</span>. The failure by us to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any right or provision will be effective only if in writing and signed by a duly authorized representative of Universal Weather and Aviation, LLC.</p>

                            <p>13. <span className="UWA_underline">No Third-Party Beneficiaries</span>. These Terms do not and are not intended to confer any rights or remedies upon any person other than you.</p>

                            <p>14. <span className="UWA_underline">Notices</span>.</p>

                            <p>(a) <span className="UWA_underline">To You</span>. We may provide any notice to you under these Terms by sending a message to the email address you provide. Notices sent by email will be effective when we send the email. It is your responsibility to keep your email address current.</p>

                            <p>(b) <span className="UWA_underline">To Us</span>. To give us notice under these Terms, you must contact us as follows: (i) by email to oanddservicessetup@univ-wea.com; or (ii) by personal delivery, overnight courier, or registered or certified mail to Universal Weather and Aviation, LLC, 1150 Gemini Street, Houston, Texas 77058. Notices provided by personal delivery will be effective immediately. Notices provided by email shall be effective upon receipt of a confirmation email. Notices provided by overnight courier will be effective one business day after they are sent. Notices provided by registered or certified mail will be effective three business days after they are sent.</p>

                            <p>15. <span className="UWA_underline">Severability</span>. If any provision of these Terms is invalid, illegal, void or unenforceable, then that provision will be deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.</p>

                            <p>16. <span className="UWA_underline">Entire Agreement</span>. These Terms and our Privacy Policy will be deemed the final and integrated agreement between you and us on the matters contained in these Terms.</p>

                            <p>17. <span className="UWA_underline">Termination</span>. Any subscriptions will automatically renew until cancelled in writing by you. To terminate a subscription, you may send an email to oanddservicessetup@univ-wea.com. The termination shall be effective for the next invoicing period (the "<strong>Effective Date</strong>"). Universal may terminate a subscription for cause, and immediately cut-off your access to such subscription, if you violate the terms of this Agreement, including but not limited, sharing your password in violation of Section 9. In the aforementioned scenarios, you shall not receive any type of refund, pro-rated or otherwise, for the period between the date of your termination notice and the Effective Date.</p>

                        </Box>

                    </Grid>

                </Grid>
            </div>
        )
    }
}