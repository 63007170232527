import React, { useEffect, useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Container, Row, Col } from 'reactstrap';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Paper from '@mui/material/Paper';
import NetSuiteCustomerBrowse from '../NetSuiteCustomerBrowse';
import FIQAddUserModal from './FIQAddUserModal'
import { netsuiteService } from '../../_services/netsuite.service';
import { commonfunctions } from '../../_helpers/commonfunctions';
import { ModalWait } from '../WaitModal';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function FeasibilityIQCart(props) {
    const [loading, setLoading] = React.useState(true);
    const [confirmUserDeleteModal, showConfirmUserDeleteModal] = React.useState(false);
    const [deleteUserEmail, setUserDeletionEmail] = React.useState("");
    const [CardHeaderTitle, updateCardHeaderTitle] = React.useState("Customer Information");

    const [cartMode] = React.useState(props.mode);
    const [currentCartID, updateCurrentCartID] = React.useState("");
    const [disabled, setDisabledFlag] = React.useState(false);
    const [cartLoaded, setCartIsLoaded] = React.useState(false);
    const [cartStatus, updateCartStatus] = React.useState("");
    const [createDate, updateCreateDate] = React.useState("");
    const [linkExpirationDate, updateLinkExpDate] = React.useState("");
    const [cartLink, updateCartLink] = React.useState("");
    const [canEditCart, updateCanEditCart] = React.useState(false);
    const [canArchiveCart, updateCanArchiveCart] = React.useState(false);
    const [canSendCartLink, updateCanSendCartLink] = React.useState(false);
    const [cartContactFName, updateCartContactFName] = React.useState("");
    const [cartContactLName, updateCartContactLName] = React.useState("");
    const [cartContactEmail, updateCartContactEmail] = React.useState("");
    const [cartContactPhone, updateCartContactPhone] = React.useState("");
    const [errContactFNameMsg, updateContactFNameErrMsg] = React.useState("");
    const [errContactLNameMsg, updateContactLNameErrMsg] = React.useState("");
    const [errContactEmailMsg, updateContactEmailErrMsg] = React.useState("");
    const [errContactPhoneMsg, updateContactPhoneErrMsg] = React.useState("");
    const [sendButtonText, updateSendButtonText] = React.useState("Send Link");

    const [showWaitModal, updateWaitModal] = React.useState(false);
    const [dialogIsOpen, setCustomerBrowseDialogOpen] = React.useState(false);
    const [editName, setEditName] = React.useState("");
    const [editUserName, setEditUserName] = React.useState("");
    const [editEmail, setEditEmail] = React.useState("");
    const [shoppingCartValidationErrMsgs, setvalidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateUserErrMsg, setuserValidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateValidationErrMsgs, setUpdateCartValidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateMsgs, setCartUpdateSuccessMsg] = React.useState("");
    const [couponCode, updateCouponCode] = React.useState("");
    const [appliedCouponCode, saveCouponCode] = React.useState("");
    const [couponCodeValMsg, updateCouponValidationMsg] = React.useState("");
    const [FIQAddUserDialogIsOpen, setFIQAddUserModalOpen] = React.useState(false);
    const [FIQUsers, addToFIQUserArray] = React.useState([]);
    const [FIQPricingLegend, addToFIQPricingLegend] = React.useState([]);
    const [SubscriptionProductName, updateProductName] = React.useState("");
    const [SubscriptionProductID, updateProductID] = React.useState("");
    const [unitPrice, updateUnitPrice] = React.useState(0);
    const [discountAmount, updateDiscountAmount] = React.useState(0);
    const [oracleCustomerID, setCurrentOracleCustomerID] = React.useState("");
    const [companyName, setCurrentCompanyName] = React.useState("");
    const [billingAddress, setCurrentBillingAddress] = React.useState("");
    const [shippingAddress, setCurrentShippingAddress] = React.useState("");
    const closeDialog = () => setCustomerBrowseDialogOpen(false);
    const closeFIQAddUserDialog = () => setFIQAddUserModalOpen(false);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));
    const handleClickOpenCustomerBrowse = (event) => {
        setCustomerBrowseDialogOpen(true);
    };
    const handleClickOpenUserAdd = (event) => {
        setEditName("");
        setEditUserName("");
        setEditEmail("");
        setFIQAddUserModalOpen(true);
    };
    const handle_DeleteUser = async (event, emailAddress) => {
        setUserDeletionEmail(emailAddress);
        showConfirmUserDeleteModal(true);
      
    };
    const deleteUserFromSubscription = async () => {
        var success = false;
        let tempFIQUsers = [...FIQUsers];
        if (FIQUsers.length > 0) {
            for (let i = 0; i < FIQUsers.length; i++) {
                if (FIQUsers[i].email.toLowerCase() === deleteUserEmail.toLowerCase()) {
                    if (!cartLoaded) {
                        //We are deleting just from memory - there is no cart yet. 
                        tempFIQUsers.splice(i, 1);
                        success = true;
                    }
                    else {
                        if (await modifyFIQCartUser("Delete", FIQUsers[i].name, FIQUsers[i].username, FIQUsers[i].email)) {
                            tempFIQUsers.splice(i, 1);
                            success = true;
                        };

                    }
                  

                }
            };
            if (success) {
                addToFIQUserArray(tempFIQUsers);
               
            };
            setUserDeletionEmail("");
            showConfirmUserDeleteModal(false);
        }
    }
    function handle_CloseUserDeletionDialog() {
        setUserDeletionEmail("");
        showConfirmUserDeleteModal(false);
    }
    const handle_ContactFieldChange = (e) => {
        switch (e.target.name) {
            case "ContactFName":
                updateCartContactFName(e.target.value);
                break;
            case "ContactLName":
                updateCartContactLName(e.target.value);
                break;
            case "ContactEmail":
                updateCartContactEmail(e.target.value);
                break;
            case "ContactPhone":
                updateCartContactPhone(e.target.value);
                break;
        }
        //console.log(e.target.name);
        
    }
    const handle_EditUser = (event, name, userName, emailAddress) => {
        setEditName(name);
        setEditUserName(userName);
        setEditEmail(emailAddress);
        setFIQAddUserModalOpen(true);
    }
    const setCurrentOracleID = (oracleID) =>{
        setCurrentOracleCustomerID(oracleID);
        setvalidationErrorMsg("");
        //console.log(props.bearerToken);

        //Grab the values from the db and set the rest of the values on the form. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
      
        const body = {
            SearchQuery: oracleID
        };
        updateWaitModal(true);
        try {
            
            const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomerDetails';
            axios.post(serviceUrl, body)
                .then((response) => {
                     if (response.status === 200) {
                        setCurrentCompanyName(response.data.name);
                        setCurrentBillingAddress(netsuiteService.buildHTMLAddress(response.data.billtoaddressee, response.data.billtoaddress1, response.data.billtoaddress2, response.data.billtocity, response.data.billtostate, response.data.billtozip, response.data.billtocountry));
                        setCurrentShippingAddress(netsuiteService.buildHTMLAddress(response.data.shiptoaddressee, response.data.shiptoaddress1, response.data.shiptoaddress2, response.data.shiptocity, response.data.shiptostate, response.data.shiptozip, response.data.shiptocountry));
                      
                    }
               
                })
                .catch((error) => {
                    console.log('Error:', error);
                    updateWaitModal(false);

                });
            updateWaitModal(false);



        }
        catch (error) {
            updateWaitModal(false);
            return error;
        }

    };
    useEffect(() => {
   
        if (FIQPricingLegend.length == 0) {
            getFIQTierPricing();
        }
        setTierUnitPrice(FIQUsers.length);
        getDiscountAmount();
    }, [FIQUsers]);

    const load_CurrentCart = useCallback(async () => {
        if (!cartLoaded && cartMode == "manage") {
            let cartID = await props.currentCart;
            //console.log(cartID);
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                cartID: encodeURI(cartID)
            };


            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/GetFIQCart';
                axios.post(serviceUrl, FIQCartInfo)

                    .then(response => {

                        //console.log(response);
                        if (response.status === 200) {
                            setCartIsLoaded(true);
                            updateCardHeaderTitle("Cart Details");
                            updateCartStatus(response.data.status);
                            updateCanEditCart(response.data.canEditCart);
                            updateCanArchiveCart(response.data.canArchiveCart);
                            if (!response.data.canEditCart) {
                                setDisabledFlag(true);
                                //console.log("IsDisabled");
                            }
                            updateCanSendCartLink(response.data.canSendCartLink);
                            if (!!response.data.shoppingCartLink) { updateSendButtonText("Resend Link");}
                            updateCreateDate(new Date(response.data.created).toLocaleDateString('en-US'));
                            updateCartContactFName(response.data.soldToFirstName);
                            updateCartContactLName(response.data.soldToLastName);
                            updateCartContactEmail(response.data.soldToEmail);
                            updateCartContactPhone(response.data.soldToPhone);
                            updateCartLink(response.data.shoppingCartLink);
                            updateUnitPrice(response.data.cartLines[0].unitPrice);
                            //updateCouponCode(response.data.couponCode);
                            if (!!response.data.cartLines[0].couponCode) { saveCouponCode(response.data.cartLines[0].couponCode);}
                            
                            updateDiscountAmount(response.data.cartLines[0].discount);
                            if (!!response.data.expirationDate) { updateLinkExpDate(new Date(response.data.expirationDate).toLocaleDateString('en-US')) } else { updateLinkExpDate("N/A") };
                            ///console.log(response.data);
                            updateCurrentCartID(response.data.cartID);
                            setCurrentOracleID(response.data.oracleID);
                            let parser = new DOMParser();
                            let extraInfo = parser.parseFromString(response.data.cartLines[0].extraInfo, "text/xml");
                            let FIQUser = extraInfo.getElementsByTagName("FIQUser");
                            //reset the array to null;
                            addToFIQUserArray((FIQUsers) => []);

                            for (var i = 0; i < FIQUser.length; i++) {
                                //console.log(FIQUser[i].getAttribute("Email"));
                                if (FIQUser[i].nodeType == 1 && FIQUser[i].hasAttribute("Name") && FIQUser[i].hasAttribute("UserName") && FIQUser[i].hasAttribute("Email")) {
                                    loadFIQUser(FIQUser[i].getAttribute("Name") ,  FIQUser[i].getAttribute("UserName"),  FIQUser[i].getAttribute("Email"));
                                }
                               // console.log(FIQUsers);
                                
                            }
                           
                            setLoading(false);
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setvalidationErrorMsg(error.response.data);
                        setLoading(false);
                    });

               



            }
            catch (error) {
                //return error;
                setvalidationErrorMsg(commonfunctions.handleError(error.status, error));
                updateWaitModal(false);
            }
        }
        else {
            //we are here because are are adding a new cart.
            updateCanEditCart(true);
        }

    }, [props.currentCart])
    useEffect(() => {
         load_CurrentCart();
 

    }, [load_CurrentCart]);
   
   
    async function getFIQTierPricing() {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
       
        try
        {
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetFeasibilityIQPlan';
            axios.get(serviceUrl)
                .then((response) => {
                    //console.log(response.data);
                    addToFIQPricingLegend([]);
                    updateProductName(response.data.external_name);
                    updateProductID(response.data.id);
                    for (const tier of response.data.item_price.tiers) {
                     
                        addToFIQPricingLegend((FIQPricingLegend) => [...FIQPricingLegend, tier]);
                    }
                   
                 })
                .catch((error) => {
                    console.log('Error:', error);
                });

        }
        catch (error) {
            return error;
        }
    }
    const  setTierUnitPrice  = (userCount) => {
        for (const tier of FIQPricingLegend)
        {
            let lowerBound = tier.startingUnit;
            let upperBound = tier.endingUnit;
        

            if (userCount >= lowerBound && userCount <= upperBound) {
                updateUnitPrice(tier.price);
                //getDiscountAmount(couponCode);
                break;
            }

        }
       
    }
    const validateCouponCode = async () => {
        if (!!couponCode)
        {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            //const requestOptions = {
            //    method: 'GET',
            //    headers: { 'Content-Type': 'application/json' }
            //};
            try {
                const serviceUrl = endPointURL + "api/ChargebeeWidgets/ValidateCouponCode/" + couponCode;
                await axios.get(serviceUrl)
                    .then((response) => {
                        if (response.status === 200) {
                            saveCouponCode(couponCode);
                            getDiscountAmount(couponCode);
                            updateCartCouponCode();
                        }
                    })
                    .catch((error) => {
                       updateCouponValidationMsg(error.response.data.message);
                        //updateCouponValidationMsg("Invalid Coupon");
                    })
                
            }
            catch (error) {
                updateCouponValidationMsg(error.message);
            }
           

        }
        else {
            updateCouponValidationMsg("You must enter a coupon to validate it");
        }
    }
    const returnToDashboard = () => {
        window.location.href = '/Admin/ManageFIQCarts';
    }
    const validateContactFields = () => {
        var success = true;
        updateContactFNameErrMsg("");
        updateContactLNameErrMsg("");
        updateContactEmailErrMsg("");
        updateContactPhoneErrMsg("");
        if (!(cartContactFName !== undefined && cartContactFName !== '' && /^[a-zA-Z '.-]*$/.test(cartContactFName) && cartContactFName?.length)) {
            updateContactFNameErrMsg("First Name is not valid");
            success  = false;
        }
        

        if (!(cartContactLName !== undefined && cartContactLName !== '' && /^[a-zA-Z '.-]*$/.test(cartContactLName) && cartContactLName?.length)) {
            updateContactLNameErrMsg("Last Name is not valid");
            success = false;
        }
        if (!(cartContactEmail !== undefined && cartContactEmail !== '' && /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cartContactEmail))) {
            updateContactEmailErrMsg("Email is not valid");
            success = false;
        }
        if (!(cartContactPhone !== undefined && cartContactPhone !== '' && /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(cartContactPhone))) {
            updateContactPhoneErrMsg("Phone is not valid");
            success = false;
        }
        return success;
    }
    
    const updateShoppingCart = () => {
        if (validateContactFields())
        {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                contactFirstName: cartContactFName,
                contactLastName: cartContactLName,
                contactEmail: cartContactEmail,
                contactPhone: cartContactPhone,
                cartID: encodeURI(props.currentCart)
            };
            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/UpdateFIQCartContactInfo';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            load_CurrentCart();
                            setCartUpdateSuccessMsg("Cart updated successfully!");
                            setTimeout(() => {
                                setCartUpdateSuccessMsg("");
                            }, 8000);
                            //window.location.href = '/MyDashboard/ManageFIQCart?CartID=' + decodeURI(props.currentCart);
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setUpdateCartValidationErrorMsg(error.response.data.detail);

                    });
            }
            catch (error) {
                setUpdateCartValidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        }
    }
    const updateCartCouponCode = () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            contactFirstName: cartContactFName,
            contactLastName: cartContactLName,
            contactEmail: cartContactEmail,
            contactPhone: cartContactPhone,
            cartID: encodeURI(props.currentCart),
            discountCode: couponCode,
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/UpdateCouponCodeFIQCart';
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    if (response.status === 200) {
                        //load_CurrentCart();
                        //updateCouponValidationMsg("Cart updated successfully!");
                        //setTimeout(() => {
                        //    setCartUpdateSuccessMsg("");
                        //}, 8000);
                        //window.location.href = '/MyDashboard/ManageFIQCart?CartID=' + decodeURI(props.currentCart);
                    }


                })

                .catch(error => {
                    console.log(error);
                    updateCouponValidationMsg(error.response.data.detail);

                });
        }
        catch (error) {
            setUpdateCartValidationErrorMsg(error.message);
            updateWaitModal(false);
        }

    }
    const saveShoppingCart = ()  => {
        var isValid = true;
        if(!!oracleCustomerID) {
            isValid = true;
        }
        else
        {
            isValid = false;
            setvalidationErrorMsg("Please select a customer for this shopping cart.");
        }
        if (FIQUsers.length === 0) {
            isValid = false;
            setvalidationErrorMsg("You must enter information for at least one user.");


        }

        if (isValid) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            var tempUsersList = [];
            for (var i = 0; i < FIQUsers.length; i++) {
                const FIQUserInfo = {
                    cartID: "",
                    name: FIQUsers[i].name,
                    userName: FIQUsers[i].userName,
                    email: FIQUsers[i].email
                };
                tempUsersList.push(FIQUserInfo);
            }
            const FIQCartInfo = {
                customerID: oracleCustomerID,
                subscribers: tempUsersList,
                CartLines: [{
                    productID: SubscriptionProductID,
                    quantity: FIQUsers.length,
                    unitprice: unitPrice,
                    discountCode: couponCode,
                    discountAmount: discountAmount
                }]
            };
           
          
            try {
                updateWaitModal(true);
                const serviceUrl = endPointURL + 'api/ShoppingCart/CreateFIQCart';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            window.location.href = '/Admin/ManageFIQCart?CartID=' + response.data.cartID;
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setvalidationErrorMsg(error.response.data.detail);
                        updateWaitModal(false);
                       
                    });

                    




            }
            catch (error) {
                console.log(error);
                setvalidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        }
        return isValid;
    }
    const archiveShoppingCart = () => {
        if (window.confirm('Are you sure you want to archive this shopping cart?')) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                cartID: encodeURI(props.currentCart)
            };
            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/ArchiveFIQCart';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            returnToDashboard();
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setUpdateCartValidationErrorMsg(error.response.data.detail);

                    });
            }
            catch (error) {
                setUpdateCartValidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        } else {
            // User cancelled the delete operation.

        }
    }
    
    const sendShoppingCartLink = () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            cartID: encodeURI(props.currentCart)
        };
        try {
            updateWaitModal(true);
            const serviceUrl = endPointURL + 'api/ShoppingCart/SendFIQCartLinkToCustomer';
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    if (response.status === 200) {
                        load_CurrentCart();
                        setCartUpdateSuccessMsg("Email sent successfully to Customer!");
                        setTimeout(() => {
                            setCartUpdateSuccessMsg("");
                        }, 8000);
                    }


                })

                .catch(error => {
                    console.log(error);
                    setUpdateCartValidationErrorMsg(error.response.data.detail);

                });
            updateWaitModal(false);
        }
        catch (error) {
            setUpdateCartValidationErrorMsg(error.message);
            updateWaitModal(false);
        }
        
    }
    const getDiscountAmount = () => {

        if (!!couponCode) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            //const cartAmount = unitPrice * FIQUsers.length;
            try {
                const serviceUrl = endPointURL + "api/ChargebeeWidgets/GetCouponDiscountAmount/" + couponCode + "/" + FIQUsers.length + "/" + SubscriptionProductID;
                fetch(serviceUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (!!data.discountAmount) {
                            updateDiscountAmount(data.discountAmount);
                        }
                        else {
                            saveCouponCode("");
                            updateCouponValidationMsg(data.message);
                        }
                        //console.log(data);
                    })
                    .catch((error) => {
                        saveCouponCode("");
                        updateCouponValidationMsg(error.message);
                    });
            }
            catch (error) {
                saveCouponCode("");
                updateCouponValidationMsg(error.message);
            }


        }
       

    }
    const modifyFIQCartUser = async (operation, name, userName, email) => {
        var endPointURL = process.env.REACT_APP_API_URL;
        
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            cartID: encodeURI(props.currentCart),
            name: name,
            userName: userName, 
            email: email
        };
        try {
            updateWaitModal(true);
            let endPoint = '';
            switch (operation) {
                case "Add":
                    endPoint = 'api/ShoppingCart/AddUserToFIQCart';
                    break;
                case "Edit":
                    endPoint = 'api/ShoppingCart/EditUserFIQCart';
                    break;
                case "Delete":
                    endPoint = 'api/ShoppingCart/DeleteUserFromFIQCart';
                    break;
            }
            const serviceUrl = endPointURL + endPoint;
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    if (response.status === 200) {

                        load_CurrentCart();
                        return true;
                       
                    }


                })

                .catch(error => {
                    console.log(error);
                    setuserValidationErrorMsg(error.response.data.detail);
                    return false;

                });
            updateWaitModal(false);
        }
        catch (error) {
            setuserValidationErrorMsg(error.message);
            updateWaitModal(false);
            return false;
        }
    };

    function handleCouponCodeChange(e) {
        updateCouponCode(e.target.value);

    }
    function clearSavedCouponCode() {
        saveCouponCode("");
        updateCouponCode("");
        updateCartCouponCode();
        updateDiscountAmount(0);
    }

    async function loadFIQUser(name, userName, email) {
        let itemExists = false;
       //Reset the array to null
            //Lets make sure the email and username does not already exist
            if (FIQUsers.length > 0) {
                for (let i = 0; i < FIQUsers.length; i++) {
                    //console.log(FIQUsers[i].name);
                    if (FIQUsers[i].name.toLowerCase() === name.toLowerCase() || FIQUsers[i].email.toLowerCase() === email.toLowerCase()) {
                        //We can't add to the array since it already exists. Let the user know.
                        itemExists = true;
                    }


                };
            }
            if (!itemExists && (!!name) && (!!userName) && (!!email)) {
                updateWaitModal(true);
                setvalidationErrorMsg("");
                await addToFIQUserArray((FIQUsers) => [...FIQUsers, { name, userName, email }]);
                //setTierUnitPrice(FIQUsers.length+1);
                //Reset the values being passed back to Modal
                setEditName("");
                setEditUserName("");
                setEditEmail("");
                updateWaitModal(false);
                setFIQAddUserModalOpen(false);
            }
    
        return !itemExists;
    };

    async function addFIQUser(originalEmail, name, userName, email) {
        let itemExists = false;
        if (!!originalEmail) {
            //We are editing an existing user.
            let tempFIQUsers = [...FIQUsers];
            if (tempFIQUsers.length > 0) {
                console.log(tempFIQUsers);
                //Lets make sure what we're trying to change does not already exist in the list of users. 
                const founduserName = tempFIQUsers.some(el => (el.userName.toLowerCase() === userName.toLowerCase() && el.email != originalEmail));
                if(founduserName)
                {
                    itemExists = true;
                }
                else {
                    for (let i = 0; i < tempFIQUsers.length; i++) {
                        if (tempFIQUsers[i].email.toLowerCase() === originalEmail.toLowerCase()) {
                            tempFIQUsers.splice(i, 1);
                            tempFIQUsers.splice(i, 0, { name, userName, email });
                            if (cartLoaded) {
                                await modifyFIQCartUser("Edit", name, userName, email);
                            }
                        }

                    };
                    addToFIQUserArray(tempFIQUsers);
                }
               
            }
        }
        else {
            //Lets make sure the email and username does not already exist
            if (FIQUsers.length > 0) {
                for (let i = 0; i < FIQUsers.length; i++) {
                    //console.log(FIQUsers[i].name);
                    if (FIQUsers[i].userName.toLowerCase() === userName.toLowerCase() || FIQUsers[i].email.toLowerCase() === email.toLowerCase()) {
                        //We can't add to the array since it already exists. Let the user know.
                        itemExists = true;
                    }


                };
            }
            if (!itemExists && (!!name) && (!!userName) && (!!email)) {
                updateWaitModal(true);
                setvalidationErrorMsg("");
                await addToFIQUserArray((FIQUsers) => [...FIQUsers, { name, userName, email }]);
                if (cartLoaded) {
                    await modifyFIQCartUser("Add", name, userName, email);
                }
                //setTierUnitPrice(FIQUsers.length+1);
                //Reset the values being passed back to Modal
                setEditName("");
                setEditUserName("");
                setEditEmail("");
                updateWaitModal(false);
                setFIQAddUserModalOpen(false);
            }
        }
     
        return !itemExists;
     };
    
  
    return (
        <Container>
             
            <Row >
                <Col>
                    <Card >
                        <CardHeader title={CardHeaderTitle} className="UWA_FIQCardTitle"
                            action={
                                <IconButton
                                    disabled= {!canEditCart}
                                    tooltip="Search for Customer"
                                    aria-label="settings"
                                    onClick={(e) => { handleClickOpenCustomerBrowse(e) }}
                                    sx={{ color: yellow[50] }}>
                                    <SearchIcon  />
                                </IconButton>
                            }>
                        </CardHeader>
                        {showWaitModal ? (
                            <ModalWait handleResponse={updateWaitModal}
                            />
                        ) : null}
                               
                        <CardContent className="UWA_CustomerCardContent">
                          
                            {oracleCustomerID?.length === 0 && cartMode === "create" ? (<font className='UWA_FIQCartInstructions'>Click on the search icon to assign a customer for this subscription. Please note that the customer must be an existing customer in NetSuite.</font>)
                                : (
                                    <Grid container spacing={2}>
                                        {cartMode === "manage" ? (
                                           <> 
                                                <Grid item xs={12} sm={4}>
                                                    <Item> {loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Name&nbsp;</b>{companyName}</font>)}</Item>
                                               </Grid>
                                           
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Oracle ID&nbsp;</b> {oracleCustomerID}</font>)}</Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Status&nbsp;</b> {cartStatus}</font>)}</Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) : (<font className="UWA_FIQLabel"><b>Billing Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: billingAddress }}></p></font>)} </Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) : (<font className="UWA_FIQLabel"><b>Shipping Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: shippingAddress }}></p></font>)} </Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Create Date</b> {createDate}</font>)}</Item></Grid>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Expiration Date</b> {linkExpirationDate}</font>)}</Item></Grid>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Link&nbsp;</b> <a href={cartLink} target="_new">Cart Link Preview</a> </font>)}</Item></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) :(<><Divider role="presentation" sx={{ marginBottom: '8px'}}> <Chip label="Customer Contact Info" /></Divider>
                                                    <Stack direction={{ xs: 'column', sm: 'row' }}>
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactFName"
                                                            label="First Name"
                                                            size="small"
                                                            required
                                                            onChange={handle_ContactFieldChange}
                                                            defaultValue={cartContactFName}
                                                            helperText={errContactFNameMsg}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactLName"
                                                            label="Last Name"
                                                            size="small"
                                                            required
                                                            onChange={handle_ContactFieldChange}
                                                            defaultValue={cartContactLName}
                                                            helperText={errContactLNameMsg}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactEmail"
                                                            label="Email"
                                                            size="small"
                                                            required
                                                            onChange={handle_ContactFieldChange}
                                                            helperText={errContactEmailMsg}
                                                            defaultValue={cartContactEmail}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactPhone"
                                                            label="Phone"
                                                            size="small"
                                                            required
                                                            onChange={handle_ContactFieldChange}
                                                            defaultValue={cartContactPhone}
                                                            helperText={errContactPhoneMsg}
                                                            disabled={disabled}
                                                        />
                                                    </Stack></>)}
                                                </Grid>
                                            </>
                                        ) : (
                                                <> <Grid item xs={12} sm={8}>
                                                   <Item><font className="UWA_FIQLabel"><b>Company Name&nbsp;</b> {companyName}</font></Item>
                                                </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                <Item><font className="UWA_FIQLabel"><b>Oracle ID&nbsp;</b> {oracleCustomerID}</font></Item>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Item><font className="UWA_FIQLabel"><b>Billing Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: billingAddress }}></p></font> </Item>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Item><font className="UWA_FIQLabel"><b>Shipping Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: shippingAddress }}></p></font> </Item>
                                                    </Grid>
                                                </>

                                        )}
                                      
                                       
                                       
                                    </Grid>
                                )}
                            </CardContent>
                        <CardActions>
                            {(currentCartID === "") ?
                                (<><span className="UWA_FIQCartActions"></span>
                                </>
                                )
                                : (<> <Grid container spacing={2}>
                                         <Grid item xs={7} sm={7} md={7}>
                                        <Button size="small" className="UWA_btn_cancelFIQcart" onClick={returnToDashboard}>DASHBOARD</Button>
                                        {canArchiveCart && <Button size="small" className="UWA_btn_deleteFIQcart" onClick={archiveShoppingCart}>ARCHIVE</Button>}
                                          </Grid>
                                        <Grid item xs={5} sm={5} container  justifyContent="flex-end">
                                       
                                        {canEditCart && <Button size="small" className="UWA_btn_createFIQcart" onClick={updateShoppingCart} >SAVE/GET LINK</Button>}
                                        {canSendCartLink && <Button size="small" className="UWA_btn_sendFIQcartLink" onClick={sendShoppingCartLink} startIcon={<SendIcon />}>{sendButtonText}</Button>}
                                        {!canSendCartLink && <Button size="small" className="UWA_btn_sendFIQcartLinkDisabled" startIcon={<SendIcon />} disabled>{sendButtonText}</Button>}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        {shoppingCartUpdateValidationErrMsgs && <Alert severity="error"><>{shoppingCartUpdateValidationErrMsgs}</></Alert>}
                                        {shoppingCartUpdateMsgs && <Alert severity="success"><>{shoppingCartUpdateMsgs}</></Alert>}
                                        </Grid>
                                      </Grid>
                                    </>
                                )}
                        </CardActions>
                        <NetSuiteCustomerBrowse open={dialogIsOpen} onClose={closeDialog} selectedOracleCustomerID={setCurrentOracleID} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mx-auto my-4">
                        <CardHeader title="Subscription Users" className="UWA_FIQCardTitle" action={
                            <IconButton disabled={!canEditCart} tooltip="Add FIQ User" aria-label="settings" onClick={(e) => { handleClickOpenUserAdd(e) }} sx={{ color: yellow[50] }}>
                                <PersonAddIcon />
                            </IconButton>
                        }>

                        </CardHeader>


                        <CardContent>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                {FIQUsers?.length === 0 ?
                                    (<font className='UWA_FIQCartInstructions'>Click on the Add User Icon to add a user for this subscription</font>) 
                                    : (FIQUsers.map(FIQUser => {
                                        return (<Grid item xs={12} sm={12} md={3} lg={3} key={FIQUser.email}  >
                                            <Item sx={{ textAlign: 'center' }}> <AccountCircleIcon color="primary" aria-label={FIQUser.name} sx={{ height: 80, width: 60 }} >

                                            </AccountCircleIcon>
                                                <p><font className="UWA_FIQLabel"><b>{FIQUser.name}</b></font></p>
                                                <p><font className="UWA_FIQLabel">{FIQUser.userName}</font><br />
                                                    <font className="UWA_FIQLabel">{FIQUser.email}</font></p>
                                                <p className="UWA_FIQActions">

                                                    {canEditCart && <><IconButton tooltip="Edit User" aria-label="edit user" onClick={(e) => { handle_EditUser(e, FIQUser.name, FIQUser.userName, FIQUser.email) }}>
                                                            <EditIcon className="UWA_FIQAction_EditButton" />
                                                        </IconButton>
                                                        <IconButton tooltip="Delete User" aria-label="delete user" onClick={(e) => { handle_DeleteUser(e, FIQUser.email) }}>
                                                           <DeleteForeverIcon className="UWA_FIQAction_DeleteButton" />
                                                    </IconButton></>}
                                                        </p>
                                            </Item>
                                           
                                        </Grid>);
                                    }))}
                               
                            </Grid>
                                {shoppingCartUpdateUserErrMsg && <Alert severity="error"><>{shoppingCartUpdateUserErrMsg}</></Alert>}
                        </CardContent>
                        <CardActions>
                            {/*<Button size="small">Share</Button>*/}
                            {/*<Button size="small">Learn More</Button>*/}
                        </CardActions>
                        <FIQAddUserModal open={FIQAddUserDialogIsOpen} onClose={closeFIQAddUserDialog} updateUserList={addFIQUser} editName={editName} editUserName={editUserName} editEmail={editEmail} />

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mx-auto my-4">
                        <CardHeader title="Subscription Pricing Information" className="UWA_FIQCardTitle" >

                        </CardHeader>
                        <CardContent>
                            <Grid container spacing={5} className="UWA_GridMarginRemover">
                                <Grid item xs={12} md={8} className="UWA_GridPaddingRemover">
                                    <Grid container spacing={2} className="UWA_SubscriptionCart">
                                        <Grid container spacing={2} className="UWA_FIQCartHeader">
                                            <Grid item xs={6} align="left">
                                                <b>Name</b>
                                            </Grid>
                                         
                                            <Grid item xs={2} align="center">
                                                <b>Quantity</b>
                                            </Grid>
                                            <Grid item xs={2} align="center">
                                                <b>Unit Price</b>
                                            </Grid>
                                            <Grid item xs={2} align="right">
                                                <b> Sub Total</b>
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} align="left">
                                                {SubscriptionProductName}
                                            </Grid>
                                          
                                            <Grid item xs={2} align="center">
                                                {FIQUsers.length}
                                            </Grid>
                                            <Grid item xs={2} align="center">
                                                {commonfunctions.formatToCurrency(unitPrice)}
                                            </Grid>
                                            <Grid item xs={2} align="right">
                                                {!!FIQUsers ? commonfunctions.formatToCurrency(unitPrice * FIQUsers.length) : (0)}
                                            </Grid>
                                            <Grid item xs={10} align="right">
                                                <b> Discounts Applied </b>
                                            </Grid>
                                            <Grid item xs={2} align="right">
                                                <font className={discountAmount > 0 ? "UWA_CartDiscountAmount" : ""}>{commonfunctions.formatToCurrency(discountAmount)}</font>
                                            </Grid>
                                            <Grid item xs={10} align="right">
                                                <b> Cart Total </b>
                                            </Grid>
                                            <Grid item xs={2} align="right">
                                                {!!FIQUsers ? (<font className={((unitPrice * FIQUsers.length)) > 0 ? "UWA_CartTotalAmount" : ""}>{commonfunctions.formatToCurrency((unitPrice * FIQUsers.length) - discountAmount) }</font>) :(0)}
                                            </Grid>
                                            {!!FIQUsers ? (
                                               appliedCouponCode.length > 0 ? (
                                                    <Grid item xs={12} align="left">
                                                        <TextField style={{ width: '30%', margin: '0px', paddingRight: '6px' }}
                                                            name="AppliedCouponCode"
                                                            label="Coupon Code Applied"
                                                            size="small"
                                                            value={appliedCouponCode}
                                                            disabled
                                                        />
                                                        {canEditCart && <Button onClick={clearSavedCouponCode} variant="contained" className="UWA_btn_cancel">Clear</Button>}
                                                    </Grid>
                                                    ) : (
                                                        <Grid item xs={12} align="left">
                                                            <TextField style={{ width: '30%', margin: '0px', paddingRight: '6px' }}
                                                                name="CouponCode"
                                                                label="Enter Coupon Code"
                                                                size="small"
                                                                value={couponCode}
                                                                onChange={handleCouponCodeChange}
                                                                helperText={couponCodeValMsg}
                                                                disabled={!canEditCart}
                                                            />
                                                            {canEditCart && <Button onClick={validateCouponCode} variant="contained" className="UWA_btn_cancel" >Apply</Button>}
                                                         </Grid>
                                                    )
                                                  
                                            ) : (<p></p>)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                               
                                <Grid item xs={12} md={4} className="UWA_PricingLegend UWA_GridPaddingRemover">
                                  
                                    <Grid container spacing={2} className="UWA_GridMarginRemover">
                                        <Grid container spacing={2}>
                                            <Grid item xs={5} align="center">
                                                Number of Users
                                            </Grid>
                                            <Grid item xs={4} align="center">
                                                Monthly Price<sup><font className='UWA_RedAsterisk'>*</font></sup>
                                            </Grid>
                                            <Grid item xs={3} align="center">
                                                Annual Price<sup><font className='UWA_RedAsterisk'>*</font></sup>
                                            </Grid>
                                        </Grid>
                                        {FIQPricingLegend.map((FIQPricingTier, index) => {
                                            return (
                                                <Grid container key={index} spacing={2} className="UWA_PricingLegend">
                                                    <Grid item xs={5} align="center">
                                                        <font >{FIQPricingTier.startingUnit} - {commonfunctions.setUpperLimit(FIQPricingTier.endingUnit)}</font>
                                                    </Grid>
                                                    <Grid item xs={4} align="center">
                                                        <font>{commonfunctions.getMonthlyPrice(FIQPricingTier.price)}</font>
                                                    </Grid>
                                                    <Grid item xs={3} align="center">
                                                        <font>{commonfunctions.formatToCurrency(FIQPricingTier.price)}</font>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} align="right">
                                                <sup><font className='UWA_RedAsterisk'>*  Pricing Per User</font></sup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>       
                            </Grid>
                            
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={2} justifyContent="flex-start">
                            {(currentCartID === "") ?
                                (<> 
                                    <Grid item xs={3} md={3}>
                                        <span className="UWA_FIQCartActions">
                                            <Button size="small" className="UWA_btn_cancelFIQcart" onClick={returnToDashboard}>DASHBOARD</Button>
                                            <Button size="small" className="UWA_btn_createFIQcart" onClick={saveShoppingCart}>SAVE/NEXT</Button>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}></Grid>
                                 
                                    </>
                                    )
                                : (<><span className="UWA_FIQCartActions"></span>

                                        </>
                                        )}
                                <Grid item xs={12}>{shoppingCartValidationErrMsgs && <Alert severity="error"><>{shoppingCartValidationErrMsgs}</></Alert>}</Grid>
                                
                            </Grid>
                            </CardActions>
                    </Card>
                </Col>
            </Row>
            <Dialog
                open={confirmUserDeleteModal}
                onClose={handle_CloseUserDeletionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm User Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Are you sure you want to delete this user from the subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handle_CloseUserDeletionDialog}>No</Button>
                    <Button onClick={deleteUserFromSubscription} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
        
    );
   
}
export default FeasibilityIQCart;