import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProductCard from './ProductCard';
//import { authHeader } from '../_helpers/auth-header';
export class GetAllProduct extends Component {
    //static displayName = GetAllProduct.name;
   
    constructor(props) {
        super(props);
       
        this.state = {
            products: [],
            loading: true,
            cartDetails: this.props.cartContents
        };
        //console.log(this.props);
    }
   
   
    componentDidMount() {
        this.getAllProducts();
    }

    renderProducts(products) {
     //   console.log("this is inside get all products");
     // console.log(this.props.cartContents);

        return (
            <Container>
                <Row>
                   
                    {products.map(product =>
                        <Col md={6} lg={4} style={{ padding: 15 }} key={product.id}>
                            <ProductCard dataParentToChild={product} key={product.id} orientation='vertical' cartContents={this.props.cartContents} updateCartContents={this.props.updateCartContents} PurchaseOnAccountLink="" CheckOutPageLink=""></ProductCard>
                        </Col>
                        )}
                   
                </Row>
            </Container>
           
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderProducts(this.state.products);
    
        return (
            <div>
                <h1 id="tabelLabel" >Get All Products</h1>
                <p>This component demonstrates getting all the active Products from Chargebee.</p>
                {contents}
            </div>
        );
    }

    async getAllProducts() {
        const response = await fetch('api/ChargebeeWidgets/GetAllCharges');
        const data = await response.json();
        this.setState({ products: data, loading: false });
    }
}