import React, { useEffect, useState , useRef } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography, Alert, Button, AlertTitle } from '@mui/material';
import { commonfunctions } from '../../_helpers/commonfunctions';

import axios from 'axios';
export default function RevisionDetails(props) {

    const [defaultErrMsg, setErrMsg] = useState("");
    const [estimateDetails] = useState(props.revisiondetails);
    const GetDateFrom = (itemArray) => {
        var returnDate = '';
        itemArray.map((item) => {
            returnDate = item.dateFrom;
        });
        return returnDate;
    }
    const GetDateTo = (itemArray) => {
        var returnDate = '';
        itemArray.map((item) => {
            returnDate = item.dateTo;
        });
        return returnDate;
    }
    //console.log(props);
    const BuildCreditNoteDetails = (estimateDetails) =>
    {
        if (estimateDetails.creditNoteEstimates.length > 0) {
            return (
               <><Grid item xs={12} align="left">
                    <hr />
                </Grid>
                <Grid item xs={12} align="left">
                        <Alert severity="success">
                            <AlertTitle>Credits</AlertTitle>
                            {estimateDetails.creditNoteEstimates.map((item) => {

                                return (
                                    <React.Fragment key={item.referenceInvoiceId}>
                                        An invoice <b>{item.referenceInvoiceId}</b> is present for the current billing cycle {commonfunctions.formatToDate(props.revisiondetails.dateFrom)}  - {commonfunctions.formatToDate(props.revisiondetails.dateTo)}).
                                        Prorated Adjustment Credits for <b>{commonfunctions.formatToCurrency(item.total)}</b> will be created for the unused period of <b>{commonfunctions.formatToDate(GetDateFrom(item.lineItems))}</b> - <b>{commonfunctions.formatToDate(GetDateTo(item.lineItems))} </b> 
                                        and will be adjusted against the due amount
                                    </React.Fragment>);
                                
                            })}
                        </Alert>
                </Grid>
              </> 
            );
        }
        else {
            return null;
        }
    }
    const handleAbandonRequest = () => {
        props.handleabandonrequest();
    };
    const handleFinalizeRequest = () => {
        props.handlefinalizerequest(props.revisiondetails);
    }
    return (
        <Card square={true} style={{ marginTop: "1px", backgroundColor: "#EBF5FB" }} >
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    Proposed Changes <Button variant="outlined" onClick={handleAbandonRequest}>Abandon Changes</Button> &nbsp; <Button variant="contained" onClick={handleFinalizeRequest}>Finalize</Button>
                </Typography> 
                <Grid container>
                    <Grid item xs={4} align="left" sx={{ borderBottom: 1 }}>
                        Item
                    </Grid>
                    <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                        Original Qty
                    </Grid>
                    <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                        Revised Qty
                    </Grid>
                    <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                        Estimate Qty
                    </Grid>
                    <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                       Unit Price
                    </Grid>
                    <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                        Discount
                    </Grid>
                    <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                         Sub Total
                    </Grid>
                    {estimateDetails && estimateDetails.items.map((item, index) => {
                       // console.log(item);
                        return (<React.Fragment key={item.itemID + "_" + index}>

                            <Grid container >
                                <Grid item xs={4} align="left" sx={{ borderBottom: 1 }}>
                                    {item.itemDescription}
                                    {(item.dateFrom && item.dateTo) ? (
                                        <>
                                            <br /><i>{commonfunctions.formatToDate(item.dateFrom) + '-' + commonfunctions.formatToDate(item.dateTo)}</i>
                                        </>) : ""}
                                </Grid>
                               
                             
                                <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                                    {item.originalQty}
                                </Grid>
                                <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                                    {item.revisedQty}
                                </Grid>
                                <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                                    {item.estimatedQty}
                                </Grid>
                                <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                    {commonfunctions.formatToCurrency(item.unitPrice)}
                                </Grid>
                                <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                                    {item.discountPercentage === null ? commonfunctions.formatToCurrency(item.discountAmt) : commonfunctions.formatToPercentage(item.discountPercentage)}
                                </Grid>
                                <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                                    {commonfunctions.formatToCurrency(item.extendedPrice)}
                                </Grid>
                            </Grid>
                        </React.Fragment>)
                    })}
                    {estimateDetails.roundOffAmount != "0" ? (
                        <>
                            <Grid item xs={10} align="right" >
                                <b>Round off&nbsp;&nbsp;&nbsp;</b>
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderTop: "1px solid #ccc" }}>
                                <b>{commonfunctions.formatToCurrency(estimateDetails.roundOffAmount)}</b>
                            </Grid>
                        </>
                    ): ("") }
                    
                    <Grid item xs={10} align="right" >
                        <b>Total&nbsp;&nbsp;&nbsp;</b>
                    </Grid>
                    <Grid item xs={2} align="right" sx={{ borderTop: "1px solid #ccc" }}>
                        <b>{commonfunctions.formatToCurrency(estimateDetails.subscriptionTotal)}</b>
                    </Grid>
                </Grid>
                {BuildCreditNoteDetails(estimateDetails)}
                <Grid item xs={12} align="left">
                    {defaultErrMsg ? <Alert severity="error" >{defaultErrMsg}</Alert> : ""}
                </Grid>
                <p></p>
            </CardContent>
        </Card>
    
    );
};