import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button, FormControlLabel, Grid, TextField, Switch } from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';

export default function ManageIntegrationMaster(props) {

    const [openManageIntegrationDialog, setOpenManageIntegrationDialog] = useState(false);
    const [currentIntegrationID, setCurrentIntegrationID] = useState(-1);
    const [currentIntegrationRecordData, setCurrentIntegrationRecordData] = useState({
        cbCustomerId: 0,
        createdDate: null,
        eventData: null,
        id: 0,
        integrationName: null,
        lastModifiedDate: null,
        logData: null,
        sourceSystem: null,
        status: null
    });
    const [dialogerrMsg, setDialogErrorMsg] = useState("");
    const [errMsg, setErrorMsg] = useState("");

    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        handleManageIntegrationDialogOpen(params.row.id);
                    }}
                >
                    Edit
                </Button>
            </strong>
        )
    };
    const [isloading, setLoading] = useState(null);
    const [tableData, setTableData] = React.useState([]);

    const handleManageIntegrationDialogOpen = async (currentIntegrationID) => {
        setCurrentIntegrationID(currentIntegrationID);

        setOpenManageIntegrationDialog(true);
    }
    const handleManageIntegrationDialogClose = () => {
        setDialogErrorMsg("");
        setErrorMsg("");
        setCurrentIntegrationID(-1);
        setCurrentIntegrationRecordData([]);
        setOpenManageIntegrationDialog(false);
    }
    const handleManageIntegrationDialogSubmit = async (e) => {

        if (await validateFields()) {
            //check if we are missing any required fields.
            let userInfo = {
                id: currentIntegrationRecordData.id,
                status: currentIntegrationRecordData.status,
                integrationName: currentIntegrationRecordData.integrationName,
                cbCustomerId: currentIntegrationRecordData.cbCustomerId,
                eventData: currentIntegrationRecordData.eventData,
                logData: currentIntegrationRecordData.logData,
                createdDate: currentIntegrationRecordData.createdDate,
                sourceSystem: currentIntegrationRecordData.sourceSystem
            }
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const serviceUrl = endPointURL + 'api/Subscription/UpdateIntegrationRecord';
            await axios.post(serviceUrl, userInfo)
                .then(res => {
                    if (res.status === 200) {
                        setDialogErrorMsg("");
                        loadIntegrationMaster();
                        handleManageIntegrationDialogClose();
                    }

                })
                .catch((error) => {
                    //console.error('Error:', error.response.data);
                    if (error.response.data.message) {
                        setDialogErrorMsg(error.response.data.message);
                    }
                    else if (error.response.data.detail) {
                        setDialogErrorMsg(error.response.data.detail);
                    }
                    else {
                        setDialogErrorMsg(error.response.data.title);
                    }

                });
        }



    }
    const validateFields = async (e) => {
        var success = true;
        setDialogErrorMsg("");
        if (currentIntegrationRecordData.status === '') {
            setDialogErrorMsg("You must enter an status");
            return false;
        }
        if (currentIntegrationRecordData.eventData === '') {
            setDialogErrorMsg("You must enter a eventData");
            return false;
        }
        return success;
    }
    const integrationmasterColumns = [


        {
            field: 'id', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },
        { field: 'status', headerAlign: 'left', align: 'left', headerName: 'Status', width: 110 },
        {
            field: 'cbCustomerId',
            headerName: 'Customer Id',
            width: 120,
            headerAlign: 'right',
            editable: false,
            align: 'right'
        },
        {
            field: 'integrationName',
            headerName: 'Integration Name',
            width: 200,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },
        {
            field: 'eventData',
            headerName: 'Event Data',
            width: 200,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },
        {
            field: 'logData',
            headerName: 'Log Data',
            width: 200,
            headerAlign: 'left',
            editable: false,
            align: 'left'
        },

        {
            field: 'createdDate',
            headerName: 'Create Date',
            type: 'date',
            align: 'left',
            headerAlign: 'left',
            width: 200,
            //valueGetter: (params) => {
            //    return new Date(params.row.createdDate);
            //}
        },
        {
            field: 'lastModifiedDate',
            headerName: 'Last Modified Date',
            type: 'date',
            width: 200,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                if (params.row.lastModifiedDate) {
                    return new Date(params.row.lastModifiedDate);
                }
                else {
                    null;
                }

            }
        },
       
    ];

    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(4)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );

    const loadIntegrationMaster = async () => {



        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Subscription/GetAllIntegrationMasterData';

        //console.log(serviceUrl);
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setTableData(res.data);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log('Error:', error);


            });


    };
    const loadIntegrationDetails = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        const serviceUrl = endPointURL + 'api/Subscription/GetIntegrationRecordDetails?integrationRecordId=' + currentIntegrationID;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setCurrentIntegrationRecordData(res.data);
                    console.log("currentIntegrationRecordData-Object -");
                    console.log(currentIntegrationRecordData);
                    console.log("currentIntegrationRecordData");
                    console.log(currentIntegrationRecordData[0]);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setDialogErrorMsg(error.response.data.message);
                }
                else {
                    setDialogErrorMsg(error.response.data);
                }

            });
    }
    const handleIntegrationRecordChange = async (e) => {
        var elementName = (e.currentTarget.name);
        console.log("elementName" + elementName);
        let tempIntegrationRecordData = { ...currentIntegrationRecordData };
        if (elementName === 'status') {
            tempIntegrationRecordData.status = e.currentTarget.value;
        }
        else if (elementName === 'eventData') {
            tempIntegrationRecordData.eventData = e.currentTarget.value;
        }
        else if (elementName === 'logData') {
            tempIntegrationRecordData.logData = e.currentTarget.value;
        }
        setCurrentIntegrationRecordData(tempIntegrationRecordData);
    }
    useEffect(() => {
        document.title = "Manage Integration Records";
        loadIntegrationMaster();

    }, []);
    
    useEffect(() => {
        if (currentIntegrationID > 0) {

            loadIntegrationDetails();
        }
    }, [currentIntegrationID])
    return (

        <Container>
            <Dialog
                open={openManageIntegrationDialog}
                onClose={handleManageIntegrationDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="Manage Integration Dialog">Manage Integration Records
                </DialogTitle>
                <DialogContent>
                    {isloading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Skeleton variant="rectangular" width="100%" height={360} />
                        </div>
                    ) : (
                            <Grid container sx={{ p: 1 }} spacing={2} >
                                <React.Fragment key={"integration_" + currentIntegrationRecordData.id}>
                                    <Grid item xs={12}>
                                        <TextField style={{ width: '100%' }}
                                            name="integrationName"
                                            label="Integration Name"
                                            size="small"
                                            disabled="true"
                                            required
                                            value={currentIntegrationRecordData.integrationName}
                                         
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField style={{ width: '100%' }}
                                            name="cbCustomerId"
                                            label="Customer Id"
                                            size="small"
                                            disabled="true"
                                            required
                                            value={currentIntegrationRecordData.cbCustomerId}
                                            
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField style={{ width: '100%' }}
                                            name="status"
                                            label="Status"
                                            size="small"
                                            required
                                            value={currentIntegrationRecordData.status}
                                            onChange={handleIntegrationRecordChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField multiline style={{ width: '100%' }}
                                            name="eventData"
                                            label="EventData"
                                            size="large"
                                            required
                                            value={currentIntegrationRecordData.eventData}
                                            onChange={handleIntegrationRecordChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField multiline style={{ width: '100%' }}
                                            name="logData"
                                            label="Log Data"
                                            size="large"
                                            required
                                            value={currentIntegrationRecordData.logData}
                                            onChange={handleIntegrationRecordChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField style={{ width: '100%' }}
                                            name="createdDate"
                                            label="Created Date"
                                            size="small"
                                            disabled="true"
                                            required
                                            value={currentIntegrationRecordData.createdDate}

                                        />
                                    </Grid>

                                </React.Fragment>
                                

                            <Grid item xs={12}>
                                {dialogerrMsg ? <Alert severity="error" >{dialogerrMsg}</Alert> : ""}
                            </Grid>

                        </Grid>
                    )}




                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={handleManageIntegrationDialogClose} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleManageIntegrationDialogSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Row>
                <Col>
                    <h1>Manage Integration Records</h1>
                </Col>
            </Row>
            <Row>
                <Col>

                    <hr />
                    <div style={{ height: 350, width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <PermissionsGate scopes={[SCOPES.canManageIntegrationMaster]} showMessage="false" >
                                <DataGrid
                                    rows={tableData}
                                    rowHeight={39}
                                    columns={integrationmasterColumns}
                                    pageSize={10}
                                    getRowId={row => Math.random()}
                                    components={{
                                        LoadingOverlay: LoadingSkeleton
                                    }}
                                    loading={isloading}
                                    rowsPerPageOptions={[10, 15, 20]}
                                        disableColumnSelector={true} />
                                    </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
                </Col>
            </Row>
        </Container >


    );
}


