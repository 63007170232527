import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { commonfunctions } from '../_helpers/commonfunctions';

const OktaSignOut = () => {
    const { authState, oktaAuth } = useOktaAuth();

    const handleLogout = async () => {
        await commonfunctions.logEvent("LOGOFF", "User Logout", "");
        localStorage.removeItem('UWA_CurrentUserRoles');
        oktaAuth.tokenManager.clear();
        await oktaAuth.signOut({ originalUri: '/login-okta' });
    }

    // onload
    useEffect(() => {
      
        handleLogout();
    }, []);

    if (!authState || !authState.isAuthenticated) {
        return <></>;
    } else {
        return `You're not authenticated`;
    }
}

export default OktaSignOut;