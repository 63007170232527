import React, { useState } from 'react';
import { PERMISSIONS } from "./permission-maps";
import CryptoJS from "crypto-js";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Alert from '@mui/material/Alert';

const hasPermission = ({ permissions, scopes, missingscopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    const missingScopesMap = {};
    missingscopes.forEach((missingScope) => {
        missingScopesMap[missingScope] = true;
    })
    //if user has no permissions then return false. 
    if (!permissions) {
        return false;
    }
    else {
        var missingPermissionExists = permissions.some((permission) => missingScopesMap[permission]);
        var permissionExists = permissions.some((permission) => scopesMap[permission]);
        if (permissionExists && !missingPermissionExists) {
            return true;
        }
        else {
            return false;
        }
//        return permissions.some((permission) => scopesMap[permission]);
    }
    
};

export default  function PermissionsGate({
    showMessage,
    children,
    scopes = [],
    missingscopes = []
}) {
    let userRoles = [];
    const seedKey = process.env.REACT_APP_SEED_KEY;
    //var userRoles = authenticationService.getUserRoles();
    var tempRoles = localStorage.getItem('UWA_CurrentUserRoles') ;
    if (tempRoles) {
        var bytes = CryptoJS.AES.decrypt(tempRoles, seedKey);
        var roles = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        userRoles = roles.split(",");
        //userRoles.push(tempRoles.split(","));
    }
    if (userRoles.length === 0) {
        return <></>;
    }
    //const userRoles = ["MANAGE_SUBSCRIPTIONS","MANAGE_FIQ_CARTS"];
    var permissions = [];
    userRoles.forEach((role) => {
        //get the permissions for each role and lets check if it exists in the permissions array before adding it.
        let rolePermissions = PERMISSIONS[role];
        if (rolePermissions) {
            rolePermissions.forEach((rolePermission) => {
                if (permissions.indexOf(rp => rp === rolePermission) < 0) {
                    permissions.push(rolePermission);
                }
            });
        }
       
        //permissions.push(PERMISSIONS[role]);
    });
    //const permissions = PERMISSIONS[role];

    const permissionGranted = hasPermission({ permissions, scopes, missingscopes });

    if (!permissionGranted) {
        if (showMessage === "false") {
            return <></>
        }
        else {
            return (
                <>
                    <Card sx={{ maxWidth: '85%' }} className="mx-auto my-4">
                        <CardHeader title="Error accessing restricted resource" className="UWA_FIQCardTitle" />
                        <CardContent><Alert severity="error">Oops. It does not look like you have access to the page being requested. If you feel you're getting this message in error, please contact the Administrator of this application. </Alert>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                    <LockOpenIcon sx={{ height: 100, width: 100, color: 'red' }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )
        }
    }
    return <>{children}</>;
}




