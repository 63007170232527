import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import GetShoppingCart   from './GetShoppingCart';
import StepZilla from "react-stepzilla";
import StartStep from './WizardSteps/StartStep';
import AddressInfo from './WizardSteps/AddressInfo';
import TermsAndConditions from './WizardSteps/TermsAndConditions';
import PaymentInfo from './WizardSteps/PaymentInfo';
import { ModalWait } from './WaitModal'
import { Button } from '@mui/material';

export class CheckoutWizard extends Component {
    

    constructor(props) {
        super(props);
        //console.log();
        this.state = { loading: true, invoiceID: '', showWaitModal: false, cartDetails: [this.props.cartContents], refreshCart: this.props.updateCartContents, shoppingCartPageLink: this.props.shoppingcartpage, orderConfirmationMsg: ''};
        this.cartCheckOut = {
            email: '',
            contactPhone: '',
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',
            companyName: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            region: '',
            postalCode: '',
            country: '',
            regionName: '',
            countryName: '',
            cbCCToken: '',
            acceptedTC: false,
            useSameAsBilling:false,
            invoiceID: '',
            couponCode: '',
            appliedCouponCode: '',
            discountAmount: 0
        };
        //console.log(this.state.cartDetails);
    }
    displayThankYouMessage = (paymentStatus) => {
        this.setState({ invoiceID: this.cartCheckOut.invoiceID });
        localStorage.removeItem("UWA_ShoppingCart");
        var orderThankYouMsg = "";
        if (paymentStatus === 'PaymentDue')
        {
            //console.log("HELLO");
            orderThankYouMsg = "<b> Invoice: </b>" + this.cartCheckOut.invoiceID + "<br/><b>Payment Status:</b><font color='red'> Payment is pending.</font></br></br> We have received your payment information. "
            orderThankYouMsg += "Unfortunately, we were unable to process your credit card for the payment amount. We will make another attempt tomorrow. Please make sure you have sufficient funds on your credit card and that the payment amount";
            orderThankYouMsg += " doesn't exceed any transaction limit that may be on your card."
        }
        else {
            orderThankYouMsg = "<b> Invoice: </b>" + this.cartCheckOut.invoiceID + "<br/><b>Payment Status:</b><font color='green'> Payment processed successfully.</font></br></br>Thank you. We have received your payment information. "
            orderThankYouMsg += "A copy of your payment receipt has been sent to the email address you provided - <b>" + this.cartCheckOut.email + "</b></br>";
        }
        this.setState({ orderConfirmationMsg: orderThankYouMsg });
    }
    toggleWaitModal = (val) => {
        this.setState({ showWaitModal: val });
    }
  
    getCartCheckOut() {
        return this.cartCheckOut;
    };

    RedirectToHomePage() {
        window.location.href = "http://universalweather.com";
    };
    updateCartCheckOut(update) {
        if (update == "refreshcart") {
            this.state.refreshCart();
        }
        this.cartCheckOut = {
            ...this.cartCheckOut,
            ...update,
        }
      //  console.log(this.cartCheckOut.DisablePlaceOrderButton);
    };

    componentDidMount() {
        //var x = JSON.parse(localStorage.getItem("shoppingCart"));
        //console.log(x);
        //this.setState({ cartDetails: x });
        this.startCheckoutWizard();
    }
    renderCheckoutWizard() {
        
        const steps =
            [
                { name: 'Welcome', component: <StartStep enablePlaceOrderButton={() => (this.enablePlaceOrderButton())} getCartCheckOut={() => (this.getCartCheckOut())} updateCartCheckOut={(u) => { this.updateCartCheckOut(u) }} toggleWaitModal={(u) => { this.toggleWaitModal(u) }} /> },
                { name: 'Contact Info', component: <AddressInfo getCartCheckOut={() => (this.getCartCheckOut())} updateCartCheckOut={(u) => { this.updateCartCheckOut(u) }} toggleWaitModal={(u) => { this.toggleWaitModal(u) }} /> },
                { name: 'Terms & Conditions', component: <TermsAndConditions getCartCheckOut={() => (this.getCartCheckOut())} updateCartCheckOut={(u) => { this.updateCartCheckOut(u) }} toggleWaitModal={(u) => { this.toggleWaitModal(u) }}/> },
                { name: 'Finalize Order', component: <PaymentInfo getCartCheckOut={() => (this.getCartCheckOut())} updateCartCheckOut={(u) => { this.updateCartCheckOut(u) }} cartContents={this.props.cartContents} displayThankYouMessage={this.displayThankYouMessage} toggleWaitModal={(u) => { this.toggleWaitModal(u) }} /> },
                ]
        return (
            <Container>
                <Row>

                       <Col md={9} style={{ padding: '10' }} className="step-progress">
                        {this.state.showWaitModal ? (
                            <ModalWait
                                handleResponse={this.handleResponse}
                            />
                        ) : null}
                            <StepZilla steps={steps} showNavigation={true} nextButtonCls={"btn btn-next btn-primary pull-right"} backButtonCls="btn btn-prev btn-primary pull-left" />
                        </Col>
                        <Col md={3} style={{ padding: '10' }}>
                            <h3>Order Summary</h3>
                        <GetShoppingCart ReadOnlyMode="true" cartContents={this.props.cartContents} getCartCheckOut={() => (this.getCartCheckOut())} updateCartCheckOut={(u) => { this.updateCartCheckOut(u) }} shoppingCartPageLink={this.state.shoppingCartPageLink}></GetShoppingCart>
                        </Col>
                </Row>
                
            </Container>
        );
    }
    renderEmptyCartMessage() {
        return (
            <Container>
                <Row>
                    <Col md={12} style={{ padding: '10' }}>
                        <h3>Empty Cart Contents</h3>
                        <font>There are no items in your cart to begin the checkout process. Please make sure you have at least one item to purchase before beginning the checkout process.</font>
                    </Col>
                </Row>
            </Container>
            );
    }
    renderThankYouPage() {
        return (
            <Container>
                <Row>
                    <Col md={12} style={{ padding: '10' }}>
                        <h3>Order Confirmation</h3>
                        <font><p dangerouslySetInnerHTML={{ __html: this.state.orderConfirmationMsg }} />
                           </font>
                        <br/>
                        <Button variant="contained" onClick={this.RedirectToHomePage}>Return to Home Page</Button>
                    </Col>
                </Row>
            </Container>
            );
    }
    render() {

        let contents = this.state.loading
            ? <p><em>Loading CheckoutWizard...</em></p>
            : ((this.state.invoiceID === '') ? ((this.props.cartContents.cartLines?.length === 0) ? this.renderEmptyCartMessage() : this.renderCheckoutWizard(this.props.cartContents)) : this.renderThankYouPage());
        return (
            <div>
                {contents}
            </div>
        );
    }

    async startCheckoutWizard() {
     
        this.setState({ loading: false });
    }
}