import React, { useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import LockOpenIcon from '@mui/icons-material/LockOpen';
function ProtectedRoute(props) {
    useEffect(() => {
        if (!props.isLoggedIn) {
            document.title = "Secure Page-Access Restricted";

        }
        

    }, []);
    const loadNotLoggedInMessage = () => {
        if (props.isLoggedIn !== null) {
            return (<>
                <Card sx={{ maxWidth: '85%' }} className="mx-auto my-4">
                    <CardHeader title="Error accessing restricted resource" className="UWA_FIQCardTitle" />
                    <CardContent><Alert severity="error">Oops. It looks like you are trying to access a page that is only accessible to logged in users. Please login first before attempting to access this resource. </Alert>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                <LockOpenIcon sx={{ height: 100, width: 100, color: 'red' }} onClick={() => window.location.replace('/login-okta')} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </>);
        }
        else { return (<></>);}

        //console.log(props.isLoggedIn);
        
    }
    return ((!props.isLoggedIn) ? (<>{loadNotLoggedInMessage()}</>) : props.children);
   
}
export default ProtectedRoute;