import { commonfunctions } from '../_helpers/commonfunctions';

import { shoppingcartService } from '../_services/shoppingcart.service';
export const cartfunctions = {
    getCartID: function () {
        try {
            return JSON.parse(localStorage.getItem("UWA_ShoppingCart"));
        }
        catch
        {
            return this.getNewShoppingCart();
        }
       
    },
    purgeCartID: function () {
        localStorage.setItem("UWA_ShoppingCart", "");
    },
    getCartItemCount: function (cartDetails) {
        
        var cartDetails = null;
       var qty = 0;
        for (var i in cartDetails) {
            qty += cartDetails[i].qty;
        }
        return qty;
    },
    loadCart: async function (cartID) {
            //console.log(cartID);
            await shoppingcartService.getShoppingCart(cartID)
                .then(response => { return response; })
                .catch(error => {
                    if (error.response) {
                        //If the cart doesn't exist then make a request for a new one and return that. 
                        if (error.response.data.detail === "Invalid Cart") {
                            return this.getNewShoppingCart();
                        }
                        else if (error.response.data.detail === "This shopping cart has already been converted to an order. Please contact your Account Manager if you have any questions regarding this cart.") {
                            return this.getNewShoppingCart();
                        }
                        // The request was made and the server responded with a status code that falls out of the range of 200
                        // Something like 4xx or 500
                        //console.log(error.response.data)
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                        console.log(error.request)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message)
                    }
                    console.log(error.config)
                })

            //return results;
      
    },
    //loadCart: async function (cartID) {
    //    if (!!cartID) {
    //        console.log(cartID);
    //        await shoppingcartService.getShoppingCart(cartID)
    //            .then(response => {  return response; })
    //            .catch(error => {
    //                if (error.response) {
    //                    //If the cart doesn't exist then make a request for a new one and return that. 
    //                    if (error.response.data.detail === "Invalid Cart") {
    //                        return  this.getNewShoppingCart();
    //                    }
    //                    else if (error.response.data.detail === "This shopping cart has already been converted to an order. Please contact your Account Manager if you have any questions regarding this cart.") {
    //                        return  this.getNewShoppingCart();
    //                    }
    //                    // The request was made and the server responded with a status code that falls out of the range of 200
    //                    // Something like 4xx or 500
    //                    //console.log(error.response.data)
    //                } else if (error.request) {
    //                    // The request was made but no response was received
    //                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
    //                    console.log(error.request)
    //                } else {
    //                    // Something happened in setting up the request that triggered an Error
    //                    console.log("Error", error.message)
    //                }
    //                console.log(error.config)
    //            })

    //        //return results;
    //    }
    //    else {
    //        this.getNewShoppingCart();
    //       //Save it to browser storage.
    //    }
    
      
        
    //},
    getNewShoppingCart: async function () {
        shoppingcartService.getNewShoppingCart()
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem("UWA_ShoppingCart", decodeURIComponent(JSON.stringify(result.data.cartID)));
                    //Now lets reload and get the cart. 
                    return this.loadCart(result.data.cartID);
                }
            })
            .catch(error => {

            });
    },
    checkCartIsValid: function () {
        //console.log("Checking Valid cart");
        var cartDetails = JSON.parse(localStorage.getItem("shoppingCart"));
        if (cartDetails === null) {
            cartDetails = [];
        }
        else {
            for (var i in cartDetails) {
                shoppingcartService.checkUnitPrice(cartDetails[i].id, cartDetails[i].unit_price).then(
                    response => {
                        //console.log(response);
                        if (response === false) {
                            //remove the item from the shopping cart
                            this.removeItemfromCart(cartDetails[i].id);
                        }
                    });
              
            }
            //reload the cart and return to the user
            cartDetails = JSON.parse(localStorage.getItem("shoppingCart"));
            if (cartDetails === null) {
                cartDetails = [];
            }
        }
        return cartDetails;
    },
    formatCartSubTotals: function (cartDetails) {
        //var cartDetails = this.loadCart();
        var subTotal = 0.00;
        for (var i in cartDetails) {
            subTotal += (cartDetails[i].quantity * cartDetails[i].unitPrice);
        }
        return commonfunctions.formatToCurrency(subTotal);
    },
    addItemToCart: async function (productID, unitPrice) {
        console.log("add item");
        let cartID = this.getCartID();
        return  await shoppingcartService.addItemToShoppingCart(cartID, productID, unitPrice);
     },

    addQtyToCart: async function (productID, unitPrice) {
        let cartID = this.getCartID();
        const results = await shoppingcartService.addItemToShoppingCart(cartID, productID, unitPrice);
        return results;
    },
    saveCartToStorage: function(cartDetails) {
        
        localStorage.setItem("shoppingCart", encodeURIComponent(JSON.stringify(cartDetails)));
       
    },
   
    clearCart: async function () {
        let cartID = this.getCartID();
        const results = await shoppingcartService.clearCart(cartID);
        return results;

    },
    removeQtyfromCart: async function (productID, unitPrice) {
        let cartID = this.getCartID();
        const results = await shoppingcartService.reduceItemFromShoppingCart(cartID, productID, unitPrice);
        return results;
       
    },
    removeItemfromCart: async function (productID) {
        let cartID = this.getCartID();
        const results = await shoppingcartService.removeItemFromShoppingCart(cartID, productID);
        return results;
    },
    getCart: async function ()
    {
        let cartID = this.getCartID();
        return await shoppingcartService.getShoppingCart(cartID);
            
    },
    getCartHeader: async function () {
        let cartID = this.getCartID();
        return await shoppingcartService.getShoppingCartHeader(encodeURIComponent(cartID));

    },
    saveCartEmail: async function (email) {
        let cartID = this.getCartID();
        return await shoppingcartService.saveCartEmail(cartID, email);
    },
    saveCartAddressInfo: async function (companyName, firstName, lastName,
        address1, address2, city, region, postalCode,
        contactPhone, contactFirstname, contactLastName, contactEmail, country) {
        let cartID = this.getCartID();
        return await shoppingcartService.saveCartAddressInfo(cartID, companyName, firstName, lastName,
            address1, address2, city, region, postalCode,
            contactPhone, contactFirstname, contactLastName, contactEmail, country);
    },
    saveCartTCVersion: async function (tcVersion) {
        let cartID = this.getCartID();
        return await shoppingcartService.saveTCVersion(cartID, tcVersion);
    },
};
export default cartfunctions;