import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button, FormControlLabel, Grid, TextField, Switch } from '@mui/material';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   

export default function ManageUsers(props) {
    const [isloading, setLoading] = useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [currentUserData, setCurrentUserData] = React.useState([]);
    const [currentUserRoles, setCurrentUserRoles] = React.useState([]);
    const [errMsg, setErrorMsg] = useState("");
    const [dialogerrMsg, setDialogErrorMsg] = useState("");
    const [openManageUserDialog, setOpenManageUserDialog] = useState(false);
    const [currentUserID, setCurrentUserID] = useState(-1);
    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        handleManageUserDialogOpen(params.row.userID);
                    }}
                >
                    Edit
                </Button>{ }
            </strong>
        )
    };

    const subscriptionColumns = [

        {
            field: 'userID', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },

        {
            field: 'Name',
            headerName: 'Name',
            width: 250,
            headerAlign: 'left',
            editable: false,
            align: 'left',
            valueGetter: (params) => {
                return params.row.fullName;
            }
        },
        {
            field: 'Email',
            headerAlign: 'left',
            align: 'left',
            headerName: 'Email',
            width: 250,
            valueGetter: (params) => {
                return params.row.email;
            }
        },
        {
            field: 'Created',
            headerName: 'Create Date',
            type: 'date',
            width: 100,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return new Date(params.row.created);
            }
        },

        {
            field: 'LastLoginDate',
            headerName: 'Last Login',
            width: 250,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return commonfunctions.formatToDateTime(params.row.lastLoginDate);
            }
        },

    ];
    const loadUserDetails = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        const serviceUrl = endPointURL + 'api/User/GetUserDetails?userID=' + currentUserID;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setCurrentUserData(res.data.user);
                    let tempUserRoles = [...currentUserRoles];
                    for (var i = 0; i < res.data.user[0].userRoles.length; i++) {
                        let userRole = {
                            userID: res.data.user[0].userRoles[i].userID,
                            roleID: res.data.user[0].userRoles[i].roleID
                        };
                        tempUserRoles.push(userRole);
                    }
                    setCurrentUserRoles(tempUserRoles);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setDialogErrorMsg(error.response.data.message);
                }
                else {
                    setDialogErrorMsg(error.response.data);
                }

            });
    }
    const loadUsers = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/User/GetAllUsers';

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data.user);
                    setTableData(res.data.user);

                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }
    const loadRoles = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Role/GetAllRoles';

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data.roles);
                    setRoles(res.data.roles);

                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }
    const handleManageUserDialogOpen = async (currentUserID) => {
        setCurrentUserID(currentUserID);
     
        setOpenManageUserDialog(true);
    }
    const handleCreateNewUser = () => {
        setCurrentUserID(-1);
        if (currentUserID === -1) {
            let userInfo = {
                userID: -1,
                email: "",
                lastName: "",
                firstName: "",
                UserRoles: [],
                Password: "X",
                Username: "X",
                PasswordSalt: "X"
            }
            setCurrentUserData([userInfo]);
        }
        setOpenManageUserDialog(true);
    }
    const handleManageUserDialogClose = () => {
        setDialogErrorMsg("");
        setErrorMsg("");
        setCurrentUserID(-1);
        setCurrentUserData([]);
        setCurrentUserRoles([]);
        setOpenManageUserDialog(false);
    }
    const handleuserInfoChange = (e) => {
        var elementName = (e.currentTarget.name);
        let tempUserInfo = [...currentUserData];
        console.log(elementName);
        if (elementName === 'firstName') {
            tempUserInfo[0].firstName = e.currentTarget.value;
        }
        else if (elementName === 'lastName') {
            tempUserInfo[0].lastName = e.currentTarget.value;
        }
        else if (elementName === 'email') {
            tempUserInfo[0].email = e.currentTarget.value;
        }
        setCurrentUserData(tempUserInfo);
    }
    const handleUserRoleChange = (e) => {
        var v_roleID = parseInt(e.currentTarget.dataset.roleid);
        var v_userID = parseInt(e.currentTarget.dataset.userid);
        var userRole = {
            userID: v_userID,
            roleID: v_roleID
        };
        if (e.target.checked) {
            setCurrentUserRoles([...currentUserRoles, userRole]);
           
        }
        else {
            let tempUserRoles = [...currentUserRoles];
            var index = tempUserRoles.findIndex((item) => item.roleID === v_roleID);
            if (index !== -1) {
                tempUserRoles.splice(index, 1);
                setCurrentUserRoles(tempUserRoles);
            }
            //if (currentUserRoles.indexOf(userRole.roleID) > -1) {
            //    setCurrentUserRoles((existingVal) =>
            //        existingVal.filter((existingItem) => existingItem !== userRole.roleID)
            //    );
              
            //}
        }
        console.log(currentUserRoles);
    }
    const validateFields = async (e) => {
        var success = true;
        setDialogErrorMsg("");
        if (currentUserData[0].email === '') {
            setDialogErrorMsg("You must enter an email address");
            return false;
        }
        if (currentUserData[0].lastName === '') {
            setDialogErrorMsg("You must enter a last name");
            return false;
        }
        if (currentUserData[0].firstName === '') {
            setDialogErrorMsg("You must enter a first name");
            return false;
        }
        return success;
    }
    const handleManageUserDialogSubmit = async(e) => {

        if (await validateFields()) {
            //check if we are missing any required fields.
            let userInfo = {
                userID: currentUserData[0].userID,
                email: currentUserData[0].email,
                lastName: currentUserData[0].lastName,
                firstName: currentUserData[0].firstName,
                UserRoles: currentUserRoles,
                Password: "X",
                Username: "X",
                PasswordSalt: "X"
            }
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const serviceUrl = endPointURL + 'api/User/UpdateUser';
            await axios.post(serviceUrl, userInfo)
                .then(res => {
                    if (res.status === 200) {
                        setDialogErrorMsg("");
                        loadUsers();
                        loadRoles();
                        handleManageUserDialogClose();
                    }

                })
                .catch((error) => {
                    //console.error('Error:', error.response.data);
                    if (error.response.data.message) {
                        setDialogErrorMsg(error.response.data.message);
                    }
                    else if (error.response.data.detail) {
                        setDialogErrorMsg(error.response.data.detail);
                    }
                    else {
                        setDialogErrorMsg(error.response.data.title);
                    }

                });
        }
       

      
    }
    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(10)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );
   
    useEffect(() => {
        loadUsers();
        loadRoles();
  
    }, []);

    useEffect(() => {
        if (currentUserID > 0) {

            loadUserDetails();
        }
        else {
            //Lets create a dummy record to be used to update this new user. 
            let userInfo = {
                userID: -1,
                email: "",
                lastName: "",
                firstName: "",
                UserRoles: [],
                Password: "X",
                Username: "X",
                PasswordSalt: "X"
            }
            setCurrentUserData([userInfo]);
        }
    }, [currentUserID])
    
    return (
        <Container>
            <Dialog
                open={openManageUserDialog}
                onClose={handleManageUserDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="Manage User Dialog">Manage User Details ({currentUserID})
                </DialogTitle>
                <DialogContent>
                    {isloading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Skeleton variant="rectangular" width="100%" height={360} />
                        </div>
                    ) : (
                            <Grid container sx={{ p: 1 }} spacing={2} >
                                {currentUserData && currentUserData.map((item, index) => {
                                    return (

                                        <React.Fragment key={"user_" + item.userID}>
                                            <Grid item xs={12}>
                                                <TextField style={{ width: '100%' }}
                                                    name="firstName"
                                                    label="First Name"
                                                    size="small"
                                                    required
                                                    value={item.firstName}
                                                    onChange={handleuserInfoChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField style={{ width: '100%' }}
                                                    name="lastName"
                                                    label="Last Name"
                                                    size="small"
                                                    required
                                                    value={item.lastName}
                                                    onChange={handleuserInfoChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField style={{ width: '100%' }}
                                                    name="email"
                                                    label="Email"
                                                    size="small"
                                                    required
                                                    value={item.email}
                                                    onChange={handleuserInfoChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container sx={{ p: 1.5 }} spacing={2}>

                                                    {roles && roles.map((role) => {
                                                        var roleExists = false;
                                                        for (var i = 0; i < currentUserRoles.length; i++) {
                                                            if (currentUserRoles[i].roleID === role.roleID) {
                                                                roleExists = true;
                                                                break;
                                                            }
                                                        }

                                                        return (

                                                            <React.Fragment key={role.roleID}>
                                                                <Grid item xs={6} >
                                                                    <FormControlLabel control={<Switch checked={roleExists} onChange={handleUserRoleChange} inputProps={{ 'data-roleid': role.roleID, 'data-userid': item.userID }} />} label={role.name} />
                                                                </Grid>
                                                            </React.Fragment>

                                                        )

                                                    })}

                                                </Grid>
                                            </Grid>

                                        </React.Fragment>

                                    )


                                })}
                              
                                <Grid item xs={12}>
                                    {dialogerrMsg ? <Alert severity="error" >{dialogerrMsg}</Alert> : ""}
                                </Grid>

                            </Grid>
                    )}
                   
    
                    

                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={handleManageUserDialogClose} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleManageUserDialogSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
                    <PermissionsGate scopes={[SCOPES.canManageUsers]} showMessage="false" >
                        <Row>
                            <Col style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'right', flex: 1
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="UWA_btn_Submit"
                                    style={{ marginLeft: 16, marginBottom: 3 }}
                                    onClick={handleCreateNewUser}
                                >  Create New User
                                </Button>
                          
                            </Col>
                        </Row>
                   </PermissionsGate>
             <Row>
                <Col>
                    <div style={{ height: '35vh', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>

                                <DataGrid
                                    rows={tableData}
                                    rowHeight={39}
                                    columns={subscriptionColumns}
                                    pageSize={10}
                                    getRowId={row => Math.random()}
                                    components={{
                                        LoadingOverlay: LoadingSkeleton
                                    }}
                                    loading={isloading}
                                    rowsPerPageOptions={[10]}
                                    disableColumnSelector={true} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
                </Col>
            </Row>
        </Container>
    );
}