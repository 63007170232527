import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import  Alert  from '@mui/material/Alert';
import axios from 'axios';

import Report_NewSubscriptions from './Reports/Report_NewSubscriptions';
 
export default function SubscriptionDashboard(props) {
    const [dashboardErrMsg, setDashboardErrorMsg] = useState("");
  

 
  
    useEffect(() => {
        document.title = "Subscription Dashboard";
   
    },[]);
   
   
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Subscription Dashboard</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Report_NewSubscriptions />
                </Col>
            </Row>
          
            <Row>
                <Col>
                    {dashboardErrMsg ? <Alert severity="error" >{dashboardErrMsg}</Alert> : ""}
                </Col>
            </Row>
          
        </Container>
        );
}