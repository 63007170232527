import React, { useState } from 'react';
import { FormControlLabel, Grid, TextField, Switch } from '@mui/material';

export default function UserForm(props) {
    const { mode, itemDetails, subscriptionDetails, ...onValidate } = props;
    //console.log(props);
    const handleChange = async (e) => {
        props.onvalidate(e);
    }
    const handleSelectedAddonChange = async (e) => {
        props.onvalidateaddonchange(e);
    }
    const LoadAddOnSwitch = (props) => {
        let addonChecked = false;
        props.addonDetail.addOns && props.addonDetail.addOns.map((item) => {
            //console.log(item);
            if (props.subscriptionItem.itemID === item.id) {
                addonChecked = (true);
            }
        })

        if (addonChecked) {
            return (
                <Grid item xs={12} >

                    <FormControlLabel control={<Switch defaultChecked onChange={handleSelectedAddonChange} inputProps={{ 'data-addonid': props.subscriptionItem.itemID, 'data-originalkey': itemDetails.originalKey }} />} label={props.subscriptionItem.itemID} />
                </Grid>
            )
        }
        else {
            return (
                < Grid item xs={12} >

                    <FormControlLabel control={<Switch onChange={handleSelectedAddonChange} inputProps={{ 'data-addonid': props.subscriptionItem.itemID, 'data-originalkey': itemDetails.originalKey }} />} label={props.subscriptionItem.itemID} />
                </Grid >)
        }

    }
    if (mode === "edit") {
        return (

            <>     <Grid container sx={{ p: 1.5 }} spacing={2}>
                    <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="userName"
                        label="Username"
                        size="small"
                        required
                        disabled
                        
                        defaultValue={itemDetails.key}
                        inputProps={{ 'maxLength': 15, 'data-originalkey': itemDetails.originalKey, 'data-fieldname': 'userName' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="firstName"
                        label="First Name"
                        size="small"
                         onChange={handleChange}
                        defaultValue={itemDetails.firstName}
                        inputProps={{ 'data-fieldname': 'firstName' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="lastName"
                        label="Last Name"
                        size="small"
                         onChange={handleChange}
                        defaultValue={itemDetails.lastName}
                        inputProps={{ 'data-fieldname': 'lastName' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="email"
                        label="Email"
                        size="small"
                        onChange={handleChange}
                        defaultValue={itemDetails.email}
                        inputProps={{ 'data-fieldname': 'email' }}
                    />
                </Grid>
                {subscriptionDetails.items && subscriptionDetails.items.map((item) => {
                    let addOnDetails = {
                        addonDetail: itemDetails,
                        subscriptionItem: item
                    }
                    if (item.itemType === "Addon") {
                        return (<React.Fragment key={item.itemID}><LoadAddOnSwitch {...addOnDetails} /></React.Fragment>);
                    }

                })}
                <Grid item xs={12}>
                    <br /><br />
                </Grid>
            </Grid>
            </>
        )
    }
    else {
        return (

            <>  <Grid container sx={{ p: 1.5 }} spacing={2}>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="userName"
                        label="Username"
                        size="small"
                        required
                        onChange={handleChange}
                        inputProps={{ 'maxLength': 15, 'data-originalkey': '', 'data-fieldname': 'userName' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField style={{ width: '100%' }}
                        name="firstName"
                        label="First Name"
                        size="small"
                        onChange={handleChange}
                        inputProps={{ 'data-fieldname': 'firstName' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField style={{ width: '100%' }}
                        name="lastName"
                        label="Last Name"
                        size="small"
                        onChange={handleChange}
                        inputProps={{ 'data-fieldname': 'lastName' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="email"
                        label="Email"
                        size="small"
                        onChange={handleChange}
                        inputProps={{ 'data-fieldname': 'email' }}
                    />
                </Grid>
               
                <Grid item xs={12}>
                    <br /><br />
                </Grid>
            </Grid>
            </>
        )
    }
}