/// <reference path="shoppingcart.js" />
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FeasibilityIQCart from './FIQCartManagement/FeasibilityIQCart';
import { useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

 function ManageFeasibilityIQCart(props) {
     const [currentCartID, setCurrentCartID] = useState(new URLSearchParams(useLocation().search).get('CartID'));
     const [bearerToken, setBearerToken] = useState("");
     const { authState, oktaAuth } = useOktaAuth();
   
     const getBearerToken = () => {
         if (!authState || !authState.isAuthenticated) {
             // When user isn't authenticated dont allow the user to get to this page.
             //setUserInfo(null);
             // console.log("DUMMY");
         } else {
             oktaAuth.getUser().then((info) => {
                 return authState.accessToken.accessToken;
             });
         }
     }
     useEffect(() =>{
         document.title = "Manage FIQ Shopping Cart";
         
         if (!authState || !authState.isAuthenticated) {
             // When user isn't authenticated dont allow the user to get to this page.
             //setUserInfo(null);
             // console.log("DUMMY");
         } else {
             oktaAuth.getUser().then((info) => {
                 // console.log(JSON.parse(atob(authState.accessToken.accessToken.split('.')[1])));
                 setBearerToken(authState.accessToken.accessToken);
                 //setUserInfo(info);
             });
         }
     }, [authState, oktaAuth]); // Update if authState changes
        return (
            <Container>
                <Row>
                    <Col>
                        <h3>Manage Feasibility IQ Cart</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FeasibilityIQCart currentCart={currentCartID} bearerToken={bearerToken} mode="manage"/>
                    </Col>
                </Row>
            </Container>
        );
   
};
export default ManageFeasibilityIQCart;