import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, Skeleton } from '@mui/material';
import { commonfunctions } from '../../_helpers/commonfunctions';

export default function SubscriptionInfoTile(props) {
    const { loading, ...subscripinfo } = props;
    //console.log( props);
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width={210} height={60} />
            </div>
        );
    }
   
    return (
        <Card square={true} style={{ marginTop: "1px" }} >
            <CardContent className="UWA_SubscriptionList_Item">
                <Grid {...subscripinfo} container justify="space-between">
                    <Grid item xs={12} align="right" className="UWA_SubscriptionList_Item_ID">
                        {subscripinfo.subscripinfo.id}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>
                            {subscripinfo.subscripinfo.subscription_plan_id}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} align="right">
                        <Typography color={subscripinfo.subscripinfo.status == 'Cancelled' ? 'red' : (subscripinfo.subscripinfo.status == 'Active' ? '#2268e0' : 'green')}>
                            {subscripinfo.subscripinfo.status}
                        </Typography>
                    </Grid>
                   
                    <Grid item xs={6}>
                        <Typography color="textSecondary">
                            Start Date:
                            {commonfunctions.formatToDate(subscripinfo.subscripinfo.start_date)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Typography color="textSecondary" >
                          
                            {commonfunctions.formatToCurrency(subscripinfo.subscripinfo.subscription_total)}
                        </Typography>
                    </Grid>
                  
                </Grid>

            </CardContent>
        </Card>
        );
}