import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, TextField, Switch} from '@mui/material';

export default function RegistryForm(props) {
    const { mode, itemDetails, subscriptionDetails, ...onValidate } = props;
   // console.log(props);
    const handleChange = async (e) => {
        props.onvalidate(e);
    }
    const handleSelectedAddonChange = async (e) => {
        //setAddOnChecked(!e.target.checked);
       props.onvalidateaddonchange(e);
    }
    const LoadAddOnSwitch = (props) => {
        let addonChecked = false;
        props.addonDetail.addOns && props.addonDetail.addOns.map((item) => {
            //console.log(item);
            if (props.subscriptionItem.itemID === item.id) {
                addonChecked = (true);
            }
        })
       
        if (addonChecked) {
            return (
                <Grid item xs={12} >

                    <FormControlLabel control={<Switch defaultChecked onChange={handleSelectedAddonChange} inputProps={{ 'data-addonid': props.subscriptionItem.itemID, 'data-originalkey': itemDetails.originalKey }} />} label={props.subscriptionItem.itemID} />
                </Grid>
            )
        }
        else {
            return (
            < Grid item xs = { 12} >

                    <FormControlLabel control={<Switch onChange={handleSelectedAddonChange} inputProps={{ 'data-addonid': props.subscriptionItem.itemID, 'data-originalkey': itemDetails.originalKey }} />} label={props.subscriptionItem.itemID} />
            </Grid >)
        }
         
    }
    if (mode === "edit") {
        return (

            <>     <Grid container sx={{ p: 0.5 }}>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="registryName"
                        label="Registry/Tail #"
                        size="small"
                        required
                        disabled
                        defaultValue={itemDetails.key}
                        inputProps={{ 'data-originalkey': itemDetails.originalKey }}
                        inputProps={{ maxLength: 8 }}
                    />
                </Grid>
                {subscriptionDetails.items && subscriptionDetails.items.map((item) => {
                    let addOnDetails = {
                        addonDetail: itemDetails,
                        subscriptionItem: item
                    }
                    if (item.itemType === "Addon") {
                        return (<React.Fragment key={item.itemID}><LoadAddOnSwitch {...addOnDetails} /></React.Fragment>);
                    }

                })}
                <Grid item xs={12}>
                    <br /><br />
                </Grid>
            </Grid>
            </>
        )
    }
    else {
        return (

            <>  <Grid container sx={{ p: 0.5 }}>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="registryName"
                        label="Registry/Tail #"
                        size="small"
                        required
                        onChange={handleChange}
                    />
                </Grid>
               
                <Grid item xs={12}>
                    <br /><br />
                </Grid>
            </Grid>
            </>
        )
    }
   ;
}