import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from "axios";
import './web.config';

axios.interceptors.request.use((req) => {
   // console.log(req);
    if (localStorage.getItem('okta-token-storage') !== '{}') {
        //console.log(localStorage.getItem('okta-token-storage'));
        const token = JSON.parse(localStorage.getItem('okta-token-storage'));
        req.headers.Authorization = token ? `Bearer ${token.accessToken.accessToken}` : '';
        //req.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    }
   
    return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

let baseUrl;
try {
    baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    if (baseUrl === null) {
        baseUrl = document.getElementById('UWAChargebeeWidgets').src;
    }
    
}
catch {
    baseUrl = document.getElementById('UWAChargebeeWidgets').src;
    
}
const uwa_widgets = document.querySelectorAll('.uwa_widgets');
uwa_widgets.forEach(Div => {
    ReactDOM.render(
        <React.StrictMode>
            <App domElement={Div} baseUrl={baseUrl}/>
         </React.StrictMode>,
        Div);
});




