import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Country, State } from 'country-state-city';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import cartfunctions from '../../_helpers/cartfunctions';
import Alert from '@mui/material/Alert';

export default class AddressInfo extends Component {



    constructor(props) {
        super(props);
        this.state = {
            billEmail: props.getCartCheckOut().email,
            companyName: props.getCartCheckOut().companyName,
            firstName: props.getCartCheckOut().firstName,
            lastName: props.getCartCheckOut().lastName,
            address1: props.getCartCheckOut().address1,
            address2: props.getCartCheckOut().address2,
            city: props.getCartCheckOut().city,
            region: props.getCartCheckOut().region,
            postalCode: props.getCartCheckOut().postalCode,
            country: (props.getCartCheckOut().country === '' ? 'US' : props.getCartCheckOut().country),
            contactFirstName: props.getCartCheckOut().contactFirstName,
            contactLastName: props.getCartCheckOut().contactLastName,
            contactPhone: props.getCartCheckOut().contactPhone,
            contactEmail: props.getCartCheckOut().contactEmail,
            useSameAsBilling: props.getCartCheckOut().useSameAsBilling,
            validationErrMsg: 'The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 to process your order and let them know you could not proceed with the checkout process. ',
            contactFirstNameRefresh: '',
            contactLastNameRefresh: '',
            contactEmailRefresh: '',
            disableCompanyName: false
        };
        //console.log(this.state.contactPhone);

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);


    }

    componentDidMount() {
        this.props.toggleWaitModal(true);
        cartfunctions.getCartHeader().then(result => {
            console.log(result);
            if (!!result.companyName) {
                this.setState({ companyName: result.companyName });
                this.setState({ disableCompanyName: true});
            };
            if (!!result.soldToFirstName) {
                this.setState({ contactFirstName: result.soldToFirstName });
            };
            if (!!result.soldToLastName) {
                this.setState({ contactLastName: result.soldToLastName });
            };
            if (!!result.soldToPhone) {
                this.setState({ contactPhone: result.soldToPhone });
            }
           
            if (!!result.billToEmail) {
                this.setState({ contactEmail: result.billToEmail });
            }
            if (!!result.billToFirstName) {
                this.setState({ firstName: result.billToFirstName });
            }
            if (!!result.billToLastName) {
                this.setState({ lastName: result.billToLastName });
            }

            if (!!result.billToAddr1) {
                this.setState({ address1: result.billToAddr1 });
            }
            if (!!result.billToAddr2) {
                this.setState({ address2: result.billToAddr2 });
            }
            if (!!result.billToCity) {
                this.setState({ city: result.billToCity });
            }
            if (!!result.billToState) {
                this.setState({ region: result.billToState });
            }
            if (!!result.billToPostalCode) {
                this.setState({ postalCode: result.billToPostalCode });
            }
            if (!!result.billToCountry) {
                this.setState({ country: result.billToCountry });
            }

        });
        this.props.toggleWaitModal(false);
    }

    componentWillUnmount() { }
    isValidated = async () => {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getCartCheckOut().companyName !== userInput.companyName ||
                this.props.getCartCheckOut().firstName !== userInput.firstName ||
                this.props.getCartCheckOut().lastName !== userInput.lastName ||
                this.props.getCartCheckOut().address1 !== userInput.address1 ||
                this.props.getCartCheckOut().address2 !== userInput.address2 ||
                this.props.getCartCheckOut().city !== userInput.city ||
                this.props.getCartCheckOut().region !== userInput.region ||
                this.props.getCartCheckOut().postalCode !== userInput.postalCode ||
                this.props.getCartCheckOut().contactPhone !== userInput.contactPhone ||
                this.props.getCartCheckOut().contactFirstName !== userInput.contactFirstName ||
                this.props.getCartCheckOut().contactLastName !== userInput.contactLastName ||
                this.props.getCartCheckOut().contactEmail !== userInput.contactEmail ||
                this.props.getCartCheckOut().country !== userInput.country) {
                this.props.updateCartCheckOut({ ...userInput });
                isDataValid = true;
               
            }
            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }
        //Now lets perform the DPS check
        if (isDataValid) {
            this.props.toggleWaitModal(true);
            cartfunctions.saveCartAddressInfo(userInput.companyName, userInput.firstName, userInput.lastName,
                userInput.address1, userInput.address2, userInput.city, userInput.region, userInput.postalCode,
                userInput.contactPhone, userInput.contactFirstName, userInput.contactLastName, userInput.contactEmail, userInput.country
            );
            var DPSErrorDiv = document.getElementsByName('DPSValidationMsg');
            //var DPSErrorMsg = document.getElementById('dpsValidationErrMsg');
            try {
                // let DPSCheckResult = true;
                let DPSCheckResult = await this.passesDPSCheck(this.props.getCartCheckOut);
                //let DPSCheckResult = false;
                //console.log(DPSCheckResult);
                if (DPSCheckResult === true) {
                    DPSErrorDiv[0].style.display = "none";
                    isDataValid = true;

                }
                else {
                    DPSErrorDiv[0].style.display = "block";
                    isDataValid = false;
                }

            }
            catch (error) {
                DPSErrorDiv[0].style.display = "block";
                console.log("failed");
                isDataValid = false;
            }
            this.props.toggleWaitModal(false);

        }

        return isDataValid;
    }
    //passesDPSCheck = async (cartCheckOutObject) => {
    //    var success = true;
    //    var endPointURL = process.env.REACT_APP_API_URL;
    //    if (process.env.REACT_APP_API_URL === undefined) {
    //        endPointURL = "/";
    //    }
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            FirstName: cartCheckOutObject().firstName,
    //            LastName: cartCheckOutObject().lastName,
    //            Company: cartCheckOutObject().companyName,
    //            Address1: cartCheckOutObject().address1,
    //            Address2: cartCheckOutObject().address2,
    //            City: cartCheckOutObject().city,
    //            State: cartCheckOutObject().region,
    //            PostalCode: cartCheckOutObject().postalCode,
    //            Country: cartCheckOutObject().country,
    //            EmailAddress: cartCheckOutObject().email
    //        })
    //    };

    //    const serviceUrl = endPointURL + 'api/MKDenialService/GetMKDenialSearchResults';
    //    const response = await fetch(serviceUrl, requestOptions);
    //    if (!response.ok) {
    //        return false;
    //    }
    //    return await response.json();


    //}
    //CheckDPSValidation(responseJson) {
    //    var resultsObj = JSON.parse(responseJson);
    //    if (resultsObj.Hits.length > 0) {
    //        return false;
    //    }
    //    return true;
    //}
    passesDPSCheck = async (cartCheckOutObject) => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                FirstName: cartCheckOutObject().firstName,
                LastName: cartCheckOutObject().lastName,
                Company: cartCheckOutObject().companyName,
                Address1: cartCheckOutObject().address1,
                Address2: cartCheckOutObject().address2,
                City: cartCheckOutObject().city,
                State: cartCheckOutObject().region,
                PostalCode: cartCheckOutObject().postalCode,
                Country: cartCheckOutObject().country,
                EmailAddress: cartCheckOutObject().email
            })
        };
        try {
            const serviceUrl = endPointURL + 'api/MKDenialService/GetMKDenialSearchResults';
            const response = await fetch(serviceUrl, requestOptions);
            const json = await response.json();
            //console.log(json);
            let resultsObj = JSON.parse(json);
            if (resultsObj.Hits != null) {
                if (resultsObj.Hits.length > 0) {
                    var validationErrMsg = 'We were unable to process your information. Please re-enter your name and, if applicable, your company name exactly as it appears on your credit card. If you continue to receive this error, please contact Universal Client Relations at +1 (713) 943-4699 and provide the following reference number: ';
                    this.setState({ validationErrMsg: validationErrMsg + '[' + resultsObj.SearchRequest.Reference + ']' });
                    return false;
                }

            }
            return true;
        }
        catch (error) {
            return false;
        }


    }
    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }
    handleChange = async (e) => {
        if (e.target.name === 'useSameAsBilling') {
            await this.setState({ [e.target.name]: e.target.checked });
            if (this.state.useSameAsBilling) {

                this.setState({ contactFirstName: this.state.firstName });
                this.setState({ contactLastName: this.state.lastName });
                this.setState({ contactEmail: this.state.billEmail });
                this.state.contactFirstNameValMsg = '';
                this.state.contactLastNameValMsg = '';
                this.state.contactEmailValMsg = '';
            }
            else {
                this.setState({ contactFirstName: '' });
                this.setState({ contactLastName: '' });
                this.setState({ contactEmail: '' });
            }
            await this.setState({ contactFirstNameRefresh: Math.random() });
            await this.setState({ contactLastNameRefresh: Math.random() });
            await this.setState({ contactEmailRefresh: Math.random() });
            return;
        }
        var helperVarName = e.target.name + 'ValMsg';
        this.setState({ [helperVarName]: '' });
        if (e.target.name === 'contactFirstName' || e.target.name === 'contactLastName') {
            this.setState({ [e.target.name]: e.target.value });
            return;
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }


        if (this.state.useSameAsBilling) {
            this.setState({ contactFirstName: this.state.firstName });
            this.setState({ contactLastName: this.state.lastName });
            this.setState({ contactEmail: this.state.billEmail });
            this.setState({ contactFirstNameRefresh: this.state.firstName });
            this.setState({ contactLastNameRefresh: this.state.lastName });
            this.setState({ contactEmailRefresh: this.state.billEmail });
            this.state.contactFirstNameValMsg = '';
            this.state.contactLastNameValMsg = '';
            this.state.contactEmailValMsg = '';
            return;
        }

        if (e.target.name === 'country') {
            this.setState({ 'countryName': Country.getCountryByCode(e.target.value).name });
        }
        if (e.target.name === 'region') {
            this.setState({ 'stateValMsg': '' });
            var stateName = State.getStateByCodeAndCountry(e.target.value, this.state.country).name;
            // console.log(stateName);
            this.setState({ 'regionName': stateName });
        }



    }
    _validateData(data) {
        console.log(data);
        return {
            firstNameVal: (data.firstName !== undefined && data.firstName !== '' && /^[a-zA-Z '.-]*$/.test(data.firstName)), // required: anything besides N/A
            lastNameVal: (data.lastName !== undefined && data.lastName !== '' && /^[a-zA-Z '.-]*$/.test(data.lastName)), // required: anything besides N/A
            address1Val: (data.address1.trim() !== undefined && data.address1.trim() !== ''), // required: anything besides N/A
            cityVal: (data.city !== undefined && data.city !== ''), // required: anything besides N/A
            stateVal: (data.region !== undefined && data.region !== ''), // required: anything besides N/A
            postalCodeVal: (data.postalCode !== undefined && data.postalCode !== ''), // required: anything besides N/A
            countryVal: (data.country !== undefined && data.country !== ''), // required: anything besides N/A
            contactPhoneVal: (data.contactPhone !== undefined && data.contactPhone !== ''), // required: anything besides N/A
            contactFirstNameVal: (data.contactFirstName !== undefined && data.contactFirstName !== '' && /^[a-zA-Z '.-]*$/.test(data.contactFirstName)), // required: anything besides N/A
            contactLastNameVal: (data.contactLastName !== undefined && data.contactLastName !== '' && /^[a-zA-Z '.-]*$/.test(data.contactLastName)), // required: anything besides N/A
            contactEmailVal: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.contactEmail)


        }
    }

    _validationErrors(val) {
        const errMsgs = {
            firstNameValMsg: val.firstNameVal ? '' : 'First Name field is required',
            lastNameValMsg: val.lastNameVal ? '' : 'Last Name field is required',
            address1ValMsg: val.address1Val ? '' : 'Line 1 of the Address field is required',
            cityValMsg: val.cityVal ? '' : 'City field is required',
            stateValMsg: val.stateVal ? '' : 'Province/State field is required',
            postalCodeValMsg: val.postalCodeVal ? '' : 'Zip/Postal Code field is required',
            countryValMsg: val.countryVal ? '' : 'Country field is required',
            contactPhoneValMsg: val.contactPhoneVal ? '' : 'Contact Phone is required',
            contactFirstNameValMsg: val.contactFirstNameVal ? '' : 'Contact First name is required',
            contactLastNameValMsg: val.contactLastNameVal ? '' : 'Contact Last name is required',
            contactEmailValMsg: val.contactEmailVal ? '' : 'Contact Email field value is invalid'
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            companyName: this.state.companyName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            region: this.state.region,
            postalCode: this.state.postalCode,
            country: this.state.country,
            contactPhone: this.state.contactPhone,
            contactFirstName: this.state.contactFirstName,
            contactLastName: this.state.contactLastName,
            contactEmail: this.state.contactEmail,
            useSameAsBilling: this.state.useSameAsBilling
        };
    }


    render() {
        // explicit class assigning based on validation

        return (
            <div className="step addressInfo">
                <Grid container spacing={2} paddingBottom={4} paddingRight={4}>
                    <Grid item xs={12} md={12}>
                        <label className="col-md-12 control-label">
                            <h2>Contact and Billing Information</h2>
                        </label>
                    </Grid>

                    <Grid item col={12}>
                        <label className="col-md-12 control-label">
                            <h4>Billing Info</h4>
                        </label>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <hr />
                        Please enter the billing information for the credit card you will be using to pay for this order.
                    </Grid>
                    <Grid item xs={12}>
                        <TextField style={{ width: '100%' }}
                            name="companyName"
                            label="Company Name"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.companyName}
                            disabled={this.state.disableCompanyName}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField
                            required
                            label="First Name"
                            size="small"
                            name="firstName"
                            style={{ width: '100%' }}
                            onChange={this.handleChange}
                            value={this.state.firstName}
                            helperText={this.state.firstNameValMsg}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField
                            required
                            name="lastName"
                            label="Last Name"
                            size="small"
                            onChange={this.handleChange}
                            style={{ width: '100%' }}
                            value={this.state.lastName}
                            helperText={this.state.lastNameValMsg}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField style={{ width: '100%' }}
                            required
                            name="address1"
                            label="Address Line 1"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.address1}
                            helperText={this.state.address1ValMsg}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField style={{ width: '100%' }}
                            name="address2"
                            label="Address Line 2"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.address2}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField style={{ width: '100%' }}
                            name="city"
                            required
                            label="City"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.city}
                            helperText={this.state.cityValMsg}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField style={{ width: '100%' }}
                            name="region"
                            required
                            select
                            label="Province/State/Region"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.region}
                            helperText={this.state.stateValMsg}
                        >
                            {State.getStatesOfCountry((this.state.country)).map((option) => (
                                <MenuItem key={option.isoCode} value={option.isoCode}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField style={{ width: '100%' }}
                            name="postalCode"
                            required
                            label="Zip/Postal Code"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.postalCode}
                            helperText={this.state.postalCodeValMsg}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} >
                        <TextField style={{ width: '100%' }}
                            name="country"
                            required
                            label="Country"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.country}
                            select
                            helperText={this.state.countryValMsg}
                        >
                            {/*{Country.getAllCountries().map((option) => (*/}
                              
                            {/*    <MenuItem key={option.isoCode} value={option.isoCode}>*/}
                            {/*        {option.name}*/}
                            {/*    </MenuItem>*/}
                            {/*))}*/}
                            {Country.getAllCountries().map(option => {
                                if (option.isoCode !== 'EH') {
                                    return <MenuItem key={option.isoCode} value={option.isoCode}>
                                        {option.name}
                                    </MenuItem>;
                                }
                            })}
                            
                               
                        </TextField>
                    </Grid>
                    <Grid item col={12}>
                        <label className="col-md-12 control-label">
                            <h4>Contact Info</h4>
                        </label>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <hr />
                        Please enter contact information in case we need to get in touch with someone regarding this order.
                        <FormGroup >
                            <FormControlLabel control={<Switch name='useSameAsBilling' onChange={this.handleChange} checked={this.state.useSameAsBilling} />} label="Use same as Billing" />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={6} md={6} >
                        <span key={this.state.contactFirstNameRefresh}>
                            <TextField
                                required
                                label="Contact - First Name"
                                size="small"
                                name="contactFirstName"
                                style={{ width: '100%' }}
                                onChange={this.handleChange}
                                value={this.state.contactFirstName}
                                disabled={this.state.useSameAsBilling}
                                helperText={this.state.contactFirstNameValMsg}

                            />
                        </span>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <span key={this.state.contactLastNameRefresh}><TextField
                            required
                            name="contactLastName"
                            label="Contact - Last Name"
                            size="small"
                            onChange={this.handleChange}
                            style={{ width: '100%' }}
                            value={this.state.contactLastName}
                            disabled={this.state.useSameAsBilling}
                            helperText={this.state.contactLastNameValMsg}
                        />
                        </span>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      
                        <TextField
                            name="contactPhone"
                            id="outlined-required"
                            label="Contact Phone Number"
                            onChange={this.handleChange}
                            size="small"
                            value={this.state.contactPhone}
                            required
                            style={{ width: '100%' }}
                            helperText={this.state.contactPhoneValMsg}
                            />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <span key={this.state.contactEmailRefresh}>
                            <TextField
                                name="contactEmail"
                                id="outlined-required"
                                label="Contact - Email Address"
                                onChange={this.handleChange}
                                size="small"
                                value={this.state.contactEmail}
                                required
                                style={{ width: '100%' }}
                                helperText={this.state.contactEmailValMsg}
                                disabled={this.state.useSameAsBilling} />
                        </span>
                    </Grid>

                    <Grid item col={12} name="DPSValidationMsg" style={{ display: 'none' }}>
                        {this.state.validationErrMsg && <Alert severity="error"><>{this.state.validationErrMsg}</></Alert>} 
                    </Grid>
                </Grid>

            </div>
        )
    }
}