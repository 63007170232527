import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ManageUsers from './ManageUsers.js';
import ManageRoles from './ManageRoles.js';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width:'95%'} }
        >
            {value === index && (
                <Box sx={{ p: 3, width:'100%' }}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function UserManagementDashboard(props) {
    const [tabvalue, setTabValue] = React.useState(0);
    const [isloading, setLoading] = useState(null);
    const [dashboardErrMsg, setDashboardErrorMsg] = useState("");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        document.title = "User Management";
        setLoading(true);
        switch (tabvalue) {
            case 1:
                console.log(tabvalue);
                break;
            case 2:
                console.log(tabvalue);
                 break;
            case 3:
                console.log(tabvalue);
                break;
            case 4:
                console.log(tabvalue);
                break;
        }
    }, [tabvalue]);
    return (
        <Container>
            <Row>
                <Col>
                    <h1>User Management</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 400 }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="fullWidth"
                            value={tabvalue}
                            onChange={handleTabChange}
                            aria-label="User Management"
                            sx={{ borderRight: 1, borderColor: 'divider', width:'200px'}}
                        >
                            <Tab label="Users" {...a11yProps(0)} />
                            <Tab label="Roles" {...a11yProps(1)} />
                         
                         </Tabs>
                        <TabPanel value={tabvalue} index={0} >
                            <ManageUsers />
                        </TabPanel>
                        <TabPanel value={tabvalue} index={1}>
                            <ManageRoles />
                        </TabPanel>
                        <TabPanel value={tabvalue} index={2}>
                            Roles
                        </TabPanel>
                       
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    {dashboardErrMsg ? <Alert severity="error" >{dashboardErrMsg}</Alert> : ""}
                </Col>
            </Row>

        </Container>
    );
}
