import React, { Component} from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Container, Row, Col } from 'reactstrap';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { debounce } from 'lodash';
import cartfunctions from '../../_helpers/cartfunctions';
import Alert from '@mui/material/Alert';
import axios from 'axios';

export default class PaymentInfo extends Component {
   
    cardRef = React.createRef();
    constructor(props) {
        super(props);
        this.cbccstate = {
            errors: {},
            errorMessage: '',
            // CSS class names for field's status. testing a change.
            classes: {
                'focus': 'focus-css-class',
                'complete': 'complete-css-class',
                'invalid': 'invalid-css-class',
                'empty': 'empty-css-class',
            },
            // Google Fonts and other whitelisted fonts
            fonts: [
                'https://fonts.googleapis.com/css?family=Open+Sans'
            ],
            // Style customizations
            styles: {
                base: {
                    color: '#424770',
                    fontWeight: 600,
                    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                   
                    ':focus': {
                        color: '#424770',
                    },

                    '::placeholder': {
                        color: '#9BACC8',
                    },

                    ':focus::placeholder': {
                        color: '#CFD7DF',
                    },
                },
              
                invalid: {
                    color: '#FA755A',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            }
        }
        this.state = {
            email: props.getCartCheckOut().email,
            companyName: props.getCartCheckOut().companyName,
            firstName: props.getCartCheckOut().firstName,
            lastName: props.getCartCheckOut().lastName,
            address1: props.getCartCheckOut().address1,
            address2: props.getCartCheckOut().address2,
            city: props.getCartCheckOut().city,
            region: props.getCartCheckOut().region,
            postalCode: props.getCartCheckOut().postalCode,
            country: props.getCartCheckOut().country,
            //ccNum: props.getCartCheckOut().ccNum,
            //ccExpMonth: props.getCartCheckOut().ccExpMonth,
            //ccExpYear: props.getCartCheckOut().ccExpYear,
            //ccCVV: props.getCartCheckOut().ccCVV,
            cbCCToken: props.getCartCheckOut().cbCCToken,
            contactFirstName: props.getCartCheckOut().contactFirstName,
            contactLastName: props.getCartCheckOut().contactLastName,
            contactfullName: props.getCartCheckOut().contactFirstName + " " + props.getCartCheckOut().contactLastName,
            contactPhone: props.getCartCheckOut().contactPhone,
            contactEmail: props.getCartCheckOut().contactEmail,
            validationErrMsg: '',
            disableOrderCheckoutButton: false,
            fullName: props.getCartCheckOut().firstName + " " + props.getCartCheckOut().lastName,
            addressInfo: (props.getCartCheckOut().companyName === '' ? '' : props.getCartCheckOut().companyName + "\n") + props.getCartCheckOut().address1 + "\n" + props.getCartCheckOut().city + ", " + props.getCartCheckOut().region + " " + props.getCartCheckOut().postalCode + "\n" + props.getCartCheckOut().country,
            couponCode: props.getCartCheckOut().couponCode,
            appliedCouponCode: this.props.cartContents.discountCode,
            discountAmount: props.getCartCheckOut().discountAmount,
            couponCodeValMsg: '',
        };
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
      
        //const year = (new Date()).getFullYear();
        
    }

    componentDidMount() {


    }
    loadCart() {
        return cartfunctions.getCartID();
    }
    clearCart() {
        return cartfunctions.purgeCartID();
    }
    //loadCart() {
    //    var cartDetails = JSON.parse(localStorage.getItem("shoppingCart"));
    //    if (cartDetails === null) {
    //        cartDetails = [];
    //    }
    //    return cartDetails;

    //};
    //clearCart() {
    //    localStorage.setItem("shoppingCart", JSON.stringify([]));
    //    this.setState({ cartDetails: JSON.parse(localStorage.getItem("shoppingCart")) });

    //}
    componentWillUnmount() { }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getCartCheckOut().ccNum !== userInput.ccNum || this.props.getCartCheckOut().ccExpMonth !== userInput.ccExpMonth) { // only update store of something changed
              
                isDataValid = true;
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
   
        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return {
            ccNumVal: (data.ccNum !== undefined && data.ccNum !== -1 && data.ccNum !== ''), // required: anything besides N/A
            ccExpMonthVal: (data.ccExpMonth !== undefined && data.ccExpMonth !== -1 && data.ccExpMonth !== ''),
            ccExpYearVal: (data.ccExpYear !== undefined && data.ccExpYear !== -1 && data.ccExpYear !== ''),
            ccCVVVal: (data.ccCVV !== undefined && data.ccCVV !== -1 && data.ccCVV !== '')
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            ccErrorValMsg: val.ccNumVal ? '' : 'A credit card number is required',
            ccExpMonthValMsg: val.ccExpMonthVal ? '' : 'A valid expiration is required',
            ccExpYearValMsg: val.ccExpYearVal ? '' : 'A valid expiration year is required',
            ccCVVValMsg: val.ccCVVVal ? '' : 'A valid CVV is required'

        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            cbCCToken: this.state.cbCCToken
        };
    }
    createCBOrder = async (cartCheckOutObject, cartDetails) => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        //const customerCartObj = {
        //    FirstName: cartCheckOutObject().firstName,
        //    LastName: cartCheckOutObject().lastName,
        //    Company: cartCheckOutObject().companyName,
        //    Address1: cartCheckOutObject().address1,
        //    Address2: cartCheckOutObject().address2,
        //    City: cartCheckOutObject().city,
        //    State: cartCheckOutObject().region,
        //    PostalCode: cartCheckOutObject().postalCode,
        //    Country: cartCheckOutObject().country,
        //    EmailAddress: cartCheckOutObject().email,
        //    CBCCToken: cartCheckOutObject().cbCCToken,
        //    //CCNum: cartCheckOutObject().ccNum,
        //    //CCExpMonth: String(cartCheckOutObject().ccExpMonth),
        //    //CCExpYear: String(cartCheckOutObject().ccExpYear),
        //    //CCCVV: String(cartCheckOutObject().ccCVV),
        //    ContactEmail: cartCheckOutObject().contactEmail,
        //    ContactPhone: cartCheckOutObject().contactPhone,
        //    ContactFirstName: cartCheckOutObject().contactFirstName,
        //    ContactLastName: cartCheckOutObject().contactLastName,
        //    CartDetails: cartDetails,
        //    invoiceID : cartCheckOutObject().invoiceID
        //};
        const cartObj = {
            CartID: cartfunctions.getCartID(),
            CBCCToken: cartCheckOutObject().cbCCToken,
            invoiceID : cartCheckOutObject().invoiceID
        }
      
        try {
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CreateOrder';
            axios.post(serviceUrl, cartObj)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                        this.props.updateCartCheckOut({ ...{ invoiceID: response.data.value[0].invoiceID } });
                        this.props.updateCartCheckOut({...{ email: response.data.value[0].customerEmail}});
                        this.props.displayThankYouMessage(response.data.value[0].paymentStatus);
                        return true;
                    }
                })
                .catch(error => {
                    console.log(error);
                    var errMsg = 'The system has encountered an error while processing your information. Error Message Details - ' + '[' + error.response.data.message + ']';
                    this.setState({ validationErrMsg: errMsg });
                    this.setState({ disableOrderCheckoutButton: false });
                    setTimeout(() => {
                        this.setState({ validationErrMsg: "" });
                    }, 8000);
                    return false;
                });
            //const response = await fetch(serviceUrl, requestOptions);
            //if (response.ok) {
               
            //    response.json().then(jsonResult => {
            //        if (jsonResult.invoiceID === '') {
            //            var errMsg = 'The system processed your order successfully, however was not able to get an invoice # at this time. Please check your email for more information. ';
            //            this.setState({ validationErrMsg: errMsg });
            //            return false;
            //        }
            //        this.clearCart();
            //        this.props.updateCartCheckOut({ ...{ invoiceID: jsonResult.invoiceID } });
            //        this.props.displayThankYouMessage(jsonResult.paymentStatus);
            //       return true;
            //    })
            //}
          

            
            
        }
        catch (error) {
            var validationErrMsg = 'The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 to process your order and provide the following error message - ';
            this.setState({ validationErrMsg: validationErrMsg + '[' + error.message + ']' });
            this.setState({ disableOrderCheckoutButton: false });
            setTimeout(() => {
                this.setState({ validationErrMsg: "" });
            }, 8000);
            return false;
        }
    }

    handleChange = (e) => {
        //console.log(this.state);
        //this.setState({ [e.target.name]: e.target.value });
        this.setState({ [e.target.name]: e.target.value }, () =>
            this.validationCheck());
    }

    handleCouponCodeChange = (e) => {
        this.setState({ couponCode: e.target.value.toUpperCase() });
    }

    clearSavedCouponCode = (e) => {
        this.setState({ couponCode: "" });
        this.setState({ appliedCouponCode: "" });
        this.updateCartCouponCode();
        this.setState({ discountAmount: 0 });
    }

    validateCouponCode = async () => {
        var CouponCodeValidationErrorDiv = document.getElementsByName('CouponCodeValidationMsg');
        if (Boolean(this.state.couponCode)) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const CartInfo = {
                cartID: cartfunctions.getCartID(),
                discountCode: this.state.couponCode
            };
            try {
                const serviceUrl = endPointURL + "api/ShoppingCart/ValidateCouponCode";
                axios.post(serviceUrl, CartInfo)
                    .then((response) => {
                        // we're getting back sucess bool, message, and discount amount
                        if (response.status === 200) {
                            if (response.data.isValid) {
                                CouponCodeValidationErrorDiv[0].style.display = "none";
                                this.setState({ appliedCouponCode: this.state.couponCode });
                                this.setState({ discountAmount: response.data.discountAmount });
                                this.props.updateCartCheckOut("refreshcart");
                            }
                            else {
                                CouponCodeValidationErrorDiv[0].style.display = "block";
                                this.setState({ couponCodeValMsg: response.data.message });
                            }
                        }
                    })
                    .catch((error) => {
                        CouponCodeValidationErrorDiv[0].style.display = "block";
                        var errMsg = error.response.data.message;
                        this.setState({ couponCodeValMsg: errMsg });
                    })
            }
            catch (error) {
                console.error(error);
                CouponCodeValidationErrorDiv[0].style.display = "block";
                var errMsg = error.message;
                this.setState({ couponCodeValMsg: errMsg });
            }
        }
        else {
            CouponCodeValidationErrorDiv[0].style.display = "block";
            var errMsg = "You must enter a coupon to validate it.";
            this.setState({ couponCodeValMsg: errMsg });
        }
    }

    updateCartCouponCode = (couponCode) => {
        var CouponCodeValidationErrorDiv = document.getElementsByName('CouponCodeValidationMsg');

        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const CartInfo = {
            contactFirstName: this.props.getCartCheckOut().contactFirstName,
            contactLastName: this.props.getCartCheckOut().contactLastName,
            contactEmail: this.props.getCartCheckOut().contactEmail,
            contactPhone: this.props.getCartCheckOut().contactPhone,
            cartID: cartfunctions.getCartID(),
            discountCode: couponCode,
        };
        try {
            const serviceUrl = endPointURL + "api/ShoppingCart/UpdateCouponCodeProductCart";
            axios.post(serviceUrl, CartInfo)
                .then(response => {

                    if (response.status === 200) {
                        CouponCodeValidationErrorDiv[0].style.display = "none";
                        //this.props.updateCartCheckOut({ ...this._grabUserInput() });
                        this.props.updateCartCheckOut( "refreshcart" );
                    }
                })
                .catch(error => {
                    console.log(error);
                    CouponCodeValidationErrorDiv[0].style.display = "block";
                    this.setState({ couponCodeValMsg: error.response.data.detail });
                });
        }
        catch (error) {
            console.error(error);
            CouponCodeValidationErrorDiv[0].style.display = "block";
            var errMsg = error.message;
            this.setState({ couponCodeValMsg: errMsg });
        }
    }

    handleCCChange = (status) => {
        let errors = {
            ...this.state.errors,
            [status.field]: status.error
        };
        let errMessages = Object.values(errors).filter(message => !!message);
        this.setState({
            errors,
            errorMessage: errMessages.pop() || '',
        })
    }

    handlePlaceOrderClick = async(e) => {
        let userInput = null;
        if (this.state.disableOrderCheckoutButton) {
            console.log("Already clicked once");
            return;
        }
        //const userInput = this._grabUserInput(); // grab user entered vals
        //const validateNewInput = this._validateData(userInput); // run the new input against the validator
        //console.log(validateNewInput);
        let isDataValid = false;
        if (e) e.preventDefault()
        await this.cardRef.current.tokenize()
            .then((data) => {
                //console.log('chargebee token', data.token);
                isDataValid = true;
                this.setState({ disableOrderCheckoutButton: true });
                this.setState({ cbCCToken: data.token }, () =>
                   userInput = this._grabUserInput()
                );
                this.props.updateCartCheckOut({ ...userInput });
                //this.setState({ cbCCToken: data.token });
                if (isDataValid) {
                    this.props.toggleWaitModal(true);
                    var OrderValidationErrorDiv = document.getElementsByName('OrderValidationMsg');
                    try {
                        let CreateOrderResult = this.createCBOrder(this.props.getCartCheckOut, this.loadCart());
                        //console.log(CreateOrderResult);
                        if (CreateOrderResult === true) {
                            OrderValidationErrorDiv[0].style.display = "none";

                        }
                        else {
                            OrderValidationErrorDiv[0].style.display = "block";
                        }
                    }
                    catch (err) {
                        OrderValidationErrorDiv[0].style.display = "block";
                        this.state.validationErrMsg = err.message;

                    }
                    this.props.toggleWaitModal(false);
                }
                
            })
            .catch((err) => {
                var OrderValidationErrorDiv = document.getElementsByName('OrderValidationMsg');
                OrderValidationErrorDiv[0].style.display = "block";
                //console.log(err);
                this.state.validationErrMsg = 'An error occured while trying to process your credit card information.Please confirm you have entered the information correctly.';
                this.setState({ disableOrderCheckoutButton: false });
                });

      
     
        if (!isDataValid) {
            
            var OrderValidationErrorDiv = document.getElementsByName('OrderValidationMsg');
             OrderValidationErrorDiv[0].style.display = "block";
            this.state.validationErrMsg = "An error occured while trying to process your credit card information. Please confirm you have entered the information correctly.";

        }
    }

    render() {
        const { fonts, styles, classes, locale } = this.cbccstate;
        return (
            <div className="step PaymentInfo">
                <Container style={{ padding: 15 }}>
                    <Row>
                        <Col md={12}>
                            <label className="col-md-12 control-label">
                                <h2>Payment Info</h2>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} >
                            Please review the information below and confirm everything is correct. You can click on the Previous button if you want to make any changes.
                            Upon entering your credit card information, please click on the Place Order button to complete the transaction. You will get a confirmation email once
                            the transaction has been processed successfully.
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <hr />
                            <label className="col-md-12 control-label">
                                <h4>Contact Info</h4>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ padding: 15 }}>
                            <TextField
                                disabled
                                label="Contact Person"
                                size="small"
                                name="nameInfo"
                                defaultValue={this.state.contactfullName}
                                style={{ width: '33%', paddingRight: "10px" }}
                            />
                            <TextField
                                disabled
                                label="Contact Email"
                                size="small"
                                name="emailInfo"
                                defaultValue={this.state.contactEmail}
                                style={{ width: '33%', paddingRight: "10px" }}
                            />
                            <TextField
                                disabled
                                label="Contact Phone"
                                size="small"
                                name="emailInfo"
                                defaultValue={this.state.contactPhone}
                                style={{ width: '33%' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <hr />
                            <label className="col-md-12 control-label">
                                <h4>Billing Info</h4>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ padding: 15 }}>
                            <TextField
                                disabled
                                label="Billing Person"
                                size="small"
                                name="nameInfo"
                                defaultValue={this.state.fullName}
                                style={{ width: '50%', paddingRight: "10px"}}
                            />
                            <TextField
                                disabled
                                label="Billing Email"
                                size="small"
                                name="emailInfo"
                                defaultValue={this.state.email}
                                style={{ width: '50%' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ padding: 15 }}>
                            <TextField
                                disabled
                                label="Address Information"
                                size="small"
                                name="AddressInfo"
                                multiline
                                maxRows={6}
                                defaultValue={this.state.addressInfo}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <hr />
                        <Col md={5}>
                            {this.state.appliedCouponCode && this.state.appliedCouponCode.length > 0 ? (
                            <>
                                <TextField style={{ width: '75%', margin: '0px', paddingRight: '6px' }}
                                    disabled
                                    label="Coupon Code Applied"
                                    size="small"
                                    name="appliedCouponCode"
                                    value={this.state.appliedCouponCode}
                                    onChange={this.handleChange}
                                />
                                <Button onClick={this.clearSavedCouponCode}
                                    variant="contained"
                                    className="UWA_btn_cancel" >Clear
                                </Button>
                            </>
                            ) : (
                            <>
                                <TextField style={{ width: '75%', margin: '0px', paddingRight: '6px' }}
                                    name="couponCode"
                                    label="Coupon Code"
                                    size="small"
                                    value={this.state.couponCode}
                                    onChange={this.handleCouponCodeChange}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                />
                                <Button onClick={this.validateCouponCode}
                                    variant="contained"
                                    className="UWA_btn_cancel" >Apply
                                </Button>
                            </>
                            )
                            }
                        </Col>
                        <Col md={7} name="CouponCodeValidationMsg" style={{ display: 'none' }}>
                            {this.state.couponCodeValMsg && <Alert severity="error"><>{this.state.couponCodeValMsg}</></Alert>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <hr />
                            <label className="col-md-12 control-label">
                                <h4>Credit Card Info</h4>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="CBCCComponent" style={{ padding: 10, border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '4px', marginBottom:'4px'}}>
                            <CardComponent ref={this.cardRef} onChange={this.handleCCChange} className="CCfield"
                                fonts={fonts}
                                classes={classes}
                                locale={locale}
                                styles={styles} >
                            </CardComponent>
                        </Col>
                    </Row>
                   
                   
                  
                   
                    <Row>
                        <Col md={12}>
                            <Button variant="contained" disabled={this.state.disableOrderCheckoutButton} onClick={debounce(this.handlePlaceOrderClick,300)}>Place your order</Button>
                        </Col>
                    </Row>
                    <Row name="OrderValidationMsg" style={{ display: 'none' }}>
                        <Col md={12}>
                            {this.state.validationErrMsg && <Alert severity="error"><>{this.state.validationErrMsg}</></Alert>}
                        </Col>
                    </Row>
                </Container>
                
              
            </div>
        )
    }
}