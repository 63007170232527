import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
function FIQAddUserModal(props) {
 
    //const editName = props.editName;
    //const editUserName = props.editUserName;
    //const editEmail = props.editEmail;
    const open = props.open;
    const onClose = props.onClose;
    const [validationErrMsg, updateValidationErrMsg] = React.useState("");
    //const [hasInputValues, toggleHasInputValues] = React.useState(false);
    //const [isEditRecord, updateEditRecordFlag] = React.useState(false);
    const [name, updateName] = React.useState("");
    const [userName, updateUserName] = React.useState("");
    const [email, updateEmail] = React.useState(props.editEmail);
    const [errNameMsg, updateNameErrorMsg] = React.useState("");
    const [errUserNameMsg, updateUserNameErrorMsg] = React.useState("");
    const [errEmailMsg, updateEmailErrorMsg] = React.useState("");

    //const [editName, updateEditName] = props.editName;
    //const [editUserName, updateEditUserName] = props.editUserName;
    //const [editEmail, updateEditEmail] = props.editEmail;


   

    //function onClearFields(e)
    //{
    //    updateName("");
    //    updateUserName("");
    //    updateEmail("");
    //    updateValidationErrMsg("");

    //};
    const handleNameChange = (e) =>{
        updateName(e.target.value);
    };
    const handleuserNameChange = (e) => {
        updateUserName(e.target.value);
    };
    const handleEmailChange = (e) => {
        updateEmail(e.target.value);
    };
    async function onFIQUserAdd() {
        if (await validateUserFields()) {
            if (await props.updateUserList(props.editEmail, ((!!name) ? name : props.editName), ((!!userName) ? userName : props.editUserName), ((!!email) ? email : props.editEmail))) {
                updateName("");
                updateUserName("");
                updateEmail("");
                updateValidationErrMsg("");
                props.onClose(false);
            }
            else {
                updateValidationErrMsg("Unable to add user to the list of existing users. The username and email must be unique.")
            };

        }
        
        
    }
    async function validateUserFields(){
        var success = true;
        updateNameErrorMsg("");
        updateUserNameErrorMsg("");
        updateEmailErrorMsg("");
        if (!(name !== undefined && name !== '' && /^[a-zA-Z '.-]*$/.test(name))) {
            updateNameErrorMsg("You must provide a name for the subscriber");
            success = false;
        }


        if (!(userName !== undefined && userName !== '' && /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/.test(userName))) {
            updateUserNameErrorMsg("Please enter a username using only characters and numbers. Max size of 15 characters.");
            success = false;
        }
        if (!(email !== undefined && email !== '' && /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            updateEmailErrorMsg("Email is not valid");
            success = false;
        }
        return success;
    }
    useEffect(() => {
        updateValidationErrMsg("");
        updateNameErrorMsg("");
        updateUserNameErrorMsg("");
        updateEmailErrorMsg("");
        updateName(props.editName);
        updateUserName(props.editUserName);
        updateEmail(props.editEmail);
    }, [open]);
    
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="UWA_DialogTitle" >
                Add Feasibility IQ User
            </DialogTitle>
            <DialogContent className="UWA_FIQAddUserDialogContent" sx={{ margin: '5px' }}>

                <TextField style={{ width: '90%', margin: '5px' }}
                    name="Name"
                    label="Name"
                    size="small"
                    defaultValue={props.editName}
                    onChange={handleNameChange}
                    helperText={errNameMsg}
                />
                <TextField style={{ width: '90%', margin: '5px' }}
                    name="username"
                    label="Username"
                    size="small"
                    defaultValue={props.editUserName }
                    onChange={handleuserNameChange}
                    inputProps={{ 'maxLength': 15 }}
                    helperText={errUserNameMsg}
                />
                <TextField style={{ width: '90%', margin: '5px' }}
                    name="email"
                    label="Email"
                    size="small"
                    defaultValue={props.editEmail}
                    onChange={handleEmailChange}
                    disabled={(!!props.editEmail)}
                    helperText={errEmailMsg}
                />
                <div style={{ width: '90%', margin: '5px' }}>
                    <span className=" has-error">{validationErrMsg}</span>
                 </div>
                <Button onClick={onFIQUserAdd} autoFocus variant="contained" className="UWA_btn_Submit">
                    Submit
                </Button>
            </DialogContent>
            <DialogActions >
           
                <Button onClick={onClose} autoFocus variant="contained" className="UWA_btn_cancel">
                    Close
                </Button>

            </DialogActions>
        </Dialog>
    );

};
export default FIQAddUserModal;