import React, { useState, useRef, useEffect} from 'react';
import { Card, CardContent, CardHeader, Grid, Button, Skeleton, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, Alert, Select, InputLabel, Switch, TextField, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { commonfunctions } from '../../_helpers/commonfunctions';
import SubscriptionItemDetails from './SubscriptionItemDetails.js'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RegistryForm  from './MetaDataComponents/RegistryForm.js';
import UserForm from './MetaDataComponents/UserForm.js';
import RevisionDetails from './RevisionDetails.js';
import CustomerInfoTile from './CustomerInfoTile.js';
import SubscriptionDetailsTile from './SubscriptionDetailsTile.js';
import PermissionsGate from '../../security/PermissionGate';
import { SCOPES } from '../../security/permission-maps';   

import axios from 'axios';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function SubscriptionDetails(props) {
    const { loading, custInfo, reloadsubscription, ...subscripdetails } = props;
    //console.log(subscripdetails);
    const [subscripRevisionDetails, updatesubscripRevisionDetails] = useState(subscripdetails.subscripdetails.subscription_revision);

    const [originalRevisionDetails, setOriginalRevisionDetails] = useState(subscripdetails.subscripdetails.subscription_original);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openCancelSubscriptionDialog, setCancelSubscriptionDialogStatus] = useState(false);
    const [validationErrMsg, setValidationErrMsg] = useState("");
    const [finalizeSubscriptionErrMsg, setFinalizeSubscriptionErrorMsg] = useState("");
    const [yesoptionDisabled, disableYesOption] = useState(false);
    const [selectedPlanAdditionValue, setPlanAdditionValue] = useState("");
    const [subscriptionaddOns, setAddOnsList] = useState([]);
    const subscriptionItemKey = useRef("");
    const subscriptionItemFirstName = useRef("");
    const subscriptionItemLastName = useRef("");
    const subscriptionItemEmail = useRef("");
    const [isPlanType, setIsPlanType] = useState(true);
    const [useExistingDiscount, setDiscountFlag] = useState(true);
    const [customDiscountValue, setCustomDiscountValue] = useState(0);
    const [customDiscountType, setCustomDiscountType] = useState("percentageDiscount");
    const [selectedRegistries, setRegistry] = useState([]);
    const [extendedPlanType, setextendedPlanType] = useState(subscripdetails.subscripdetails.extended_plan_type);
    const cancelReasonCodes = useRef([]);
    const handle_AddSubscriptionItem = async () => {
        setValidationErrMsg("");
        setPlanAdditionValue("");
        //User wants to add a unit to the subscription.
        //Lets retrieve any add-ons for this plan type and then ask the user to pick an add-on if applicable. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        let aps = {
            planID: props.subscripdetails.subscription_plan_id,
            subscriptionID: subscripdetails.subscripdetails.id,
            billingPeriodUnit: subscripdetails.subscripdetails.billing_period_unit,
            subscriptionDetails: subscripRevisionDetails
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetPlanAddOns';
        await axios.post(serviceUrl, aps)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setAddOnsList([]);
                    if (res.data.addOns.length > 0) {
                        setAddOnsList(res.data.addOns);

                    }
                    disableYesOption(false);
                }

            })
            .catch((error) => {
                //console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });

        setOpenAddDialog(true);


    }
    const handleCancelSubscription = () => {
        setCancelSubscriptionDialogStatus(true);
    }
    const reload_CurrentSubscription = async (e) => {
        //console.log("YES");
        //props.reloadsubscription();
        //setSubscriptionItemKey("");
        //setSubscriptionItemFirstName("");
        //setSubscriptionItemLastName("");
        //setSubscriptionItemEmail("");
        //console.log(e);
        updatesubscripRevisionDetails([]);
        updatesubscripRevisionDetails(e);

    }
    useEffect(() => {
        loadCancelReasons();
        //console.log(cancelReasonCodes.current);
    }, []);
    const loadCancelReasons =  async() => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        try {
            const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetSubscriptionCancelReasonCodes';
            await axios.get(serviceUrl)
                .then((response) => {
                    if (response.status === 200) {
                        //console.log(response.data);
                        cancelReasonCodes.current = (response.data);
                        //return response.data;
                        //let i = 0;
                        //while (i < cancelReasonsList.length) {
                        //    setCancelReasonCode([response.data]);
                        //    i++;
                        //}
                       // console.log(cancelReasonCodes);


                    }


                })
                .catch((error) => {
                    console.log('Error:', error);
                });

        }
        catch (error) {
            console.log('Error:', error);
        }
    }
    const handleAddDialogClose = () => {
        setOpenAddDialog(false);

    };
    const handleCancelSubscriptionDialogClose = () => {
        setCancelSubscriptionDialogStatus(false);
    }
    const handleSelectionChange = (event) => {
        setPlanAdditionValue((event.target).value);
        setIsPlanType(false);
        setValidationErrMsg("");
        subscriptionItemKey.current = "";
        //setSubscriptionItemKey("");
        if ((event.target).title === "plan") {
            setIsPlanType(true);
        }

    };
    const handleDefaultDiscountsChange = async (event) => {
        await setDiscountFlag(event.target.checked);

        //console.log(useExistingDiscount);
        //console.log(!isNaN(customDiscountValue));
    }
    const handleDiscountTypeChange = (event) => {
        setCustomDiscountValue(0);
        setCustomDiscountType(event.target.value);
    }
    const handleCustomDiscountValueChange = (event) => {
        setCustomDiscountValue((event.target).value);
    }
    const handleAddDialogSubmit = async (event) => {
        //User wants to save a new item to the subscription. lets pass it on to the server for validation and processing.
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        var subscriptionMetaType = "";
        var action = "";
        if (isPlanType) {
            if (subscripdetails.subscripdetails.extended_plan_type == 'REGISTRY') {
                subscriptionMetaType = "REGISTRY";
                action = "validateAddRegistry";
            }
            if (subscripdetails.subscripdetails.extended_plan_type == 'USER') {
                subscriptionMetaType = "USER";
                action = "validateAddUser";
            }
            if (subscriptionItemKey.current == "") {
                if (subscripdetails.subscripdetails.extended_plan_type == 'USER') {
                    return setValidationErrMsg('You must provide the Username');
                }
                return setValidationErrMsg('You must provide the Registry/Tail #');
            }
        }
        else {
            if (selectedPlanAdditionValue == "") {
                return setValidationErrMsg('You must select an option before clicking on the submit button');
            }
            else {
                action = "validateAddAddOn";
            }

        }
        //Now lets pass this information to the estimateObject so that it triggers a refresh from chargebee. 
        const subscriptionInfo = {
            action: action,
            subscriptionID: subscripdetails.subscripdetails.id,
            subscriptionDetails: subscripRevisionDetails,
            addOnID: selectedPlanAdditionValue,
            key: subscriptionItemKey.current,
            firstName: subscriptionItemFirstName.current,
            lastName: subscriptionItemLastName.current,
            email: subscriptionItemEmail.current,
            pricingModel: subscripdetails.subscripdetails.pricingModel,
            subscriptionMetaType: subscriptionMetaType,
            includeExistingDiscounts: useExistingDiscount,
            customDiscountType: customDiscountType,
            customDiscountValue: customDiscountValue,
            selectedAddOnRegistries: selectedRegistries
        };
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CheckEditSubscription';
        await axios.post(serviceUrl, subscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    //console.log(subscripRevisionDetails);
                  
                    updatesubscripRevisionDetails([]);
                    updatesubscripRevisionDetails(res.data.revisionInfo);

                    //console.log(subscripRevisionDetails);
                    handleAddDialogClose();

                }

            })
            .catch((error) => {
                //console.error('Error:', error.response.data);
                disableYesOption(true);
                if (error.response.data.message) {
                    setValidationErrMsg(error.response.data.message);
                }
                else {
                    setValidationErrMsg(error.response.data);
                }

            });

    }
    const finalizeSubscriptionChanges = async (e) => {
        //user wants to finalize the subscription changes.
      
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/FinalizeSubscriptionChanges';
        const finalizeSubscriptionInfo = {
            subscriptionID: subscripdetails.subscripdetails.id,
            subscriptionDetails: e,
            pricingModel: subscripdetails.subscripdetails.pricingModel
        };
        await axios.post(serviceUrl, finalizeSubscriptionInfo)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data);
                    setFinalizeSubscriptionErrorMsg("");
                    //disableYesOption(false);
                    props.reloadsubscription();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                if (error.response.data.message) {
                    setFinalizeSubscriptionErrorMsg(error.response.data.message);
                }
                else {
                    setFinalizeSubscriptionErrorMsg(error.response.data);
                }

            });
    }
    const handleSelectedRegistriesChange = async (event) => {
        const {
            target: { value },
        } = event;
        setRegistry(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }
    const validateRegistryInfo = React.useCallback((e) => {
        console.log((e.target).value);
        subscriptionItemKey.current = ((e.target).value);
        //setSubscriptionItemKey((e.target).value);
        disableYesOption(false);
        setValidationErrMsg("");

    }, []);
    const LoadAddToSubscriptionButton = () => {
        if (subscripdetails.subscripdetails.meta_data ||
            subscripdetails.subscripdetails.extended_plan_type == "NONE") {
            return (
                <>
                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                        {(subscripdetails.subscripdetails.extended_plan_type != "NONE") ? <Button variant="contained" onClick={handle_AddSubscriptionItem} disabled={commonfunctions.checkSubscriptionStatus(subscripdetails.subscripdetails.status)}>Add</Button> : null}
                    </PermissionsGate>
                    {subscripdetails.subscripdetails.isTrial ? <>
                        <PermissionsGate scopes={[SCOPES.canManageTrialSubscriptions]} showMessage="false" missingscopes={[SCOPES.canManageSubscriptions]}>
                            {(subscripdetails.subscripdetails.extended_plan_type != "NONE") ? <Button variant="contained" onClick={handle_AddSubscriptionItem} disabled={commonfunctions.checkSubscriptionStatus(subscripdetails.subscripdetails.status)}>Add</Button> : null}
                        </PermissionsGate>

                    </> : null
                    }
                </>
            ); 

        }
        else {
            return (<Alert severity="error" >This subscription was not created via this interface. Please contact the administrator if you do not believe this to be correct.</Alert>);
        }
    }
    const LoadCancelSubscriptionDialog = () => {
        return (
            <>
                <Dialog open={openCancelSubscriptionDialog}
                    onClose={handleCancelSubscriptionDialogClose}>
                    <DialogTitle> {"Cancel Subscription"}</DialogTitle>
                    <DialogContent>
                        <FormLabel id="cancelSubscriptionLabel">In order to cancel this subscription, you must log into the Chargebee Interface to complete the cancellation. </FormLabel>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus={true} onClick={handleCancelSubscriptionDialogClose} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
    const LoadCustomerInfoTile = (customerProps) => {
       //console.log(customerProps);
        return (
            <>
                
                        {customerProps.customerinfo && customerProps.customerinfo.map((item) => {
                            let subscriberInfo = {
                                loading: loading,
                                subscriberinfo: item
                            };
                            // console.log("INSIDE CUSTOMER INFO");
                            return (<CustomerInfoTile {...subscriberInfo} key={item.id}  />);
                        })}
            </>
        );
    }
    const LoadSubscriptionDetailsTile = (subscriptionInfo) => {
        return (
            <>
                <SubscriptionDetailsTile loading={loading} subscriptiondetails={subscriptionInfo.subscriptionInfo}  /> 
            </>
        
        );
    }
    const LoadExtendedPlanTypeChoice = () => {
        if (extendedPlanType === 'REGISTRY') {
            let registrymode = {
                mode: "new",
                onvalidate: validateRegistryInfo
            }
            return (
                <>
                    <FormControlLabel value="REGISTRY" control={<Radio inputProps={{ 'title': 'plan' }} />} label="Add Registry/Tail #" />
                    <RegistryForm {...registrymode} />
                </>
            );

        }
        else if (extendedPlanType === 'USER') {
            let usermode = {
                mode: "new",
                onvalidate: validateFIQUserInfo
            }
            return (
                <>
                    <FormControlLabel value="USER" control={<Radio inputProps={{ 'title': 'plan' }} />} label="Add User" />
                    <UserForm {...usermode} />
                </>
            );

        }
        else {
            disableYesOption(true);
            return null;
        }
    }
    const validateFIQUserInfo = React.useCallback((e) => {
        switch ((e.target).name) {
            case "userName":
                subscriptionItemKey.current = ((e.target).value);
                //setSubscriptionItemKey((e.target).value);
               
                break;
            case "firstName":
                subscriptionItemFirstName.current = ((e.target).value);
                 break;
            case "lastName":
                subscriptionItemLastName.current = ((e.target).value);
                break;
            case "email":
                subscriptionItemEmail.current = ((e.target).value);
                break;

        }
        disableYesOption(false);
        setValidationErrMsg("");
  
    })
    const handleAbandonProposedChanges = React.useCallback((e) => {
        //Reset everything back to what it used to be. 
        updatesubscripRevisionDetails(originalRevisionDetails);
        setRegistry([]);
    }, [])
    const handleFinalizeProposedChanges = React.useCallback((e) => {
        finalizeSubscriptionChanges(e);
    }, [])
   
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width="100%" height={200} />
            </div>
        );
    }
    let subscriptionID = subscripdetails.subscripdetails.id;
   
    
    
    let rev = {
        revisiondetails: subscripRevisionDetails
    };
    let revisionUpdates = {
        handleabandonrequest: handleAbandonProposedChanges,
        handlefinalizerequest: handleFinalizeProposedChanges
    }
   
    return (
        <>
            <Dialog
                open={openAddDialog}
                onClose={handleAddDialogClose}>
                <DialogTitle id="Add Subscription Item">
                    {"Add Item To Subscription"}
                </DialogTitle>
                <DialogContent>

                    <FormControl>
                        <FormLabel id="addChoicesLabel"> What would you like to add to this subscription? Please pick from the choice(s) below.</FormLabel>
                        <RadioGroup
                            aria-labelledby="addChoicesLabel"
                            name="row-addChoicesGroup"
                            onChange={handleSelectionChange}
                            defaultValue={extendedPlanType}
                        >
                            <LoadExtendedPlanTypeChoice />
                            {/*{subscripdetails.subscripdetails.itemFamilyID && subscripdetails.subscripdetails.itemFamilyID == 'OFP'*/}
                            {/*    ? ()*/}
                            {/*    : (subscripdetails.subscripdetails.itemFamilyID == 'Feasibility-IQ' ? () : (null))}*/}
                            {/*{selectedPlanAdditionValue && selectedPlanAdditionValue == 'OFP'*/}
                            {/*    ? ()*/}
                            {/*    : ((selectedPlanAdditionValue == 'Feasibility-IQ' ? () : (null)))}*/}
                            <Grid container spacing={2}>
                                {subscriptionaddOns.length > 0 ? (
                                    <> <Grid item xs={12}>
                                        <Typography color="textSecondary">
                                            Addons:
                                        </Typography>
                                        <hr />
                                    </Grid>

                                        <Grid item xs={6}><FormControlLabel control={<Switch checked={useExistingDiscount} onChange={handleDefaultDiscountsChange} />} label="Use Existing Discounts" /></Grid>
                                        {!useExistingDiscount ? (
                                            <> <Grid item xs={3}>
                                                <TextField style={{ width: '100%' }}
                                                    name="discount"
                                                    label="Discount Applied"
                                                    size="small"
                                                    value={customDiscountValue}
                                                    onChange={handleCustomDiscountValueChange}
                                                />
                                            </Grid>
                                                <Grid item xs={3}>
                                                    <RadioGroup
                                                        aria-labelledby="discountChoicesLabel"
                                                        name="row-discountChoiceGroup"
                                                        row
                                                        onChange={handleDiscountTypeChange}
                                                        defaultValue={customDiscountType}
                                                    >
                                                        <FormControlLabel value="dollarDiscount" control={<Radio inputProps={{ 'title': 'Discount in Dollars' }} />} label="$" />
                                                        <FormControlLabel value="percentageDiscount" control={<Radio inputProps={{ 'title': 'Discount as a Percentage' }} />} label="%" />
                                                    </RadioGroup>
                                                </Grid>
                                            </>) : null}

                                    </>) : null}
                            </Grid>
                            {subscriptionaddOns && subscriptionaddOns.map((item) => {
                                //console.log(item);
                                return (
                                    <React.Fragment key={item.id}>
                                        <FormControlLabel value={item.id} control={<Radio inputProps={{ 'title': 'addon' }} />} label={item.name} key={item.id} />

                                    </React.Fragment>)
                            })}
                        </RadioGroup>
                    </FormControl>
                    {(subscriptionaddOns.length > 0) ? (<FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="lblAddOnRegistrySelection">Applicable Registries</InputLabel>
                        <Select
                            labelId="lblAddOnRegistrySelection"
                            multiple
                            value={selectedRegistries}
                            input={<OutlinedInput label="Registry" />}
                            onChange={handleSelectedRegistriesChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {subscripdetails.subscripdetails.meta_data.subscriptionInfo.map((item, index) => {
                                let uniqueKey = item.key + "_" + index;
                                //console.log(uniqueKey);
                                return (
                                    <MenuItem key={uniqueKey} value={item.key}>
                                        <Checkbox checked={selectedRegistries.indexOf(item.key) > -1} />
                                        <ListItemText primary={item.key} />
                                    </MenuItem>

                                );
                            })}
                        </Select>
                    </FormControl>) : ("") }
                    
                    
                    {validationErrMsg ? <Alert severity="error" >{validationErrMsg}</Alert> : ""}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus={true} onClick={handleAddDialogClose} variant="contained">
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" disabled={yesoptionDisabled} data-key={subscripdetails.subscripdetails.id} onClick={handleAddDialogSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadCancelSubscriptionDialog />
            <Card square={true} style={{ marginTop: "1px" }} >
                <CardHeader title={"Subscription Details"} className="UWA_SubscriptionCardTitle"
                    action={
                        <>
                            <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                            {!commonfunctions.checkSubscriptionStatus(subscripdetails.subscripdetails.status) ? <Button className="UWA_btn_CancelSubscription" sx={{ m: '5px' }} onClick={handleCancelSubscription}>
                                Cancel
                            </Button> : null}
                            </PermissionsGate>
                        </>
                    }
                />
            <CardContent>
                    <Grid container >
                        <Grid item xs={12} md={12} >
                            <LoadCustomerInfoTile customerinfo={props.custInfo}  />
                            
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <hr/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <LoadSubscriptionDetailsTile subscriptionInfo={subscripdetails.subscripdetails }/><br/>
                        </Grid>
                        <Grid item xs={3} align="left">
                            <b>Plan & Addon Details</b>
                        </Grid>
                        <Grid item xs={1} align="center">
                            <b>Type</b>
                        </Grid>
                        <Grid item xs={2} align="center">
                            <b>Qty</b>
                        </Grid>
                        <Grid item xs={2} align="center">
                            <b>Unit Price</b>
                        </Grid>
                        <Grid item xs={2} align="center">
                            <b>Discount</b>
                        </Grid>
                        <Grid item xs={2} align="right">
                            <b> Sub Total</b>
                        </Grid>
                        {subscripdetails.subscripdetails && subscripdetails.subscripdetails.subscription_item_extended.map((item, index) => {
                        return (<React.Fragment key={item.item_id}>
                           
                                <Grid container >
                                    <Grid item xs={3} align="left">
                                        {item.item_id}
                                        </Grid>
                                    <Grid item xs={1} align="center">
                                            {item.item_type}
                                        </Grid>
                                    <Grid item xs={2} align="center">
                                            {item.quantity}
                                        </Grid>
                                    <Grid item xs={2} align="center">
                                            {commonfunctions.formatToCurrency(item.unit_price)}
                                        </Grid>
                                    <Grid item xs={2} align="center">
                                            {item.discount_percentage === null ? commonfunctions.formatToCurrency(item.discount_amt) : commonfunctions.formatToPercentage(item.discount_percentage)}
                                        </Grid>
                                    <Grid item xs={2} align="right">
                                            {commonfunctions.formatToCurrency(item.extendedprice)}
                                        </Grid>
                                </Grid>
                        </React.Fragment>)
                    })}
                        <Grid item xs={10} align="right" >
                            <b>Total&nbsp;&nbsp;&nbsp;</b>
                        </Grid>
                        <Grid item xs={2} align="right" sx={{ borderTop: "1px solid #ccc" }}>
                            <b>{commonfunctions.formatToCurrency(subscripdetails.subscripdetails.subscription_total)}</b>
                        </Grid>
                </Grid>
                    <Grid item xs={12} align="left">
                        {subscripRevisionDetails && subscripRevisionDetails.hasChanges ? <RevisionDetails {...rev} {...revisionUpdates} /> : ""}
                            {finalizeSubscriptionErrMsg ? <Alert severity="error" >{finalizeSubscriptionErrMsg}</Alert> : ""}
                    </Grid>   
                    <Grid container spacing={2} justifyContent="center" alignItems="top" >
                        <Grid item xs={12} align="left">
                            <hr />
                        </Grid>
                        <Grid item md={12} xs={12}>
                        <LoadAddToSubscriptionButton/>
                     
                        </Grid>
                       

                        {subscripRevisionDetails.metaData && subscripRevisionDetails.metaData.subscriptionInfo.sort(function (a, b) {
                            return (b.endDate === null) - (a.endDate === null) || -(b.endDate > a.endDate) || +(b.endDate < a.endDate);
                        }).map((item, index) => {
                            
                            let itemInfo = {
                            loading: loading,
                            subscriptionCancellationReasons: cancelReasonCodes,
                                subscriptionid: subscripdetails.subscripdetails.id,
                            customerid: props.custInfo.id,
                            pricingModel: subscripdetails.subscripdetails.pricingModel,
                            subscriptionStatus: subscripdetails.subscripdetails.status,
                            customerid: subscripdetails.subscripdetails.customer_id,
                            subscriptionDetails: subscripRevisionDetails,
                            isTrial: subscripdetails.subscripdetails.isTrial,
                            itemDetails: item
                            };
                           
                        let reload = {
                            reloadsubscription: reload_CurrentSubscription
                        }
                        
                        return (<Grid item md={4} xs={12} key={index}><SubscriptionItemDetails {...itemInfo} {...reload}/></Grid>)
                    })}

                </Grid>
            </CardContent>
        </Card>
     </> 
        );
};