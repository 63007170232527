import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import { Button, CardActions } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCart from './ShoppingCart';
import { commonfunctions } from '../_helpers/commonfunctions';
import { cartfunctions } from '../_helpers/cartfunctions';
import Alert from '@mui/material/Alert';


function ProductCard(props) {
    //var cart = loadCart();
    //const [open, setOpen] = React.useState(false);
    const [cartDetails] = useState(props.cartContents);
    const [orientation] = useState(props.orientation);
    const [validationErrMsg, updateValidationErrMsg] = useState("");

    const product = props.dataParentToChild;
    const [checkOutPageLink] = useState(props.CheckOutPageLink);
    const [purchaseOnAccountLink] = useState(props.PurchaseOnAccountLink);
    const handleClickOpen = async(event, currentProduct) => {
        //Grab the shopping cart from localstorage
        //var item = CreateItem(currentProduct.id, currentProduct.external_name, currentProduct.description, (currentProduct.item_price.price / 100), 1);
        await addItemToCart(currentProduct.id, currentProduct.item_price.price);
        
       
    };
    const addItemToCart = async (productID, unitPrice) => {
        try {
            await cartfunctions.addItemToCart(productID, unitPrice)
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        
                        props.updateCartContents();
                        setDialogIsOpen(true);
                        return true;
                    }
                    else {
                        updateValidationErrMsg(response.response.data.detail);
                        setTimeout(() => {
                            this.setState({ validationErrMsg: "" });
                        }, 8000);
                    }
                })
                .catch((error) => {
                    //Lets surface the error to the user. 
                    console.log(error);
                    updateValidationErrMsg(error.data.message);
                    setTimeout(() => {
                        this.setState({ validationErrMsg: "" });
                    }, 8000);
                    return error;
                });
          
           
        }
        catch (error) {
            
            return false;
        }
    }

    const handleViewCartOpen = (event) => {
        setDialogIsOpen(true);
    };

    const [paymentChoices, togglePaymentChoices] = React.useState(false);

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

    const displayPaymentChoices = () => togglePaymentChoices(!paymentChoices);

    const closeDialog = () => setDialogIsOpen(false);
    const RedirectToOnAccountPage = () => {
        if (purchaseOnAccountLink !== "") {
            window.location.href = purchaseOnAccountLink;
        }
        else {
            window.location.href = "https://www.universalweather.com/web-forms/dispatcher-recurrent-course-registration/";
        }
        
    }
    function getFormattedDate(dateVal) {
        return dateVal.toLocaleString('en-us', { month: 'long' }) + ' ' + dateVal.toLocaleString('en-us', { day: 'numeric' }) + ', ' + dateVal.toLocaleString('en-us', { year: 'numeric' });
    }
    function getFormattedTime(startDateVal, endDateVal) {
        return startDateVal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' - ' + endDateVal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    function hasValidTimings(startDateVal, endDateVal) {
        if (startDateVal === 0 || endDateVal === 0) {
            return false;
        }
        var startTime = new Date(startDateVal * 1000).getHours() + ":" + new Date(endDateVal * 1000).getMinutes();
        var endTime = new Date(endDateVal * 1000).getHours() + ":" + new Date(endDateVal * 1000).getMinutes();
        if (startTime === "0:0" && endTime === "0:0") {
            return false;
        }
        return true;
    }
    function getTimezone(dateVal) {
        return new Intl.DateTimeFormat([], { timeZoneName: 'short' })
            .formatToParts(dateVal)
            .find(part => part.type === "timeZoneName")
            .value;
    }
    //function getRandomImg() {
    //    var x = Math.floor(Math.random() * (1200 - 1000) + 1000);
    //    return 'https://picsum.photos/1924/' + x + '?random';
    //};
    //function getProductImg() {
    //    //console.log(process.env.REACT_APP_API_URL);
    //    return process.env.REACT_APP_API_URL + '/images/card-bg.svg';
    //}
   
    //function saveCart(cart) {
    //    localStorage.setItem("shoppingCart", JSON.stringify(cart));
    //    props.updateCartContents(cart);
    //    updateCart(cart);
    //};



    //function addItemToCart(item) {
    //    //var cart = JSON.parse(localStorage.getItem("shoppingCart"));
    //    var cart = cartDetails;
    //    for (var i in cart) {
    //        if (cart[i].id === item.id) {
    //            if ((cart[i].qty + 1) > 10) {
    //                alert("You have exceeded a limit in the quantity allowed per item.");
    //                return;
    //            }
    //            cart[i].qty += 1;
    //            saveCart(cart);
    //            return;
    //        }
    //    }
    //    if (cart === null) {
    //        cart = [];
    //    }
    //    cart.push(item);
    //    saveCart(cart);

    //}

    function CreateItem(id, name, description, price, qty) {
        var item = { "id": id, "name": name, "description": description, "unit_price": price, "qty": qty };
        return item;
    };
    return (
        <Card sx={{
            bgcolor: '#f7f7f7',
            boxShadow: 1,
            borderRadius: 0.5
        }} className={(orientation === "vertical") ? "row gx-0" : "row gx-0 horizontal"}>
            {(orientation === "vertical") ? (<div className="product-card__header col-lg-12 col-md-11">

                <div>
                    <span className="card-header__label">Start Date:</span><br /><span className="card-header__value"> {(product.cf_start_time === 0 ? 'N/A' : commonfunctions.getFormattedDate(new Date(product.cf_start_time * 1000)))} </span>
                </div>
                <div>
                    <span className="card-header__label">End Date:</span><br /><span className="card-header__value"> {(product.cf_end_time === 0 ? 'N/A' : getFormattedDate(new Date(product.cf_end_time * 1000)))} </span>
                </div>
                {(hasValidTimings(product.cf_start_time, product.cf_end_time)) ?
                    <><div>
                        <span className="card-header__label">Time:</span><br /><span className="card-header__value"> {((product.cf_start_time === 0 || product.cf_end_time === 0) ? 'N/A' : getFormattedTime(new Date(product.cf_start_time * 1000), new Date(product.cf_end_time * 1000)))} </span>
                    </div>
                        <div>
                            <span className="card-header__label">Time Zone:</span><br /><span className="card-header__value"> {((product.cf_start_time === 0 || product.cf_end_time === 0) ? 'N/A' : getTimezone(new Date(product.cf_start_time * 1000)))} </span>
                        </div></>
                    : ''}

                <div>
                    <span className="card-header__label">Course Type:</span><br /><span className="card-header__value"> {(!!product.cf_course_type ? product.cf_course_type : 'N/A')} </span>
                </div>
                <div >
                    <span className="card-header__label">Price:</span><br /><span className="card-header__value"> {commonfunctions.formatToCurrency(product.item_price.price)}</span>
                </div>
            </div>) : (<div className="product-card__header_horizontal col-md-4">

                <div >
                    <span className="card-header__label">Start Date:</span><br /><span className="card-header__value"> {(product.cf_start_time === 0 ? 'N/A' : getFormattedDate(new Date(product.cf_start_time * 1000)))} </span>
                </div>
                <div >
                    <span className="card-header__label">End Date:</span><br /><span className="card-header__value"> {(product.cf_end_time === 0 ? 'N/A' : getFormattedDate(new Date(product.cf_end_time * 1000)))} </span>
                </div>
                {(hasValidTimings(product.cf_start_time, product.cf_end_time)) ?
                    <><div>
                        <span className="card-header__label">Time:</span><br /><span className="card-header__value"> {((product.cf_start_time === 0 || product.cf_end_time === 0) ? 'N/A' : getFormattedTime(new Date(product.cf_start_time * 1000), new Date(product.cf_end_time * 1000)))} </span>
                    </div>
                        <div>
                            <span className="card-header__label">Time Zone:</span><br /><span className="card-header__value"> {((product.cf_start_time === 0 || product.cf_end_time === 0) ? 'N/A' : getTimezone(new Date(product.cf_start_time * 1000)))} </span>
                        </div></>
                    : ''}
                <div>
                    <span className="card-header__label">Course Type:</span><br /><span className="card-header__value"> {(!!product.cf_course_type ? product.cf_course_type : 'N/A')} </span>
                </div>
                <div >
                    <span className="card-header__label">Price:</span><br /><span className="card-header__value"> {commonfunctions.formatToCurrency(product.item_price.price)}</span>
                </div>
                <CardActions className="MuiCardActions-root-horizontal">
                    <ButtonDropdown className="btn-group_horizontal btn_enroll" isOpen={paymentChoices} toggle={displayPaymentChoices} direction="up" >
                        <DropdownToggle caret color={product.status !== "active" ? "disabled" : "active"}>
                            ENROLL NOW
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem className="btn_purchaseonacc" disabled={product.status !== "active"} onClick={RedirectToOnAccountPage}>Purchase On Account</DropdownItem>
                            <DropdownItem className="btn_addtocart" disabled={product.status !== "active"} onClick={(e) => { handleClickOpen(e, product) }}>Add to cart (Pay by Credit Card)</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <div className="btn-group_horizontal cart">
                        <Button endIcon={<ShoppingCartIcon />} onClick={handleViewCartOpen} className="btn_viewcart">View Cart</Button>
                    </div>
                </CardActions>
            </div>)}
            <div className={(orientation === "vertical") ? "product-card__body col-lg-12" : "product-card__body col-md-8"}>
                <CardContent>
                    <span className="card-body__title">{product.external_name}</span>
                    <p className="card-body__description">{(product.description === '' ? 'N/A' : product.description)}</p>

                    <Divider variant="middle" />
                    <Box sx={{ mt: 1 }}>
                        <p className="card-body__location">
                            <b>Location:</b> {(product.cf_location === null ? 'N/A' : (product.cf_location))}
                        </p>

                    </Box>
                    <span>{validationErrMsg && <Alert severity="error"><>{validationErrMsg}</></Alert>}</span>
                </CardContent>
                {(orientation === "vertical") ? (<CardActions className={(orientation === "vertical") ? "MuiCardActions-root-vertical" : ""}>
                    <ButtonDropdown className={(orientation === "vertical") ? "btn_enroll" : "btn-group_horizontal btn_enroll"} isOpen={paymentChoices} toggle={displayPaymentChoices} direction="up" >
                        <DropdownToggle caret color={product.status !== "active" ? "disabled" : "active"}>
                            ENROLL NOW
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled={product.status !== "active"} onClick={RedirectToOnAccountPage} className="btn_purchaseonacc">Purchase On Account</DropdownItem>
                            <DropdownItem disabled={product.status !== "active"} onClick={(e) => { handleClickOpen(e, product) }} className="btn_addtocart">Add to cart (Pay by Credit Card)</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <div className={(orientation === "vertical") ? "btn-group cart" : "btn-group_horizontal cart"} >
                        <Button endIcon={<ShoppingCartIcon />} onClick={handleViewCartOpen} className="btn_viewcart">View Cart</Button>
                    </div>
                </CardActions>) : (<span></span>)}

                <ShoppingCart open={dialogIsOpen} onClose={closeDialog} CheckOutPageLink={checkOutPageLink} cartContents={props.cartContents} updateCartContents={props.updateCartContents} />
            </div>
        </Card>


    );
};
export default ProductCard;

