import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { commonfunctions } from '../../_helpers/commonfunctions';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
export default function ManageRoles(props) {
    const [isloading, setLoading] = useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [errMsg, setErrorMsg] = useState("");

    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    color="primary"
                    className="UWA_btn_Submit"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        window.location.href = '/Admin/ManageSubscription?SubscriptionID=' + params.row.subscription.id + "&CustomerID=" + params.row.subscription.customer_id;
                    }}
                >
                    Edit
                </Button>{ }
            </strong>
        )
    };

    const subscriptionColumns = [

        {
            field: 'RoleID', headerName: 'Action', headerAlign: 'center', align: 'center', width: 90, editable: false, sortable: false,
            disableColumnMenu: true, renderCell: renderEditButton
        },

        {
            field: 'Name',
            headerName: 'Name',
            width: 275,
            headerAlign: 'left',
            editable: false,
            align: 'left',
            valueGetter: (params) => {
                return params.row.name;
            }
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 350,
            headerAlign: 'left',
            editable: false,
            align: 'left',
            valueGetter: (params) => {
                return params.row.description;
            }
        },
        
        {
            field: 'Created',
            headerName: 'Create Date',
            type: 'date',
            width: 100,
            headerAlign: 'right',
            editable: false,
            align: 'right',
            valueGetter: (params) => {
                return new Date(params.row.created);
            }
        },

       

    ];
    const loadAllRoles = async () => {
        setLoading(true);
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/Role/GetAllRoles';

        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //console.log(res.data.user);
                    setTableData(res.data.roles);

                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                }
                else {
                    setErrorMsg(error.response.data);
                }

            });
    }

    const LoadingSkeleton = () => (
        <Box
            sx={{
                height: "max-content"
            }}
        >
            {[...Array(10)].map((element, index) => (
                <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
            ))}
        </Box>
    );

    useEffect(() => {

        setLoading(true);
        loadAllRoles();

    }, []);


    return (
        <Container>


            <Row>
                <Col>
                    <div style={{ height: '40vh', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>

                                <DataGrid
                                    rows={tableData}
                                    rowHeight={39}
                                    columns={subscriptionColumns}
                                    pageSize={10}
                                    getRowId={row => Math.random()}
                                    components={{
                                        LoadingOverlay: LoadingSkeleton
                                    }}
                                    loading={isloading}
                                    rowsPerPageOptions={[10]}
                                    disableColumnSelector={true} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {errMsg ? <Alert severity="error" >{errMsg}</Alert> : ""}
                </Col>
            </Row>
        </Container>
    );
}