import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { commonfunctions } from '../../_helpers/commonfunctions';
import BusinessIcon from '@mui/icons-material/Business';

export default function CustomerInfoTile(props) {
    const { loading, ...subscriberinfo  } = props;
    //console.log(props);
    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width="100%" height={200} />
            </div>
        );
    }
   // console.log(props);
    return (
        <Card style={{ marginTop: "1px" }} {...subscriberinfo} >
            <CardContent >
                <Grid container>
                    <Grid item xs={12} md={2} alignItems="center" sx={{margin: "auto"}}>
                        <Avatar sx={{ width: 100, height: 100, margin: "auto"}}>
                            <BusinessIcon sx={{ width: 80, height: 80 }}/>
                        </Avatar>
                       
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {subscriberinfo.subscriberinfo.company} 
                                </Typography>
                            </Grid>
                          
                            <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    Customer ID:
                                </Typography>
                                <Typography>
                                    {subscriberinfo.subscriberinfo.id}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12} zeroMinWidth>
                                <Typography color="textSecondary">
                                    Create Date:
                                </Typography>
                                <Typography style={{ overflowWrap: 'break-word' }}>
                                    {commonfunctions.formatToDate(subscriberinfo.subscriberinfo.createdAt)}
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    Primary Contact: 
                                </Typography>
                                <Typography>
                                    {commonfunctions.formatFullName(subscriberinfo.subscriberinfo.firstName, subscriberinfo.subscriberinfo.lastName)}
                                </Typography>
                            </Grid>
                            
                            <Grid item md={4} xs={12}>
                                <Typography color="textSecondary">
                                    Billing Address:
                                </Typography>
                                <Typography >
                                    {commonfunctions.builAddress(subscriberinfo.subscriberinfo.billingAddress.company, subscriberinfo.subscriberinfo.billingAddress.line1, subscriberinfo.subscriberinfo.billingAddress.city, subscriberinfo.subscriberinfo.billingAddress.state, subscriberinfo.subscriberinfo.billingAddress.zip, subscriberinfo.subscriberinfo.billingAddress.country).split("<br/>").join("\n")}
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12} zeroMinWidth>
                                <Typography color="textSecondary">
                                    Primary Contact Email:
                                </Typography>
                                <Typography style={{ overflowWrap: 'break-word' }}>
                                    {subscriberinfo.subscriberinfo.email}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                   
                </Grid>
            </CardContent>
        </Card>
    );
}